import { useState } from "react";
import { Drawer2 } from "src/components/Drawer2/Drawer2";
import { Drawer2Content } from "src/components/Drawer2/Drawer2Content/Drawer2Content";
import { Drawer2Title } from "src/components/Drawer2/Drawer2Title/Drawer2Title";
import { Button } from "src/design-system/components/Button/Button";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { MyTrips } from "../MyTrips/MyTrips";
import messages from "./MyTripsDrawer.messages";

const PAGE_SIZE = 5;

export function MyTripsDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const [numVisibleTrips, setNumVisibleTrips] = useState(PAGE_SIZE);

  function handleNewTrip() {}

  return (
    <>
      <MyTripsDrawerContainer>
        <Button
          onPress={() => setIsOpen(true)}
          size="large"
          variant="secondary"
        >
          {intl.formatMessage(messages.savedTrips)}
        </Button>
      </MyTripsDrawerContainer>

      <Drawer2
        isOpen={isOpen}
        onBackdropClicked={() => {
          setIsOpen(false);
        }}
      >
        <Drawer2Title onCloseClicked={() => setIsOpen(false)}>
          {intl.formatMessage(messages.savedTrips)}
        </Drawer2Title>
        <Drawer2Content id="drawer-content">
          <MyTrips
            numVisibleTrips={numVisibleTrips}
            setNumVisibleTrips={setNumVisibleTrips}
            onClose={() => setIsOpen(false)}
            onNewTrip={handleNewTrip}
          />
        </Drawer2Content>
      </Drawer2>
    </>
  );
}

const MyTripsDrawerContainer = styled.div`
  display: flex;
  justify-content: end;
`;
