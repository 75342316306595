import styled from "styled-components";
import { useIntl } from "react-intl";
import { TripSearchResult } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResult";
import { getSearchTransportKey } from "src/domain/TripPlanner/util/getSearchTransportKey";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { getCardRouteIndex } from "src/domain/TripPlanner/TripPlannerCard/createTripCardProperties";
import { RadioGroup } from "src/components/RadioGroup/RadioGroup";
import { PropsWithChildren, ReactElement, ReactNode } from "react";
import { font_size } from "src/design-system/tokens/typography";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import { SearchResponse } from "../../../api/SearchResponse";
import { createSearchResultViewModel } from "../createSearchResultViewModel";
import { useFeature } from "../../../feature/useFeature";
import { SearchResult } from "../SearchResult/SearchResult";
import { originPlaceFromSearch } from "../../../utils/adapters/place";
import { destinationPlaceFromSearch } from "../../../utils/adapters/place";
import {
  desktopLayout,
  largeDesktopLayout,
  useLayout,
} from "../../../utils/hooks/useLayout";
import messages from "./SearchResultsContent.messages";

type Props = {
  context: "transport" | "tripPlanner";
  searchResponse: SearchResponse;
  handleTripSave: (routeIndex?: string) => void;
};

const ConditionalSearchResultWrapper = ({
  children,
  wrapper,
}: PropsWithChildren<{ wrapper: (children: ReactNode) => ReactElement }>) => {
  return wrapper(children);
};

export function SearchResultsContent(props: Props) {
  const intl = useIntl();
  const searchResults = createSearchResultViewModel(
    props.searchResponse,
    useFeature("SkipNonBookableRoutes"),
    useFeature("ApplyRouteListReordering")
  );
  const layout = useLayout();

  const originPlace = originPlaceFromSearch(props.searchResponse);
  const destinationPlace = destinationPlaceFromSearch(props.searchResponse);

  const isSingular = searchResults.allRoutes.length === 1;
  const headingMesage = isSingular
    ? messages.headingSingleRoute
    : messages.headingMultipleRoutes;

  const formattedHeading = intl.formatMessage(headingMesage, {
    numberOfRoutes: searchResults.allRoutes.length,
    origin: originPlace.shortName,
    destination: destinationPlace.shortName,
  });

  const SearchResultListing =
    props.context === "tripPlanner" ? TripSearchResult : SearchResult;

  const { tripPlannerDetails } = useTripPlannerContext();
  const transportKey = getSearchTransportKey(props.searchResponse);
  const transport = tripPlannerDetails.transport[transportKey];
  const selectedRouteIndex = transport?.selectedRouteIndex;
  const defaultSelectedRouteIndex =
    tripPlannerDetails.places.length > 2 ? "0" : "";
  const routeIndex = transport
    ? getCardRouteIndex({
        ...transport,
        searchResponse: props.searchResponse,
      })
    : undefined;

  return (
    <Container isTripDrawer={props.context === "tripPlanner"}>
      {props.context === "tripPlanner" ? (
        <TripWrapper>
          <TypographyHeading>{formattedHeading}</TypographyHeading>
        </TripWrapper>
      ) : (
        <BaseTypographyHeading>{formattedHeading}</BaseTypographyHeading>
      )}

      <ConditionalSearchResultWrapper
        wrapper={
          props.context === "tripPlanner"
            ? (children) => (
                <TripVariantWrapper
                  name="search-result"
                  value={routeIndex?.toString() ?? defaultSelectedRouteIndex}
                  onChange={props.handleTripSave}
                >
                  {children}
                </TripVariantWrapper>
              )
            : (children) => <>{children}</>
        }
      >
        {searchResults.aboveFold.map((it, listingIndex) => (
          <SearchResultListing
            $size="sm"
            selectedRouteIndex={selectedRouteIndex?.toString()}
            {...it}
            key={it.title}
            onboarding={
              layout === "desktop" &&
              listingIndex === 1 &&
              selectedRouteIndex !== listingIndex
            }
          />
        ))}
        {/* Only show the subheading when there are untagged results. */}
        {searchResults.hasFold && (
          <TypographySubHeading>
            {intl.formatMessage(messages.subHeading)}
          </TypographySubHeading>
        )}
        {searchResults.belowFold.map((it) => (
          <SearchResultListing
            $size="sm"
            selectedRouteIndex={selectedRouteIndex?.toString()}
            {...it}
            key={it.title}
          />
        ))}
      </ConditionalSearchResultWrapper>
    </Container>
  );
}

const TripVariantWrapper = styled(RadioGroup)`
  padding: ${spacing.xs} ${spacing.md};
  ${largeDesktopLayout} {
    padding: 20px;
  }
`;

const Container = styled.div<{ isTripDrawer: boolean }>`
  ${(props) => {
    if (!props.isTripDrawer) {
      return `
        padding: ${spacing.md};
        background-color: ${color.n10};
      `;
    }
  }}
`;

const BaseTypographyHeading = styled.h1`
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.snug};
  color: ${color.black};
  margin: ${spacing.md} ${spacing.md} ${spacing.xl};
  font-weight: ${fontWeight.medium};
`;

const TypographyHeading = styled.h1`
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.snug};
  color: ${color.black};
  margin: ${spacing.lg} ${spacing.md} 0 ${spacing.lg};
  font-weight: ${fontWeight.medium};

  ${desktopLayout} {
    margin: ${spacing.lg} ${spacing.md} 0 ${spacing.lg};
  }

  ${largeDesktopLayout} {
    font-weight: ${fontWeight.semibold};
    margin: 0;
    padding: ${spacing.md} 20px 20px;
    font-size: ${font_size.text_2xl};
  }
`;

const TypographySubHeading = styled.div`
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.tight};
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${color.n200};
  margin: ${spacing.xl} ${spacing.md} ${spacing.md};
  font-weight: ${fontWeight.medium};

  ${desktopLayout} {
    font-weight: ${fontWeight.semibold};
  }
`;

const TripWrapper = styled.div`
  position: relative;
  ${desktopLayout} {
    background-color: ${color.white};
  }
`;
