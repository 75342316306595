import { useState } from "react";
import { useIntl } from "react-intl";
import { QueryClient, useQueries, useQueryClient } from "react-query";
import { GeocodedPlace } from "src/PrefetchData";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useFeature } from "src/feature/useFeature";
import { ApiConfig } from "../../api/ApiConfig";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { localeToLanguageCode } from "../conversions/languageCode";
import { ONE_DAY_IN_MILLISECONDS } from "../conversions/time";
import { SupportedLanguageCode } from "../language";
import { geocode, getCacheKeyForGeocode } from "./useGeocode";
import { useIsTripScreen } from "./useIsTripScreen";

export function useTripGeocode(canonicalNames: string[], isEnabled?: boolean) {
  const [geocodedPlaces, setGeocodedPlaces] = useState<GeocodedPlace[]>([]);
  const intl = useIntl();
  const config = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const queryClient = useQueryClient();
  const isTripScreen = useIsTripScreen();
  const isTripURLFeature = useFeature("TripURL");

  const shouldInvalidateCache =
    (canonicalNames.length > 2 && !isTripScreen) || isTripScreen;

  const geocodeResultsInfo = useQueries<GeocodedPlace[]>(
    combinedGeocodeQueries({
      config,
      canonicalNames,
      languageCode,
      queryClient,
      isTripURLFeature,
    })
  );
  const geocodeReadableData = geocodeResultsInfo.map((result) => ({
    isSuccess: result.isSuccess,
    refetch: result.refetch,
    data: result.data as GeocodedPlace,
  }));

  useDeepCompareEffect(() => {
    if (geocodeReadableData.every((result) => result.isSuccess)) {
      setGeocodedPlaces(geocodeReadableData.map((result) => result.data));
    }
  }, [geocodeReadableData]);

  // Invalidate the cache when adding a new destination so we don't get the autocomplete version of the place
  useDeepCompareEffect(() => {
    if (shouldInvalidateCache) {
      canonicalNames.forEach((canonicalName) => {
        const geocodedPlace = geocodeReadableData.find(
          (result) => result.data?.canonicalName === canonicalName
        );
        if (!geocodedPlace?.data?.lat) {
          queryClient.invalidateQueries(
            getCacheKeyForGeocode(canonicalName, languageCode)
          );
          geocodedPlace?.refetch();
        }
      });
    }
  }, [canonicalNames, isTripScreen]);

  return geocodedPlaces;
}

export function combinedGeocodeQueries({
  config,
  canonicalNames,
  languageCode,
  queryClient,
  isTripURLFeature,
  isEnabled,
}: {
  config: ApiConfig;
  canonicalNames: string[];
  languageCode: SupportedLanguageCode;
  queryClient: QueryClient;
  isTripURLFeature?: boolean;
  isEnabled?: boolean;
}) {
  const geocodeQueries = canonicalNames.map((canonicalName) => {
    const queryKey = getCacheKeyForGeocode(canonicalName, languageCode);

    return {
      queryKey,
      queryFn: async () =>
        geocode(config, canonicalName, languageCode, queryClient),
      cacheTime: ONE_DAY_IN_MILLISECONDS,
      staleTime: ONE_DAY_IN_MILLISECONDS,
      enabled:
        isEnabled !== undefined
          ? isEnabled
          : canonicalNames.length > (isTripURLFeature ? 0 : 2),
      retryOnMount: false,
      retry: 3,
    };
  });

  return geocodeQueries;
}
