import { useApiConfig } from "src/api/ApiConfigProvider";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { DestinationInfoResponse } from "src/api/DestinationInfoResponse";
import { getDestinationInfoEndpoint } from "src/api/endpoints";
import { localeToLanguageCode } from "../conversions/languageCode";
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from "../conversions/time";
import useUser from "./useUser";

const STATIC_QUERY_PARAMS = {
  zoom: 15,
  radius: 1,
  maxAttractions: 8,
};

export default function useDestinationInfo(
  origin: string | undefined = undefined,
  destination: string
) {
  const apiConfig = useApiConfig();
  const intl = useIntl();
  const langCode = localeToLanguageCode(intl.locale);
  const { currencyCode } = useUser();

  const destinationInfoResult = useQuery<DestinationInfoResponse>(
    ["destinationInfo", destination, origin, langCode, currencyCode],
    async () => {
      const response = await fetch(
        getDestinationInfoEndpoint(
          apiConfig,
          destination,
          langCode,
          currencyCode,
          STATIC_QUERY_PARAMS.zoom,
          STATIC_QUERY_PARAMS.radius,
          STATIC_QUERY_PARAMS.maxAttractions,
          origin
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer-when-downgrade",
        }
      );
      return await response.json();
    },
    {
      enabled: !!destination,
      cacheTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
      suspense: true,
    }
  );

  return destinationInfoResult.data;
}
