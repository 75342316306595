import styled from "styled-components";
import { useConst } from "src/utils/hooks/useConst";
import { useLayout } from "src/utils/hooks/useLayout";
import { CollapsibleAdUnit } from "src/components/CollapsibleAdUnit/CollapsibleAdUnit";
import { useFeature } from "src/feature/useFeature";
import { color, spacing } from "../../../theme";

const RouteScreenLayouts = {
  mobile: ["singleMobile", "singleMobile2"],
  tablet: ["routeScreen", "routeScreen2"],
  desktop: ["routeScreen", "routeScreen2"],
} as const;

export function RouteScreenAds() {
  const layout = useLayout();
  // We want to keep track of the initial state of the ad because
  // we don't want to rerequest the ad if the layout changes.
  const [firstAd, secondAd] = useConst(RouteScreenLayouts[layout]);
  const isNewRoutePane = useFeature("NewRoutePane");

  return (
    <Wrapper paddingTop={isNewRoutePane}>
      {!isNewRoutePane && <Divider />}
      <CollapsibleAdUnit variant="DisplayAd" slotConfigId={firstAd} />
      <StyledCollapsibleAdUnit variant="CsaAd" />
      <CollapsibleAdUnit variant="DisplayAd" slotConfigId={secondAd} />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ paddingTop?: boolean }>`
  display: flex;
  flex-direction: column;

  gap: ${spacing.xl};
  align-items: center;
  margin-bottom: ${spacing.xxl};

  ${({ paddingTop }) => paddingTop && `padding-top: ${spacing.xxxl}`}
`;

const StyledCollapsibleAdUnit = styled(CollapsibleAdUnit)<{
  hide?: boolean;
}>`
  margin-inline: ${spacing.sm};
  align-self: stretch; // The CSA ad can be wider
`;

const Divider = styled.hr`
  border: none;
  height: ${spacing.xl};
  position: relative;
  width: 100%;
  left: 0;
  background: ${color.n10};
  margin-top: ${spacing.xxxxl};
  margin-bottom: ${spacing.xxxl};
  border-top: 1px solid ${color.n30};
`;
