import { defineMessages } from "react-intl";

const messages = defineMessages({
  closeButtonTitle: {
    id: "drawer2.close_button.title",
    defaultMessage: "Close",
    description: "Close button icon title shown to screen readers",
  },
});

export default messages;
