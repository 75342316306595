import { useIntl } from "react-intl";
import { KeyFilled } from "src/svg/KeyFilled";
import { useLayout } from "src/utils/hooks/useLayout";
import { originPlaceFromSegment } from "src/utils/adapters/place";
import { buildCarHireRedirectUrl } from "src/domain/SegmentScreen/createSegmentScreenViewModel";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useLogExitCallback } from "src/analytics/useLogExitCallback/useLogExitCallback";
import { appendInitialReferrer } from "src/analytics/appendInitialReferrer/appendInitialReferrer";
import { SearchResponse } from "src/api/SearchResponse";
import { Mode } from "src/utils/types/mode";
import { logDataPartnerCarHireExit } from "src/data-partners/logDataPartnerExit";
import useSearch from "src/utils/hooks/useSearch";
import { useFeature } from "src/feature/useFeature";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { SegmentPromoDriveVariant } from "../SegmentPromoDriveVariant/SegmentPromoDriveVariant";
import messages from "./SegmentCarHirePromoDriveVariant.messages";

type Props = {
  location: string;
  segmentIndex: number;
  searchResponse: SearchResponse;
  transitMode: Mode;
};

export function SegmentCarHirePromoDriveVariant({
  location,
  segmentIndex,
  searchResponse,
  transitMode,
}: Props) {
  const intl = useIntl();
  const isMobile = useLayout() === "mobile";
  const isAdaraEnabled = useFeature("AdaraIntegration");

  const { origin, destination } = useSearch();
  const originPlace = originPlaceFromSegment(searchResponse, segmentIndex);
  const carHirePromoUrl = buildCarHireRedirectUrl(
    originPlace,
    transitMode,
    searchResponse
  );
  const logCarHireExit = useLogExitCallback(
    "RentalCars",
    "segment",
    "carCta",
    transitMode
  );

  const handleCarHirePromoClick = () => {
    sendAnalyticsInteractionEvent("Segment", "Click:CarHirePromo");

    logDataPartnerCarHireExit({
      origin: origin?.canonicalName,
      destination: destination?.canonicalName,
      languageCode: localeToLanguageCode(intl.locale),
      isCrimtanEnabled:
        searchResponse.analytics?.interest_data?.Crimtan?.enabled,
      isAdaraEnabled,
    });

    if (carHirePromoUrl) {
      logCarHireExit(carHirePromoUrl);
      window.open(
        appendInitialReferrer(carHirePromoUrl),
        "_blank",
        "noopener noreferrer"
      );
    }
  };

  return (
    <SegmentPromoDriveVariant
      heading={intl.formatMessage(messages.heading, { location })}
      tagline={intl.formatMessage(messages.tagline)}
      icon={<KeyFilled tint="cod" />}
      iconAlign={isMobile ? "left" : "right"}
      outline={isMobile}
      fullWidth={isMobile}
      onClick={handleCarHirePromoClick}
    />
  );
}
