import { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Accessibility } from "src/svg/Accessibility";
import { useLayout } from "src/utils/hooks/useLayout";
import messages from "./AccessibilityToggle.messages";
import { Toggle } from "./Toggle";

type AccessibilityToggleProps = {
  selected: boolean;
  setSelected: Dispatch<SetStateAction<boolean>>;
};

export function AccessibilityToggle(props: AccessibilityToggleProps) {
  const intl = useIntl();
  const isMobile = useLayout() === "mobile";

  return (
    <Toggle
      label={intl.formatMessage(messages.accessibleMode)}
      backgroundColor="black"
      circleDiameter={isMobile ? 24 : 27}
      {...props}
    >
      <Accessibility tint={props.selected ? "black" : "grey3"} />
    </Toggle>
  );
}
