import React, { Dispatch, createContext, useContext } from "react";
import { GeocodedPlace } from "src/PrefetchData";
import { HistoryAction } from "src/utils/historyReducer";
import { useGetTripRoutes } from "src/utils/hooks/useGetTripRoutes";
import {
  Action,
  HoveredRecommendation,
  TripPlannerDetails,
} from "../TripPlannerProvider";
import { ApiState } from "../util/api";
import useExpandedPlaces from "./useExpandedPlaces";
import { useInteractionMade } from "./useInteractionMade";
import { useTripPlanningState } from "./useTripPlanningState";
import { useTripDestination } from "./useTripDestination";

export type TripPlannerContextType = {
  tripRoutes: ReturnType<typeof useGetTripRoutes>;
  tripPlannerDetails: TripPlannerDetails;
  dispatch: Dispatch<HistoryAction<Action>>;
  hoveredPlaceIndex: number | undefined;
  setHoveredPlaceIndex: (index: number | undefined) => void;
  tripPlanningState: ReturnType<typeof useTripPlanningState>;
  apiState: ApiState;
  loadingIndex?: number[];
  reorderingPlaces: GeocodedPlace[];
  setReorderingPlaces: Dispatch<React.SetStateAction<GeocodedPlace[]>>;
  history?: {
    canUndo: boolean;
    canRedo: boolean;
  };
  activeTripPlannerTab: "hotels" | "tripPlanner";
  setActiveTripPlannerTab: (tab: "hotels" | "tripPlanner") => void;
  tripInteraction: ReturnType<typeof useInteractionMade>;
  expandedPlaces: ReturnType<typeof useExpandedPlaces>["expandedPlaces"];
  hoveredRecommendation: HoveredRecommendation;
  setHoveredRecommendation: React.Dispatch<
    React.SetStateAction<HoveredRecommendation>
  >;
  dispatchExpandedPlaces: ReturnType<
    typeof useExpandedPlaces
  >["dispatchExpandedPlaces"];
  tripDestination: ReturnType<typeof useTripDestination>;
  isMultiTrip: boolean;
};

export const TripPlannerContext = createContext<TripPlannerContextType | null>(
  null
);

export function useTripPlannerContext() {
  const context = useContext(TripPlannerContext);
  if (!context) {
    throw new Error(
      `useTripPlannerContext must be used within TripPlannerContext`
    );
  }
  return context;
}
