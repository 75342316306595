import {
  ReactNode,
  ReactElement,
  Children,
  isValidElement,
  ElementType,
  ComponentProps,
} from "react";

export function findElementOfType<E extends ElementType>(
  node: ReactNode,
  type: E
): ReactElement<ComponentProps<E>, E>[] {
  return Children.toArray(node).filter((it) =>
    isElementOfType<E>(it, type)
  ) as ReactElement<ComponentProps<E>, E>[];
}

export function isElementOfType<E extends ElementType>(
  el: ReturnType<typeof Children.toArray>[number],
  type: E
) {
  return (
    isValidElement<ReactElement<ComponentProps<E>, E>>(el) &&
    (el as ReactElement).type === type
  );
}
