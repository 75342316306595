import { useIntl } from "react-intl";
import { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Icon } from "src/components/Icon/Icon";
import { TripPlannerTransportKey } from "src/domain/TripPlanner/TripPlannerProvider";
import { ChevronRightBold } from "src/svg/ChevronRightBold";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "src/theme";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import styled from "styled-components";
import { placeNumberOffsetPx } from "../../Headings/PlaceTitle";
import { messages } from "../HotelsCarousel.messages";

type AddHotelCTAProps = {
  index: number;
  geocodedPlace: GeocodedPlace;
  places: GeocodedPlace[];
};

function ViewMoreHotelsCta(props: AddHotelCTAProps) {
  const { index, places, geocodedPlace } = props;

  const intl = useIntl();
  const { navigateToHotels } = useNavigateToHotelsPage();

  function handleAccomClick() {
    const originCanonical =
      index === 0
        ? geocodedPlace.canonicalName
        : places[index - 1].canonicalName;

    const canonicalOverrides = {
      destinationCanonical: geocodedPlace.canonicalName,
      originCanonical,
    };
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:HotelViewMore",
      geocodedPlace.canonicalName
    );
    navigateToHotels({ ...canonicalOverrides });
  }

  return (
    <AddHotelsCta
      onClick={() => {
        handleAccomClick();
      }}
    >
      {intl.formatMessage(messages.viewMore)}
      <Icon size="sm-1" data-testid="chevron">
        <ChevronRightBold tint="pink" />
      </Icon>
    </AddHotelsCta>
  );
}

type HotelsCarouselHeadingProps = {
  geocodedPlace: GeocodedPlace;
  places: GeocodedPlace[];
  index: number;
  canonicalPair: TripPlannerTransportKey;
};

export function HotelsCarouselHeading({
  index,
  geocodedPlace,
  places,
}: HotelsCarouselHeadingProps) {
  return (
    <PlaceDetailsContainer data-testid={`trip-card-heading-${index}`}>
      <PlaceNumber>{index + 1}</PlaceNumber>
      <PlaceName>{geocodedPlace.shortName}</PlaceName>
      <ViewMoreHotelsCta {...{ index, geocodedPlace, places }} />
    </PlaceDetailsContainer>
  );
}

const PlaceDetailsContainer = styled.div`
  display: flex;
  border-radius: ${borderRadius.xl};
  align-items: center;
  justify-content: center;
  gap: ${spacing.md};
`;

const PlaceNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.md};
  color: ${color.white};
  // For multi-digit numbers, convert shape into a pill instead of a circle.
  min-width: 24px;
  height: 24px;
  padding: ${spacing.sm};
  border-radius: 12px;
  background-color: ${color.n300};
`;

const PlaceName = styled.h4`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.xl};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
  margin-top: calc(${placeNumberOffsetPx / 2}px - 0.3em);
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: auto;
`;

const AddHotelsCta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing.md};
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.md};
  color: ${color.pink};
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;
