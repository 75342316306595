import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { ApiConfig } from "../../api/ApiConfig";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { HotelInfoResponse } from "../../api/HotelInfoResponse";
import { hotelInfoEndpoint } from "../../api/endpoints";
import {
  RoomDetail,
  TOMORROW_DATE,
  TONIGHT_DATE,
  useHotelsContext,
} from "../../domain/HotelsScreen/HotelsContext";
import { localeToLanguageCode } from "../conversions/languageCode";
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from "../conversions/time";
import { SupportedCurrencyCode } from "../currency";
import { SupportedLanguageCode } from "../language";
import { HotelProviderKind } from "../types/accommodationProviders";
import {
  formatDateForHotelsEndpoint,
  formatRoomDetailsForRequest,
} from "./useHotelList";
import useSearch from "./useSearch";
import useUser from "./useUser";

type Props = {
  hotelId: string;
  provider: HotelProviderKind;
  enabled?: boolean;
};

// TODO: Expedia star ratings can be -1, which we switch to undefined.
export type HotelInfoModel = {
  name: string;
  translatedDistrictName?: string;
  address: string;
  imageUrl: string;
  extraImageUrls?: string[];
  tileImageUrl?: string;
  hotelUrl: string;
  starRating?: number;
  review?: { score: number; count: number };
  lat: number;
  lng: number;
};

// https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2592210945/Rome2Rio+api+1.6+integrations#HotelInfo-endpoint-(POST-%2Fapi%2F1.6%2Fhotels%2FhotelInfo)
export function useHotelInfo(props: Props): {
  isLoading: boolean;
  hotelInfoResponse?: HotelInfoModel;
} {
  const { hotelId, provider, enabled = true } = props;
  const intl = useIntl();
  const { currencyCode } = useUser();
  const config = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const { hotelListQueryParams } = useHotelsContext();
  const { roomDetails, arrivalDate, departureDate } = hotelListQueryParams;
  const { searchResponse } = useSearch();
  const defaultCheckIn = TONIGHT_DATE;
  const defaultCheckOut = TOMORROW_DATE;

  const requestId = searchResponse?.request.requestId ?? "";

  // If the user has not yet defined an age for any children, filter them out of the request.
  const filteredRoomDetails = roomDetails.map((room) => {
    return {
      ...room,
      children: room.children.filter((child) => child.age !== undefined),
    };
  });

  const hotelsInfoResult = useQuery<HotelInfoResponse>(
    [
      "hotelInfo",
      hotelId,
      provider,
      languageCode,
      currencyCode,
      arrivalDate ?? defaultCheckIn,
      departureDate ?? defaultCheckOut,
      roomDetails,
    ],
    async () => {
      return hitHotelInfoEndpoint(
        config,
        requestId,
        hotelId,
        languageCode,
        currencyCode,
        provider,
        arrivalDate ?? defaultCheckIn,
        departureDate ?? defaultCheckOut,
        filteredRoomDetails
      );
    },
    {
      enabled,
      cacheTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
    }
  );

  return {
    ...hotelsInfoResult,
    isLoading:
      hotelsInfoResult.status === "loading" ||
      hotelsInfoResult.status === "idle",
    hotelInfoResponse:
      hotelsInfoResult.data &&
      mapHotelInfoResponseToModel(hotelsInfoResult.data),
  };
}

async function hitHotelInfoEndpoint(
  config: ApiConfig,
  requestId: string,
  hotelId: string,
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  provider: HotelProviderKind,
  arrivalDate: Date,
  departureDate: Date,
  roomDetails?: RoomDetail[]
): Promise<HotelInfoResponse> {
  const url = hotelInfoEndpoint(
    config,
    hotelId,
    formatDateForHotelsEndpoint(arrivalDate),
    formatDateForHotelsEndpoint(departureDate),
    languageCode,
    currencyCode,
    provider
  );

  const requestBody = {
    key: config.key,
    uid: config.uid,
    aqid: config.aqid,
    requestId: requestId,
    hotelId: hotelId,
    languageCode,
    currencyCode,
    provider: provider,
    useHotelRedirect: true,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    arrivalDate: formatDateForHotelsEndpoint(arrivalDate),
    departureDate: formatDateForHotelsEndpoint(departureDate),
    hotelRooms: formatRoomDetailsForRequest(roomDetails),
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer-when-downgrade",
    body: JSON.stringify(requestBody),
  });

  return await response.json();
}

function mapHotelInfoResponseToModel(
  response: HotelInfoResponse
): HotelInfoModel {
  const review =
    response.numReviews > 0 && response.reviewScore > 0
      ? {
          score: response.reviewScore,
          count: response.numReviews,
        }
      : undefined;

  return {
    ...response,
    starRating: response.starRating === -1 ? undefined : response.starRating,
    review,
  };
}
