import { createContext, useContext } from "react";

type AccordionState = {
  expanded: boolean;
  onChange: (isExpanded: boolean) => void;
};

const AccordionContext = createContext<AccordionState>({
  expanded: false,
  onChange: () => {},
});
if (import.meta.env.DEV) {
  AccordionContext.displayName = "AccordionContext";
}
export const AccordionProvider = AccordionContext.Provider;

export function useAccordionState() {
  return useContext(AccordionContext);
}
