import { arrayMove } from "@dnd-kit/sortable";
import { useEffect, useReducer } from "react";
import { TripPlannerDetails } from "../TripPlannerProvider";

type ExpandablePlaces = boolean[];

type ResetAction = {
  type: "RESET";
  places: TripPlannerDetails["places"];
};

type UpdateLengthAction = {
  type: "UPDATE_LENGTH";
  places: TripPlannerDetails["places"];
};

type ExpandPositionAction = {
  type: "EXPAND_BY_POSITION";
  index: number;
};

type ToggleAction = {
  type: "TOGGLE";
  index: number;
};

type ReorderAction = {
  type: "REORDER";
  newOrder: {
    id: string;
  }[];
};

type RetractAction = {
  type: "RETRACT";
  index: number;
};

type RetractAllAction = {
  type: "RETRACT_ALL";
};

type Action =
  | ResetAction
  | UpdateLengthAction
  | ToggleAction
  | ReorderAction
  | ExpandPositionAction
  | RetractAction
  | RetractAllAction;

// Default to having the first place expanded.
const initialExpandedPlaces = [true, false];

export default function useExpandedPlaces(
  tripPlannerDetails: TripPlannerDetails
) {
  const [expandedPlaces, dispatchExpandedPlaces] = useReducer(
    reduceExpandedPlaces,
    initialExpandedPlaces
  );

  useEffect(() => {
    if (
      expandedPlaces.length !== tripPlannerDetails.places.length &&
      tripPlannerDetails.places.length > 0
    ) {
      dispatchExpandedPlaces({
        type: "UPDATE_LENGTH",
        places: tripPlannerDetails.places,
      });
    }
    // If the number of places changes (ie. a place gets added or removed)
    // We can just trim what we have in state here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripPlannerDetails.places.length, expandedPlaces.length]);

  return {
    expandedPlaces,
    dispatchExpandedPlaces,
  };
}

function reduceExpandedPlaces(state: ExpandablePlaces, action: Action) {
  switch (action.type) {
    case "RESET": {
      let expandedPlaces = new Array(action.places.length).fill(false);
      return setExpandedPlaceDefaults(expandedPlaces, true);
    }
    case "EXPAND_BY_POSITION": {
      let newState = [...state];
      newState[action.index] = true;
      return newState;
    }
    case "TOGGLE": {
      let newState = [...state];
      newState[action.index] = !newState[action.index];
      return newState;
    }
    case "UPDATE_LENGTH": {
      let newState = [];
      if (state.length < action.places.length) {
        newState = [
          ...state,
          ...new Array(action.places.length - state.length).fill(false),
        ];
      } else {
        newState = state.slice(0, action.places.length);
      }
      return setExpandedPlaceDefaults(newState);
    }
    case "REORDER": {
      let reordered = getReorderedExpandedPlaces(action.newOrder, state);
      return setExpandedPlaceDefaults(reordered, reordered.length === 2);
    }
    case "RETRACT": {
      let newState = [...state];
      newState[action.index] = false;
      return newState;
    }
    case "RETRACT_ALL": {
      let newState = state.map(() => false);
      return newState;
    }

    default:
      return state;
  }
}

/**
 * This function carries the expanded places into their new position.
 * It ensures that expanded places remain expanded after reordering.
 * @param newOrder An array of places with their new order.
 * @param expandedPlaces An array of expanded places for the current order.
 * @returns The expanded places reordered to match the new order.
 */
function getReorderedExpandedPlaces(
  newOrder: {
    id: string;
  }[],
  expandedPlaces: ExpandablePlaces
) {
  let newExpandedPlaces = [...expandedPlaces];
  for (let i = 0; i < newOrder.length; i++) {
    const oldIndex = Number(newOrder[i].id.split("_")[1]);
    // Only carry the expanded places into their new position.
    if (oldIndex !== i && expandedPlaces[oldIndex]) {
      newExpandedPlaces = arrayMove(newExpandedPlaces, Number(oldIndex), i);
    }
  }
  return newExpandedPlaces;
}

function setExpandedPlaceDefaults(
  expandedPlaces: ExpandablePlaces,
  autoExpandFirst = false
) {
  if (autoExpandFirst && expandedPlaces.length > 1) {
    expandedPlaces[0] = true;
  }
  // The last place should never be expanded.
  expandedPlaces[expandedPlaces.length - 1] = false;

  return expandedPlaces;
}
