import { useEffect, useState } from "react";
import { GeocodedPlace } from "src/PrefetchData";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { useFeature } from "src/feature/useFeature";
import { useTripPlanQuery } from "./useTripPlanQueries";

export function useTripIdFromURL() {
  const location = useTypedLocation();
  const searchParams = new URLSearchParams(location.search);
  return { tripIdFromURL: searchParams.get("tripId") };
}

/** Accepts both trip ID and slug */
export function useTripIdPlaces(tripIdOverride?: string) {
  const { tripIdFromURL } = useTripIdFromURL();
  const [places, setPlaces] = useState<GeocodedPlace[]>([]);
  const [queryStatus, setQueryStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const tripId = tripIdOverride ?? tripIdFromURL;
  const isTripURLFeature = useFeature("TripURL");
  const isTripPlanQueryEnabled = isTripURLFeature && !!tripId;
  const { data, status } = useTripPlanQuery(
    () => {},
    isTripPlanQueryEnabled,
    tripId as string
  );

  useEffect(() => {
    setQueryStatus(status);
    if (status === "success" && data) {
      setPlaces(data?.places);
    }
  }, [status, data]);

  return {
    places,
    setPlaces,
    data,
    queryStatus,
    isUsingTripId:
      !!tripId && (queryStatus === "loading" || queryStatus === "success"),
  };
}
