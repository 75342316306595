import { defineMessages } from "react-intl";

const messages = defineMessages({
  accessibleMode: {
    id: "accessibilityToggle.accessibleMode",
    description:
      "Accessible mode button, this button will be used to toggle accessible mode",
    defaultMessage: "Accessible mode",
  },
});

export default messages;
