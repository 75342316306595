import React, { useRef, useState } from "react";
import { Icon } from "src/components/Icon/Icon";
import { AddDestinationInput } from "src/components/TripPlanner/AddDestinationButton/AddDestinationDialog/AddDestinationInput";
import { useIntl } from "react-intl";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import styled from "styled-components";
import { borderRadius, color, elevation, spacing } from "src/theme";
import { desktopLayout } from "src/utils/hooks/useLayout";
import { lighten } from "polished";
import { Plus } from "src/svg/tripplanner/Plus";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useFeature } from "src/feature/useFeature";
import { GeocodedPlace } from "src/PrefetchData";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { messages } from "./AddDestinationInline.messages";

type AddDestionationInlineType = {
  addDestinationRef: React.RefObject<HTMLDivElement>;
  onSelectDestination(place: AutocompletePlace): void;
};

export default function AddDestinationInline(props: AddDestionationInlineType) {
  const intl = useIntl();
  const { tripPlannerDetails } = useTripPlannerContext();
  const addDestinationRef = useRef<HTMLInputElement>(null);
  const isSimpleSearchV2 = useFeature("SimpleSearchV2");
  const { isMultiTrip } = useTripPlannerContext();
  const [isQueryEmpty, setIsQueryEmpty] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const lastPlace: GeocodedPlace | undefined =
    tripPlannerDetails.places[tripPlannerDetails.places.length - 1];

  function handleOnChange(query: string) {
    setIsQueryEmpty(query === "");
  }

  function handleAutocompleteSelectOption(place: AutocompletePlace) {
    props.onSelectDestination(place);
    setIsQueryEmpty(true);
    sendAnalyticsInteractionEvent("TripPlanner", "Select:AddDestinationInline");
  }

  function handleClear() {
    setIsQueryEmpty(true);
  }

  function handleFocus() {
    sendAnalyticsInteractionEvent("TripPlanner", "Click:AddDestinationInline");
    addDestinationRef.current?.focus();
  }

  return (
    <AddDestionationWrapper ref={props.addDestinationRef}>
      <TripPlannerPlus onClick={handleFocus}>
        <Icon size="md">
          <Plus tint="white" />
        </Icon>
      </TripPlannerPlus>
      <AddDestinationInput
        onSelectOption={handleAutocompleteSelectOption}
        AutocompleteListWrapper={AutocompleteListWrapper}
        hideList={isQueryEmpty}
        onChange={handleOnChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isFocused={isFocused}
        showClearIcon={!isQueryEmpty}
        handleClear={handleClear}
        id="add-destination-inline"
        type={0}
        placeholder={intl.formatMessage(
          isFocused ? messages.enterCity : messages.addDestination
        )}
        addDestinationRef={addDestinationRef}
        transparentBackground={!isSimpleSearchV2 || isMultiTrip}
        noShadow
        fullWidth
        curvedBorder
        solidBackground
        filteredPlaces={lastPlace ? [lastPlace] : undefined}
      />
    </AddDestionationWrapper>
  );
}

export const AutocompleteListWrapper = styled.div`
  max-height: calc(50vh - 80px);
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 999;
  border-bottom-left-radius: ${borderRadius.sm};
  border-bottom-right-radius: ${borderRadius.sm};
  padding: 0 ${spacing.lg};

  // This gives the illusion that the scrollbar is rounded in the bottom right corner.
  box-shadow: ${elevation.mid};
`;

const AddDestionationWrapper = styled.div`
  top: 2px;
  margin-top: ${spacing.lg};
  padding-left: 50px;
  display: flex;
`;

const TripPlannerPlus = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${color.pink};
  position: absolute;
  top: ${spacing.md};
  left: ${spacing.sm};

  &:hover {
    cursor: pointer;
  }

  ${desktopLayout} {
    &:hover {
      background-color: ${lighten(0.1, color.pink)};
    }
  }
`;
