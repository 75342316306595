import styled from "styled-components";
import { spacing } from "src/theme";
import { MergeElementProps } from "../../../utils/MergeElementProps";

type Props = MergeElementProps<
  "div",
  {
    removePadding?: boolean;
    className?: string;
    removeMargin?: boolean;
  }
>;

export function Drawer2Content({
  removePadding,
  children,
  removeMargin,
  ...rest
}: Props) {
  return (
    <Container
      removePadding={removePadding}
      {...rest}
      removeMargin={removeMargin}
    >
      {children}
    </Container>
  );
}

const Container = styled.div<{
  removePadding?: boolean;
  removeMargin?: boolean;
}>`
  height: 100%;
  overflow-y: auto;
  padding: ${({ removePadding }) =>
    removePadding ? "0 0" : `0 ${spacing.xl}`};
  // Vertical spacing is actually 16px, but we use 8px here so we
  // can add it up across the compound components that are used.
  margin: ${({ removeMargin }) => (removeMargin ? "0 0" : `${spacing.md} 0`)};
`;
