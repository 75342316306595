import styled from "styled-components";
import { ComponentProps } from "react";
import { color, fontSize, lineHeight, spacing } from "../../../../theme";
import { Timeline } from "../../Timeline";
import { InterchangeCell } from "../cell/InterchangeCell";

type Props = {
  cell: InterchangeCell;
  className?: string;
};

export function TroniconTimelineInterchange(props: Props) {
  return (
    <Wrapper
      hasInterchangeName={!!props.cell.interchangeName}
      className={props.className}
    >
      <FullWidthTimeline
        type="interchange"
        transitMode={props.cell.transitMode}
      />
      <LeftAligned>
        <InterchangeTitle>{props.cell.interchange}</InterchangeTitle>
        {props.cell.interchangeName && (
          <AlternativeInterchangeName>
            {props.cell.interchangeName}
          </AlternativeInterchangeName>
        )}
      </LeftAligned>
    </Wrapper>
  );
}

const InterchangeTitle = styled.span`
  line-height: ${lineHeight.tight};
`;

const FullWidthTimeline = styled(Timeline)<ComponentProps<typeof Timeline>>`
  flex-shrink: 0;
  // The Tronicon Lines will overflow
  // They need to cover up to 3 lines of text
  // We don't want them to overflow into the next tronicon component
  overflow: hidden;
`;

const Wrapper = styled.div<{ hasInterchangeName: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  ${({ hasInterchangeName }) =>
    hasInterchangeName && `margin: -${spacing.md} 0`};
`;

const LeftAligned = styled.span`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: auto;
  padding-left: ${spacing.xl};
  line-height: 24px;
  font-size: ${fontSize.h5};
`;
const AlternativeInterchangeName = styled.span`
  font-size: ${fontSize.h6};
  color: ${color.n90};
  line-height: ${lineHeight.tight};
`;
