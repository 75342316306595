import { DragUpdate, ResponderProvided } from "@hello-pangea/dnd";
import { useCallback, useRef, useState } from "react";
import { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { DragAndDropList } from "src/components/DragAndDropList/DragAndDropList";
import { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import styled from "styled-components";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { DraggableTripPlannerCard } from "../../TripPlannerCard/TripPlannerCard";
import { DraggableTripPlannerPlace } from "../../TripPlannerCard/TripPlannerPlace";
import { useTripPlannerContext } from "../../hooks/useTripPlannerContext";
import getOrderedPlaceId from "../../util/getOrderedPlaceId";
import { hasTripChanged } from "../../util/hasTripChanged";

type Props = {
  isExpandablePlaces?: boolean;
  onRemove: () => void;
};

export function OnscreenReorder({ isExpandablePlaces, onRemove }: Props) {
  const {
    tripPlanningState,
    setHoveredPlaceIndex,
    dispatch,
    tripPlannerDetails,
    expandedPlaces,
    dispatchExpandedPlaces,
    isMultiTrip,
  } = useTripPlannerContext();

  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentDragStatus, setCurrentDragStatus] = useState<DragUpdate>();
  const [isDragging, setIsDragging] = useState(false);
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();

  const toggle = useCallback(
    (index: number) => {
      if (!isExpandablePlaces) return;
      dispatchExpandedPlaces({
        type: "TOGGLE",
        index,
      });
    },
    [isExpandablePlaces, dispatchExpandedPlaces]
  );

  const onDragUpdate = useCallback(
    (update: DragUpdate, _: ResponderProvided) => {
      setCurrentDragStatus(update);
    },
    [setCurrentDragStatus]
  );
  const onBeforeCapture = useCallback(() => {
    setIsDragging(true);
  }, [setIsDragging]);

  const onDragEnd = useCallback(() => {
    setIsDragging(false);
    setTimeout(() => {
      setCurrentDragStatus(undefined);
    }, 700);
  }, [setIsDragging]);

  const onReorderCallback = useCallback(
    (newOrder: SortableObject<GeocodedPlace>[]) => {
      if (hasTripChanged(tripPlannerDetails.places, newOrder)) {
        sendAnalyticsInteractionEvent("TripPlanner", "Reorder:Inline");
        dispatch({ type: "REORDER_TRIP", newOrder });
        dispatchExpandedPlaces({
          type: "REORDER",
          newOrder,
        });
      }
    },
    [dispatch, tripPlannerDetails.places, dispatchExpandedPlaces]
  );

  return (
    <DragAndDropList
      onChange={onReorderCallback}
      onBeforeCapture={onBeforeCapture}
      onDragUpdate={onDragUpdate}
      onDragEnd={onDragEnd}
      initialItems={
        tripPlannerDetails.places.map((place, index) => {
          return {
            ...place,
            id: getOrderedPlaceId(place, index),
          };
        }) as SortableObject<GeocodedPlace>[]
      }
      ListComponent={isTripsAsCoreHoldback ? NewTimelineList : List}
      globalDraggableOptions={{
        disableInteractiveElementBlocking: true,
      }}
      isCustomItem
    >
      {({ dragHandleProps, item, index, snapshot, droppableSnapshot }) => {
        const isLast = index === tripPlannerDetails.places.length - 1;
        const isExpanded = !isMultiTrip ? true : expandedPlaces[index];
        return (
          <DraggableTripPlannerPlace
            index={index}
            dragStatus={currentDragStatus}
            droppableState={droppableSnapshot}
            isDragging={isDragging}
            isLast={
              isTripsAsCoreHoldback
                ? false
                : index === tripPlannerDetails.places.length - 1
            }
          >
            <DraggableTripPlannerCard
              dropSnapshot={droppableSnapshot}
              dragSnapshot={snapshot}
              dragStatus={currentDragStatus}
              dragHandleProps={dragHandleProps}
              isDragging={isDragging}
              scrollRef={
                tripPlanningState.updatedPlace.value === index + 1
                  ? scrollRef
                  : undefined
              }
              places={tripPlannerDetails.places}
              startDate={tripPlannerDetails.startDate}
              index={index}
              place={item}
              isExpanded={!isExpandablePlaces ? false : isExpanded}
              toggle={toggle}
              callbacks={{
                hoverCallback: () => !isLast && setHoveredPlaceIndex(index),
              }}
              onRemove={onRemove}
            />
          </DraggableTripPlannerPlace>
        );
      }}
    </DragAndDropList>
  );
}

export function getReorderedExpandedPlaces(
  expandedPlaces: string[],
  newOrder: SortableObject<GeocodedPlace>[]
) {
  // If there are only two places, we auto expand the first pair.
  if (newOrder.length === 2) {
    return [getOrderedPlaceId(newOrder[0], 0)];
  }

  if (!expandedPlaces.length) return [];

  const newExpandedPlaces = expandedPlaces.map((placeId) => {
    const newIndex = newOrder.findIndex(({ id }) => id === placeId);
    if (newIndex !== -1 && newIndex !== newOrder.length - 1) {
      const [canonicalName] = placeId.split("-");
      const newId = [canonicalName, newIndex].join("-");
      return newId;
    }
    return null;
  });

  return newExpandedPlaces.filter((place) => !!place) as string[];
}

export function getSortablePlaces(places: { canonicalName: string }[]) {
  return places.map((place, index) => {
    return {
      ...place,
      id: getOrderedPlaceId(place, index),
    } as SortableObject<GeocodedPlace>;
  });
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const NewTimelineList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 64px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;
