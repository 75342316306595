import { SvgProps, useColor } from "./common";

export const Accessibility = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.58597 3.15C7.45582 3.15 8.16097 2.44485 8.16097 1.575C8.16097 0.705151 7.45582 0 6.58597 0C5.71612 0 5.01097 0.705151 5.01097 1.575C5.01097 2.44485 5.71612 3.15 6.58597 3.15Z"
        fill={tintColor}
      />
      <path
        d="M12.586 4.02L8.29597 5.22C8.19097 5.25 8.10097 5.355 8.10097 5.475V8.745C8.10097 8.745 8.10097 8.805 8.10097 8.82L9.52597 13.2C9.66097 13.59 9.43597 14.01 9.04597 14.145H8.97097C8.58097 14.295 8.16097 14.085 8.02597 13.68L6.57097 9.225L5.10097 13.68C4.98097 14.085 4.56097 14.295 4.15597 14.16H4.09597C3.69097 14.01 3.48097 13.59 3.61597 13.2L5.04097 8.82C5.04097 8.82 5.04097 8.76 5.04097 8.745V5.475C5.04097 5.355 4.96597 5.265 4.84597 5.22L0.55597 4.02C0.15097 3.9 -0.0890299 3.495 0.0309701 3.09V3.015C0.16597 2.625 0.57097 2.385 0.97597 2.505L5.46097 3.75C5.46097 3.75 5.50597 3.75 5.53597 3.75H7.59097C7.59097 3.75 7.63597 3.75 7.66597 3.75L12.151 2.505C12.541 2.385 12.961 2.625 13.066 3.015L13.096 3.09C13.201 3.495 12.976 3.9 12.571 4.02H12.586Z"
        fill={tintColor}
      />
    </svg>
  );
};
