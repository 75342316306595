import { TripSearchResultSkeleton } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResultSkeleton";
import { TripSearchResultsContainer } from "./SearchResultListCard";

export function SearchResultListCardSkeleton() {
  const resultSkeles = Array.from({ length: 4 });

  return (
    <>
      <TripSearchResultsContainer>
        {resultSkeles.map(() => {
          return <TripSearchResultSkeleton />;
        })}
      </TripSearchResultsContainer>
    </>
  );
}
