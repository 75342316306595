import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Icon } from "src/components/Icon/Icon";
import { ChevronRightBold } from "src/svg/ChevronRightBold";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import styled from "styled-components";
import { Place } from "src/api/SearchResponse";
import { color, fontSize, spacing } from "src/theme";
import { messages } from "../HotelsCarousel.messages";

type ViewAllStaysCTAProps = {
  place?: Place;
};

export function ViewAllStaysCTA({ place }: ViewAllStaysCTAProps) {
  const intl = useIntl();
  const { navigateToHotels } = useNavigateToHotelsPage();

  function handleViewAllStays() {
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:HotelViewAll",
      place?.canonicalName
    );
    navigateToHotels({ destinationCanonical: place?.canonicalName });
  }

  return (
    <ViewAllStays onClick={handleViewAllStays}>
      <CircleDiv>
        <Icon size="md">
          <ChevronRightBold tint="white" />
        </Icon>
      </CircleDiv>
      <CtaTitleSpan>{intl.formatMessage(messages.viewAll)}</CtaTitleSpan>
    </ViewAllStays>
  );
}

const ViewAllStays = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.sm};
  min-width: 140px;
  align-self: stretch;
  cursor: pointer;
`;

const CircleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${color.pink};
`;

const CtaTitleSpan = styled.span`
  font-size: ${fontSize.md};
`;
