import { Dispatch, useCallback, useState } from "react";
import useSearch from "src/utils/hooks/useSearch";
import { useFeature } from "src/feature/useFeature";
import { Action } from "../TripPlannerProvider";
import { createTripPlanFromSearchResponse } from "../util/createTripPlanFromSearchResponse";
import { useTripURLGeocodedPlaces } from "./useTripURL";

export function useInitialTripFromSearch(
  dispatch: Dispatch<Action>,
  isEnabled: boolean
) {
  const { isLoading } = useSearch();
  const [hasUsedSearch, setHasUsedSearch] = useState(false);
  const { searchResponse } = useSearch();
  const isTripURLFeature = useFeature("TripURL");
  const { places, tripIdData } = useTripURLGeocodedPlaces();

  const addTripFromSearch = useCallback(() => {
    if (!isLoading && searchResponse && !hasUsedSearch) {
      const tripFromSearch = createTripPlanFromSearchResponse(searchResponse);
      dispatch({
        type: "PASSIVE_UPDATE",
        trip: tripFromSearch,
      });
      setHasUsedSearch(true);
    }
  }, [searchResponse, dispatch, hasUsedSearch, isLoading]);

  const addTripFromURL = useCallback(() => {
    if (places.length && !hasUsedSearch) {
      let tripDataWithDefaults = {
        transport: {},
      };
      if (tripIdData) {
        tripDataWithDefaults = tripIdData;
      }
      dispatch({
        type: "PASSIVE_UPDATE",
        trip: {
          ...tripDataWithDefaults,
          places,
        },
      });
      setHasUsedSearch(true);
    }
  }, [places, hasUsedSearch, dispatch, tripIdData]);

  const addTripFn = isTripURLFeature ? addTripFromURL : addTripFromSearch;

  return {
    addTripFromSearch: isEnabled ? addTripFn : null,
    hasUsedSearch,
  };
}
