import { useTripGeocode } from "src/utils/hooks/useTripGeocode";
import useSearch from "src/utils/hooks/useSearch";
import { GeocodedPlace } from "src/PrefetchData";
import { TripPlanWithID } from "../util/api";
import { useTripIdPlaces } from "./useTripId";
import { useTripSearchParam } from "./useTripSearchParam";

export function useTripURLGeocodedPlaces(): {
  places: GeocodedPlace[];
  tripIdData: TripPlanWithID | undefined;
} {
  const { getPlacesFromSearchParams } = useTripSearchParam();
  const {
    data: tripIdData,
    places: tripIdPlaces,
    isUsingTripId,
  } = useTripIdPlaces();
  const { origin, destination } = useSearch();

  const searchParamPlaces = getPlacesFromSearchParams();
  const searchParamGeocodedPlaces = useTripGeocode(
    searchParamPlaces,
    !isUsingTripId
  );

  // URL place priority from highest to lowest: tripId > search parameter > path parameter
  return {
    places: isUsingTripId
      ? tripIdPlaces
      : searchParamPlaces.length
      ? searchParamGeocodedPlaces
      : ([origin, destination].filter(
          (place) => place !== undefined
        ) as GeocodedPlace[]),
    tripIdData,
  };
}
