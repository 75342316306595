import { useIntl } from "react-intl";

import { Icon } from "src/components/Icon/Icon";
import { Hotel } from "src/svg/Hotel";
import styled from "styled-components";
import { spacing } from "src/theme";
import hotelMessages from "../../../../domain/HotelsScreen/Map/RefreshHotelSearch.messages";

export function NoHotelResults() {
  const intl = useIntl();
  return (
    <NoHotelsDiv>
      <Icon size="md">
        <Hotel tint="n200" />
      </Icon>
      <span>{intl.formatMessage(hotelMessages.noResults)}</span>
    </NoHotelsDiv>
  );
}

const NoHotelsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
  margin: ${spacing.md};
`;
