import { useState } from "react";
import { useIntl } from "react-intl";
import { TransitRadioIcon } from "src/components/RadioGroup/TransitRadioIcon";
import Spotlight from "src/components/Spotlight/Spotlight";
import { border_radius } from "src/design-system/tokens/border";
import { primitive } from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import {
  font_line_height,
  font_size,
} from "src/design-system/tokens/typography";
import styled from "styled-components";
import { Button } from "src/design-system/components/Button/Button";
import Cookies from "js-cookie";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import messages from "./TripSearchResults.messages";

type Props = {
  isChecked: boolean;
};

const CookieName = "tripRadioOnboardingDismissed";

export function TripRadioOnboarding({ isChecked }: Props) {
  const intl = useIntl();

  function closeOnboarding() {
    Cookies.set(CookieName, "true", { expires: 365 });
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:DismissRadioOnboarding"
    );
    setIsDismissed(true);
  }
  const [isDismissed, setIsDismissed] = useState(() => {
    return !!Cookies.get(CookieName);
  });

  return (
    <>
      {isDismissed ? (
        <TransitRadioIcon isChecked={isChecked} />
      ) : (
        <CustomSpotlight
          trackPosition
          DialogContent={() => (
            <DialogContent>
              <h3>
                {intl.formatMessage(messages.saveOnboarding, {
                  svg: <TransitRadioIcon isChecked={false} />,
                })}
              </h3>
              <Button onPress={closeOnboarding}>
                {intl.formatMessage(messages.onboardingDismiss)}
              </Button>
            </DialogContent>
          )}
        >
          <TransitRadioIcon isChecked={isChecked} />
        </CustomSpotlight>
      )}
    </>
  );
}

const DialogContent = styled.div`
  padding: ${spacing.xxl};
  border-radius: ${border_radius.rounded_md};
  background-color: white;
  color: ${primitive.neutral_1500};

  width: 256px;
  max-width: 100%;

  h3 {
    max-width: 20ch;
    font-size: ${font_size.text_lg};
    margin-bottom: ${spacing.xl};
    line-height: ${font_line_height.leading_tight};

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

const CustomSpotlight = styled(Spotlight)`
  display: none;
`;
