import { useLocation as useTypedLocation, useNavigate } from "react-router-dom";
import { Place } from "src/api/SearchResponse";
import { useFeature } from "src/feature/useFeature";
import { searchParamsChange } from "src/utils/location/searchParamsChange";

export function useTripSearchParam() {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const isTripURLFeature = useFeature("TripURL");

  function getPlacesFromSearchParams() {
    if (!isTripURLFeature) {
      return [];
    }
    const result = searchParams.get("search");
    return result ? result.split(",") : [];
  }

  function navigateSearchParamsFromPlaces(places: Place[], pathname?: string) {
    if (!isTripURLFeature) {
      return;
    }
    setSearchParamFromPlaces(searchParams, places);
    navigate(
      searchParamsChange(searchParams, {
        ...location,
        pathname: pathname ?? location.pathname,
      })
    );
  }

  return {
    getPlacesFromSearchParams,
    navigateSearchParamsFromPlaces,
  };
}

export function setSearchParamFromPlaces(
  searchParams: URLSearchParams,
  places: Place[]
) {
  searchParams.set("search", places.map((place) => place.canonicalName).join());
}
