import { useQuery, useQueryClient } from "react-query";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import { useApiConfig } from "src/api/ApiConfigProvider";
import useUser from "src/utils/hooks/useUser";
import { TripAttributes, TripError, getAllAccountTripPlans } from "../util/api";

export function useTripList() {
  const queryClient = useQueryClient();
  const apiConfig = useApiConfig();
  const { user } = useUser();
  const queryKey = ["accountTripPlans", user?.id];
  const isEnabled = !!user?.id;

  function onCompleteCallback(result?: TripAttributes[], error?: TripError) {
    if (result && !error) {
      sendAnalyticsNonInteractionEvent(
        "TripPlanner",
        "TripList:Fetch",
        String(result.length)
      );
    }
  }

  function deleteTripListing(id: string) {
    if (isEnabled) {
      queryClient.setQueryData(
        queryKey,
        (tripList: TripAttributes[] | undefined) => {
          if (!tripList) return [];
          return findAndDeleteTripInList(id, tripList);
        }
      );
    }
  }

  const query = useQuery<TripAttributes[]>({
    queryKey,
    enabled: isEnabled,
    queryFn: async () =>
      await getAllAccountTripPlans(onCompleteCallback, apiConfig),
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
    error: query.error,
    deleteTripListing,
  };
}

function findAndDeleteTripInList(id: string, tripList: TripAttributes[]) {
  return tripList.filter((trip) => trip.id !== id);
}
