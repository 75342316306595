import { ComponentProps, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Close } from "../../../svg/Close";
import { color, fontSize, fontWeight } from "../../../theme";
import { MergeElementProps } from "../../../utils/MergeElementProps";
import { ButtonBase } from "../../Button/ButtonBase";
import messages from "../Drawer2.messages";

type Props = MergeElementProps<
  "div",
  {
    onCloseClicked: ComponentProps<typeof ButtonBase>["onClick"];
    className?: string;
    closeButtonOnLeft?: boolean;
  }
>;

export function Drawer2Title({
  onCloseClicked,
  className,
  children,
  closeButtonOnLeft,
  ...rest
}: Props) {
  const intl = useIntl();
  const focusRef = useRef<HTMLButtonElement>(null);

  // Improve A11Y by ensuring the focus is moved to the Drawer (close button) when it opens.
  // The Drawer2 is used on mobile, and when open it obscures the screen behind it.
  // To give all users the same experience we make sure that the focus (for screen readers and mobile-keyboard users),
  // is not stuck behind the Drawer2 component.
  useEffect(() => {
    if (focusRef?.current) {
      focusRef.current.focus();
    }
  }, [focusRef]);

  return (
    <Root noHeading={!children} {...rest}>
      {children && <Heading>{children}</Heading>}
      <IconButton
        ref={focusRef}
        onClick={onCloseClicked}
        closeButtonOnLeft={closeButtonOnLeft}
      >
        <Close
          title={intl.formatMessage(messages.closeButtonTitle)}
          tint="primaryOnLight"
        />
      </IconButton>
    </Root>
  );
}

const Root = styled.div<{ noHeading: boolean }>`
  position: relative;
  ${({ noHeading }) => noHeading && "height: 40px;"}
  padding: 8px 72px;
`;

const Heading = styled.h4`
  font-size: ${fontSize.h4};
  font-weight: ${fontWeight.medium};
  text-align: center;
  color: ${color.n300};
`;

const IconButton = styled(ButtonBase)<{
  closeButtonOnLeft?: boolean;
}>`
  position: absolute;
  top: 0;
  ${(props) => (props.closeButtonOnLeft ? "left: 0" : "right: 0")};
  padding: 16px;

  background-color: ${color.white};
  svg {
    width: 12px;
    height: 12px;
    display: block;
  }
`;
