import { useEffect } from "react";
import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { Icon } from "src/components/Icon/Icon";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { Redo, Undo } from "src/svg/tripplanner/UndoRedo";
import { borderRadius, color, fontSize, iconSize, spacing } from "src/theme";
import { A11yOutline } from "src/utils/accessibility";
import styled, { css } from "styled-components";
import Tooltip from "src/components/Tooltip/Tooltip";
import messages from "./Actions.messages";

type ActionsProps = {
  redoCallback: () => void;
  undoCallback: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

export function Actions(props: ActionsProps) {
  const intl = useIntl();
  const { activeTripPlannerTab } = useTripPlannerContext();
  const isHotelTab = activeTripPlannerTab === "hotels";

  const undoMsg = intl.formatMessage(messages.undo);
  const redoMessage = intl.formatMessage(messages.redo);
  const tooltipPositionOffset = -5;
  const tooltipAnchorOffset = 5;

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        props.undoCallback();
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "y") {
        e.preventDefault();
        props.redoCallback();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  return (
    <>
      {!isHotelTab && (
        <ActionsContainer>
          <Tooltip
            id="undo"
            anchor="right"
            placement="bottom"
            anchorOffset={tooltipAnchorOffset}
            placementOffset={tooltipPositionOffset}
            tooltipContent={
              !!props.canUndo && <TooltipContent>{undoMsg}</TooltipContent>
            }
          >
            <UndoRedoButton
              title={undoMsg}
              disabled={!props.canUndo}
              onClick={props.undoCallback}
            >
              <Icon size="md">
                <Undo tint={props.canUndo ? "iconForeground" : "n50"} />
              </Icon>
            </UndoRedoButton>
          </Tooltip>

          <Tooltip
            id="redo"
            anchor="left"
            placement="bottom"
            anchorOffset={tooltipAnchorOffset}
            placementOffset={tooltipPositionOffset}
            tooltipContent={
              !!props.canRedo && <TooltipContent>{redoMessage}</TooltipContent>
            }
          >
            <UndoRedoButton
              title={redoMessage}
              disabled={!props.canRedo}
              onClick={props.redoCallback}
            >
              <Icon size="md">
                <Redo tint={props.canRedo ? "iconForeground" : "n50"} />
              </Icon>
            </UndoRedoButton>
          </Tooltip>
        </ActionsContainer>
      )}
    </>
  );
}

const UndoRedoButton = styled(ButtonBase)<{ disabled: boolean }>`
  ${A11yOutline};

  width: ${iconSize.xxxxl};
  height: ${iconSize.xxxxl};
  border-radius: ${borderRadius.lg};

  ${({ disabled }) => {
    return disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          &:hover {
            background-color: ${color.white};
            svg {
              path {
                fill: ${(props) =>
                  !disabled
                    ? props.theme.tripPlanActions.iconHover
                    : props.theme.tripPlanActions.iconDisabled};
              }
            }
          }
        `;
  }}
`;
const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TooltipContent = styled.span`
  font-size: ${fontSize.sm};
  display: block;
  padding: ${spacing.sm} ${spacing.lg};
`;
