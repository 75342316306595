import { useIntl } from "react-intl";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { useQueryClient } from "react-query";
import { ONE_DAY_IN_MILLISECONDS } from "src/utils/conversions/time";
import { TripPlannerDetails } from "../TripPlannerProvider";
import messages from "../TripPlannerScreen.messages";
import { getTripCacheKey, saveTripPlan } from "../util/api";
import { tripResponseToTripPlannerDetails } from "../util/tripResponseToTripPlannerDetails";

export function usePostTripPlan() {
  const intl = useIntl();
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();

  async function postTripPlanQuery(
    tripPlannerDetails: TripPlannerDetails,
    hasAuth: boolean = false
  ) {
    const tripPlanWithDefaults = {
      ...tripPlannerDetails,
      name: intl.formatMessage(messages.myTrip),
    };
    const cacheKey = getTripCacheKey(tripPlanWithDefaults, hasAuth);
    if (queryClient.isFetching(cacheKey)) {
      return;
    }
    return queryClient.fetchQuery(
      cacheKey,
      async () => {
        const response = await saveTripPlan(
          apiConfig,
          tripPlanWithDefaults,
          hasAuth
        );
        return tripResponseToTripPlannerDetails(response);
      },
      {
        cacheTime: ONE_DAY_IN_MILLISECONDS,
        staleTime: ONE_DAY_IN_MILLISECONDS,
      }
    );
  }

  return postTripPlanQuery;
}
