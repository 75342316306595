import { SvgProps, useColor } from "../common";

type MinusProps = {
  active?: boolean;
} & SvgProps;

export const Minus = (props: MinusProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <circle cx="7" cy="7" r="7" fill={tintColor} />
      <rect
        x="1.75"
        y="6.125"
        width="10.5"
        height="1.75"
        rx="0.875"
        fill="white"
      />
    </svg>
  );
};
