import { SvgProps, useColor } from "./common";

export const BarePlus = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  const strokeWidth = props.strokeWidth ?? "2";

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="verticalLine"
    >
      <title>{props.title}</title>
      <path
        id="Vector"
        d="M7.999924 14.4999L7.999925 0.49994"
        stroke={tintColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 2"
        d="M14.9999 7.49995L0.999908 7.49995"
        stroke={tintColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
