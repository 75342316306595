// This pattern and the values has been copied from material design. The reason
// we use transparency is because it makes the colors look good on any colored
// background, because, instead of pure black you get black with a bit of the

import { css, keyframes } from "styled-components";

// background color.
export const textColor = {
  primaryOnLight: "rgba(0, 0, 0, .87)",
  secondaryOnLight: "rgba(0, 0, 0, .54)",
  hintOnLight: "rgba(0, 0, 0, .38)",
  disabledOnLight: "rgba(0, 0, 0, .38)",
  iconOnLight: "rgba(0, 0, 0, .38)",

  primaryOnDark: "#FFFFFF",
  secondaryOnDark: "rgba(255, 255, 255, .7)",
  hintOnDark: "rgba(255, 255, 255, .5)",
  disabledOnDark: "rgba(255, 255, 255, .5)",
  iconOnDark: "rgba(255, 255, 255, .5)",

  pinkOnLight: "rgba(222, 0, 123, 0.9)",
};

/**
 * TextColor restricts text to a theme color name for text.
 */
export type TextColorType = keyof typeof textColor;

export const neutralColor = {
  n02: "rgba(255, 255, 255, 0.2)",
  n10: "#FAFAFA",
  n20: "#F3F3F3",
  n30: "#EBEBEB",
  n60: "#909090",
  n70: "#7B7B7B",
  n80: "#666666",
  n90: "#5A5A5A",
  n100: "#4E4E4E",
  n200: "#393939",
  n300: "#2B2B2B",
  n40: "#C7C7C7",
  n50: "#A2A2A2",
};

export const shadowColor = {
  cell: "#cfcfd1",
  pinkShadow: "rgba(222, 0, 123, 0.25)",
};

export const notificationColor = {
  danger: "#ffe8e1",
  warning: "#fff9e2",
  high: "#d92f0e",
  medium: "#ffa100",
  low: "#007c50",
  successDark: "#007C50",
};

const namedColors = {
  aqua: "#2EBAD3",
  blue: "#0B76DB",
  emerald: "#1B874A",
  green: "#84CA4B",
  mint: "#04C9A6",
  orange: "#E47225",
  pink: "#DE007B",
  fadedPink: "#FFD6ED",
  purple: "#734286",
  red: "#E0043B",
  yellow: "#FFAD00",
  dolphin: "#695B80",
  cod: "#1F1E1E",
  pinkHover: "#C90070",
  iconForeground: "#4A4A4A", // TODO: 202402 Multiple trips experiment
  accomBlue: "#131B60", // TODO: 202409 ACCOM scroll nudge experiment
};

const greys = {
  white: "#FFFFFF",
  grey0: "#FAFAFA",
  grey1: "#F3F3F3",
  grey2: "#EBEBEB",
  grey3: "#8E8E93",
  grey4: "#666666",
  grey5: "#313033",
  grey6: "#222222",
  black: "#000000",
  logoBlack: "#231F20",
  transparent: "transparent",
  greyMap: "#E5E3DF",
};

const attractionColors = {
  amusement: "#E8A119",
  aquarium: "#003BD4",
  architecture: "#795548",
  casino: "#FF5722",
  castle: "#9C27B0",
  church: namedColors.cod,
  fountain: "#778698",
  miscellaneous: "#2E77D7",
  mountain: "#A68E7A",
  museum: "#6058CE",
  park: "#21AC82",
  sculpture: "#9E9E9E",
  ski: "#03A9F4",
  theater: "#FF9800",
  waterfall: "#03A9F4",
  zoo: "#4CAF50",
};

export const color = {
  ...neutralColor,
  ...namedColors,
  ...greys,
  ...notificationColor,
  ...textColor,
  ...attractionColors,
};

/**
 * ColorType can hold colour names for themed-text, greys, neutral and general colours.
 */
export type ColorType = keyof typeof color;

export const breakpoints = {
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1440,
  xxl: 1740,
} as const;

/**
 *  This mediaQuery is designed with mobile first styling in mind
 *  Breakpoints:
 * - sm: 768px  (Extra small devices, phones in landscape, small tablets)
 * - md: 1024px (Small devices, tablets, large phones in landscape)
 * - lg: 1200px (Medium devices, small laptops and desktop monitors)
 * - xl: 1440px (Large devices, desktop monitors and small TVs)
 * - xxl: 1740px (Extra large devices, large desktop monitors and TVs)
 *
 * @example
 * // In a styled component:
 * const Box = styled.div`
 *   width: 100%;
 *   padding: 10px;
 *
 *   ${media.sm} {
 *     width: 50%;
 *     padding: 20px;
 *   }
 * `;
 */
export const screenMinWidth = {
  sm: `@media (min-width: ${breakpoints.sm}px)`,
  md: `@media (min-width: ${breakpoints.md}px)`,
  lg: `@media (min-width: ${breakpoints.lg}px)`,
  xl: `@media (min-width: ${breakpoints.xl}px)`,
  xxl: `@media (min-width: ${breakpoints.xxl}px)`,
} as const;

export const spacing = {
  auto: "auto",
  xs: "2px",
  sm: "4px",
  md: "8px",
  lg: "12px",
  xl: "16px",
  xxl: "24px",
  xxxl: "32px",
  xxxxl: "48px",
} as const;

export const borderRadius = {
  sm: "4px",
  md: "8px",
  lg: "12px",
  xl: "16px",
  xxl: "24px",
  xxxl: "32px",
  circle: "9999px",
} as const;

export const lineHeight = {
  tight: 1.15,
  snug: 1.35,
  normal: 1.5,
  loose: 1.85,
} as const;

export const fontSize = {
  h1: "28px",
  h2: "22px",
  h3: "20px",
  h4: "18px",
  h5: "16px",
  h6: "14px",
  /**@deprecated replaced by lg */
  body: "16px",
  label: "13px",
  xl: "18px",
  lg: "16px",
  md: "14px",
  sm: "12px",
  /** @deprecated replaced by sm */
  small: "12px",
  title: "22px",
} as const;

export const logoSize = {
  md: { mobile: "26px", desktop: "34px" },
  lg: { mobile: "32px", desktop: "40px" },
};

export const fontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;

export const letterSpacing = {
  wide: ".025em",
  wider: ".05em",
} as const;

export const iconSize = {
  xxs: "8px",
  xs: "10px",
  "sm-1": "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "20px",
  xxl: "24px",
  xxxl: "32px",
  xxxxl: "40px",
  xxxxxl: "48px",
} as const;

export const transitColor = {
  animal: color.grey6,
  bike: color.green,
  bus: color.orange,
  busferry: color.orange,
  cablecar: color.purple,
  car: color.grey4,
  carferry: color.aqua,
  ferry: color.aqua,
  helicopter: color.mint,
  plane: color.mint,
  rideshare: color.red,
  shuttle: color.red,
  taxi: color.yellow,
  train: color.purple,
  tram: color.purple,
  towncar: color.grey6,
  unknown: color.grey3,
  walk: color.grey3,
  multi: color.pink,
};

export const elevation = {
  high: "0px 4px 50px rgba(31, 30, 30, 0.18)",
  mid: "0px 1px 15px rgba(31, 30, 30, 0.15)",
  none: `0px 0px 0px 1px ${color.n30}`,
};

export const zIndex = {
  dropdown: 10003,
  toast: 10002,
  map: 10001,
  feedbackButton: 10000,
};

export const duration = {
  xs: 50,
  sm: 100,
  md: 200,
  lg: 400,
  xl: 800,
} as const;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeInOnRender = css`
  animation: ${fadeInAnimation} ${duration.md}ms ease-out;
`;

export const logosWidth = "70px";
