import {
  Draggable,
  DraggableProps,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { MouseEvent, ReactNode, useRef, useState } from "react";
import { MergeElementProps } from "src/utils/MergeElementProps";
import styled, { css, keyframes } from "styled-components";
import { SortableObject } from "./DraggableItem";

const DELETE_DURATION_ANIMATION = 375;

export type DraggableElementChildrenFn<T> = (props: {
  dragHandleProps: Partial<DraggableProvidedDragHandleProps> & {
    "data-rbd-drag-handle-draggable-id": string;
  };
  onClickRemoveButton: (event: MouseEvent<HTMLButtonElement>) => void;
  item: SortableObject<T>;
  index: number;
  snapshot: DraggableStateSnapshot;
  droppableSnapshot?: DroppableStateSnapshot;
}) => Element | ReactNode;

type ItemProps<T> = MergeElementProps<
  "li",
  {
    item: SortableObject<T>;
    index: number;
    droppableSnapshot?: DroppableStateSnapshot;
    handleRemove: () => void;
    children: DraggableElementChildrenFn<T>;
    draggableOptions?: Partial<DraggableProps>;
  }
>;
export function DraggableElement<T>({
  item,
  index,
  handleRemove,
  children,
  droppableSnapshot,
  ...props
}: ItemProps<T>) {
  // deleteAnimation state is used to trigger the animation
  const [deleteAnimation, setDeleteAnimation] = useState(false);

  function onClickRemoveButton(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setDeleteAnimation(true);
    setTimeout(() => {
      handleRemove();
    }, DELETE_DURATION_ANIMATION);
  }

  const itemReference = useRef<HTMLLIElement | null>(null);

  return (
    <Draggable {...props.draggableOptions} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ItemContainer
          ref={(ref) => {
            itemReference.current = ref;
            provided.innerRef(ref);
          }}
          {...provided.draggableProps}
          {...props}
          $delete={deleteAnimation}
          $index={index}
          $isDraggingOver={snapshot.isDragging}
          data-index={index}
          aria-label={item.content}
          aria-describedby={`text-${item.id}`}
          $height={itemReference.current?.clientHeight}
        >
          {
            children({
              dragHandleProps: {
                ...provided.dragHandleProps,
                "data-rbd-drag-handle-draggable-id": item.id,
              },
              onClickRemoveButton,
              item,
              index,
              snapshot,
              droppableSnapshot,
            }) as ReactNode
          }
        </ItemContainer>
      )}
    </Draggable>
  );
}

const RemoveAnimation = keyframes`
  0% {
    transform: scaleY(1);
    opacity: 1;
    margin-bottom: 0;
  }
  100% {
    transform: scaleY(0);
    opacity: 0;
    margin-bottom: var(--height-element);
  }
`;

const ItemContainer = styled.li<{
  $delete: boolean;
  $index: number;
  $isDraggingOver?: boolean;
  $height?: number;
}>`
  --height-element: -${({ $height }) => $height}px;
  display: flex;
  ${({ $delete }) => {
    if ($delete) {
      return css`
        animation: ${RemoveAnimation} ${DELETE_DURATION_ANIMATION}ms ease-in-out
          both;
      `;
    }
    return css`
      animation: "none";
    `;
  }};
`;
