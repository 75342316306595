import { useState } from "react";
import { HotelProviderKind } from "src/utils/types/accommodationProviders";
import styled from "styled-components";
import { Icon } from "../../../components/Icon/Icon";
import { VisuallyHidden } from "../../../components/VisuallyHidden/VisuallyHidden";
import { HotelAlternative } from "../../../svg/HotelAlternative";
import { borderRadius, color } from "../../../theme";
import { useLayout } from "../../../utils/hooks/useLayout";
import { getSizedImagesFromProvider } from "../utils_provider-specific";
import HotelGalleryLoading from "./HotelGalleryLoading";

type HotelGalleryProps = {
  src?: string;
  alt?: string;
  provider?: HotelProviderKind;
  variant: "pin" | "list" | "mobilePin" | "trip";
};

export default function HotelGallery({
  src,
  alt,
  provider,
  variant,
}: HotelGalleryProps) {
  const [hasError, setHasError] = useState(false);
  const isLoading = !src;
  const isMobile = useLayout() === "mobile";

  const variantStyles = {
    list: {
      width: isMobile ? "100%" : 250,
      height: isMobile ? 110 : 175,
      minWidth: 250,
    } as React.CSSProperties,
    pin: {
      width: isMobile ? 120 : 260,
      height: isMobile ? 163 : 180,
    } as React.CSSProperties,
    mobilePin: {
      width: 120,
      height: 163,
    } as React.CSSProperties,
    trip: {
      width: 160,
      height: 100,
      borderRadius: borderRadius.sm,
    } as React.CSSProperties,
  };

  const sizedImages =
    provider && src ? getSizedImagesFromProvider(provider, src) : undefined;

  const [isMainImageLoaded, setIsMainImageLoaded] = useState(false);

  return (
    <GalleryDiv style={variantStyles[variant]}>
      {hasError ? (
        <Placeholder>
          <HotelIcon size="xxl">
            <HotelAlternative tint="black" />
          </HotelIcon>
          <VisuallyHidden>
            <h2>{alt}</h2>
          </VisuallyHidden>
        </Placeholder>
      ) : null}
      {/* TODO: Determine if we can remove loading state */}
      {isLoading ? <HotelGalleryLoading /> : null}

      {!hasError && sizedImages ? (
        <>
          <TemporaryImage
            src={sizedImages.thumbnail}
            alt="Image loading"
            aria-hidden={isMainImageLoaded}
            $hide={isMainImageLoaded}
          />
          <HotelImg
            src={sizedImages.regular}
            alt={alt}
            onError={() => setHasError(true)}
            onLoad={() => setIsMainImageLoaded(true)}
            $imgLoaded={isMainImageLoaded}
          />
        </>
      ) : null}
    </GalleryDiv>
  );
}

const GalleryDiv = styled.div`
  position: relative;
  overflow: hidden;
`;

const HotelImg = styled.img<{ $imgLoaded: Boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(props) => (props.src && props.$imgLoaded ? 1 : 0)};
  pointer-events: none;
`;

// TemporaryImage is a lightweight placeholder image that functionally displays as a blur while the detailed image is loading.
const TemporaryImage = styled.img<{ $hide: Boolean }>`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.src && !props.$hide ? 1 : 0)};
  transition: opacity 200ms;
  filter: blur(4px);
  transform-origin: center;
  pointer-events: none;
`;

const HotelIcon = styled(Icon)`
  width: 36px;
  height: 36px;
`;

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.grey2};
  width: 100%;
  height: 100%;
`;
