import { FC, SyntheticEvent, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { AutocompleteKind } from "src/domain/AutocompleteScreen/AutocompleteScreen";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import { FocusContext } from "src/FocusContext";
import { GeocodedPlace } from "src/PrefetchData";
import { AutocompletePlace } from "../../../../api/AutocompleteResponse";
import useAutocomplete, {
  useKeyboardAutocompleteNavigation,
} from "../../../../utils/hooks/useAutocomplete";
import { AutocompleteInput } from "../../../AutocompleteInput/AutocompleteInput";
import { AutocompleteList } from "../../../AutocompleteList/AutocompleteList";

export function AddDestinationInput(props: {
  onSelectOption: (place: AutocompletePlace) => void;
  id: string;
  type: AutocompleteKind;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  floatingList?: boolean;
  onFocus?: (e: SyntheticEvent) => void;
  onChange?: (query: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  AutocompleteListWrapper?: FC<React.PropsWithChildren<unknown>>;
  initialValue?: string;
  solidBackground?: boolean;
  hideList?: boolean;
  placeholder?: string;
  hideIcon?: boolean;
  transparentBackground?: boolean;
  addDestinationRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  notRelative?: boolean;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  curvedBorder?: boolean;
  noShadow?: boolean;
  noBorder?: boolean;
  isFocused?: boolean;
  showClearIcon?: boolean;
  handleClear?: () => void;
  handleClickAway?: () => void;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  horizontalSearch?: boolean;
  index?: number;
  filteredPlaces?: GeocodedPlace[];
}) {
  const autocompleteListScrollRef = useRef<HTMLDivElement>(null);
  const { AutocompleteListWrapper = DefaultAutocompleteWrapper } = props;
  const { tripPlannerDetails } = useTripPlannerContext();
  const {
    results: rawResults,
    query,
    changeQuery,
  } = useAutocomplete(props.initialValue, undefined, false);

  let results = rawResults;
  const { filteredPlaces } = props;
  if (filteredPlaces?.length) {
    results = results.filter(
      (result) =>
        !filteredPlaces.some(
          (place) =>
            place.canonicalName === result.canonicalName ||
            place.longName === result.longName // To catch places with multiple canonical names (e.g Berlin, Berlin-Germany)
        )
    );
  }

  const autocompleteResults = props.hideList ? [] : results;
  function onPressEscape() {
    changeQuery("");
  }
  const { onKeyDown, focusRef, resetFocus, onFocusChanged, focusedIndex } =
    useKeyboardAutocompleteNavigation({
      id: props.id,
      results,
      onPressEnter: props.onSelectOption,
      onPressEscape,
      onPressTab: resetInput,
      scrollContainer: autocompleteListScrollRef.current,
    });

  function onChangeCallback(query: string) {
    changeQuery(query);
    if (props.onChange) {
      props.onChange(query);
    }
  }

  function resetInput() {
    changeQuery(props.initialValue || "");
    resetFocus();
    props.handleClickAway?.();
  }

  function handleClickAway(e: MouseEvent) {
    if (e.target !== props.addDestinationRef?.current) {
      resetInput();
    }
  }

  useEffect(() => {
    if (props.hideList === true && !props.horizontalSearch) {
      changeQuery("");
      resetFocus();
    }
  }, [props.hideList, changeQuery, resetFocus, props.horizontalSearch]);

  // Prefill input on load
  useEffect(() => {
    if (props.initialValue) {
      changeQuery(props.initialValue);
    }
    // Ignore exhaustive deps because we only want to run this if an input is
    // empty and another destination is added.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripPlannerDetails.places]);

  return (
    <FocusContext.Provider
      value={{
        focusedElement: { id: props.id, index: focusedIndex },
        onFocusChanged,
      }}
    >
      <InputWrapper notRelative={props.notRelative} fullWidth={props.fullWidth}>
        <AutocompleteInput
          type={props.type}
          value={!props.disabled ? query : ""}
          onChange={onChangeCallback}
          id={props.id}
          disabled={props.disabled}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          solidBackground={props.solidBackground}
          icon={props.icon}
          onKeyDown={onKeyDown}
          // We can disable this autoFocus rule because we only ever have one instance
          // in the DOM at any one time.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          transparentBackground={props.transparentBackground}
          noShadow={props.noShadow}
          noBorder={props.noBorder}
          className={props.className}
          addDestinationRef={props.addDestinationRef}
          fullWidth={props.fullWidth}
          curvedBorder={props.curvedBorder}
          isFocused={props.isFocused}
          handleClear={props.handleClear}
          showClearIcon={props.showClearIcon}
          horizontalSearch={props.horizontalSearch}
          index={props.index}
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          {props.initialValue === query ||
            (!props.disabled && Boolean(autocompleteResults.length > 0) && (
              <AutocompleteListWrapper ref={autocompleteListScrollRef}>
                <AutocompleteList
                  size="sm"
                  iconSize="xxl"
                  results={results}
                  onSelectPlace={props.onSelectOption}
                  focusRef={focusRef}
                />
              </AutocompleteListWrapper>
            ))}
        </ClickAwayListener>
      </InputWrapper>
    </FocusContext.Provider>
  );
}

const DefaultAutocompleteWrapper = styled.div``;

const InputWrapper = styled.div<{ notRelative?: boolean; fullWidth?: boolean }>`
  position: ${(props) => !props.notRelative && "relative"};
  display: block;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;
