import styled, { keyframes } from "styled-components";
import greyImageBackground from "./grey.webp";

export default function HotelGalleryLoading() {
  return (
    <LoadingSkeletonImg
      src={greyImageBackground}
      aria-label="loading"
      aria-disabled={true}
    />
  );
}

const fadeInOutKeyframes = keyframes`
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`;

const LoadingSkeletonImg = styled.img`
  width: 100%;
  height: 100%;
  filter: blur(2px);
  animation: ${fadeInOutKeyframes} 1s ease-in-out infinite;
`;
