import { ElementType, ReactElement, useMemo } from "react";
import styled from "styled-components";
import { Collapse } from "../Transition/Collapse";
import { AccordionProvider } from "./AccordionProvider";

type Props = {
  expanded: boolean;
  onChange: (isExpanded: boolean) => void;
  children: ReactElement[];
  onMouseEnter?: () => void;
  as?: ElementType;
  className?: string;
  skipInitialAnimation?: boolean;
};

export function Accordion(props: Props) {
  const [summary, ...children] = props.children;

  const state = useMemo(() => {
    return {
      expanded: props.expanded,
      onChange: props.onChange,
    };
  }, [props.expanded, props.onChange]);

  return (
    <AccordionRoot
      onMouseEnter={props.onMouseEnter}
      as={props.as}
      className={props.className}
    >
      <AccordionProvider value={state}>{summary}</AccordionProvider>
      <Collapse
        open={props.expanded}
        skipInitialAnimation={props.skipInitialAnimation}
      >
        <div
          aria-labelledby={summary.props.id}
          id={summary.props["aria-controls"]}
          role="region"
        >
          {children}
        </div>
      </Collapse>
    </AccordionRoot>
  );
}

const AccordionRoot = styled.div`
  position: relative;
`;
