import { defineMessages } from "react-intl";

const messages = defineMessages({
  refreshHotelSearch: {
    defaultMessage: "Search Hotels Here",
    id: "hotel.refreshHotelSearch",
    description:
      "Searches for hotels again on the map in the location the user has moved to",
  },
  noResults: {
    defaultMessage: "No Hotels Found",
    id: "hotel.noResults",
    description:
      "Shows when there are no results found when pressing the refresh hotel search button",
  },
});

export default messages;
