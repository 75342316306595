import { defineMessages } from "react-intl";

const messages = defineMessages({
  accessibleMode: {
    id: "tripPlanner.reorderingTrips.accessibleMode",
    description:
      "Accessible mode button, this button will be used to toggle accessible mode",
    defaultMessage: "Accessible mode",
  },
  cancel: {
    id: "tripPlanner.reorderingTrips.cancel",
    description: "Cancel button",
    defaultMessage: "Cancel",
  },
  updateTrip: {
    id: "tripPlanner.reorderingTrips.updateTrip",
    description: "Update trip button",
    defaultMessage: "Update trip",
  },
  reorderTitle: {
    id: "tripPlanner.reorderingTrips.reorderTitle",
    description: "Reorder trips title",
    defaultMessage: "Update your plans",
  },
  reorderDescription: {
    id: "tripPlanner.reorderingTrips.reorderDescription",
    description: "Reorder trips description",
    defaultMessage: "Change the order of your trip",
  },
  titleConfirmation: {
    id: "tripPlanner.reorderingTrips.titleConfirmation",
    description: "Title confirmation",
    defaultMessage: "Update your trip?",
  },
  descriptionConfirmation: {
    id: "tripPlanner.reorderingTrips.descriptionConfirmation",
    description: "Title confirmation",
    defaultMessage:
      "Changing the order of your destinations may delete some of your saved travel options.",
  },
  dontAskAgain: {
    id: "tripPlanner.reorderingTrips.dontAskAgain",
    description:
      "Don`t ask again checkbox, this checkbox will be used to toggle dont ask again so this modal will not be shown again",
    defaultMessage: "Don’t ask me this again",
  },
  notification: {
    id: "tripPlanner.reorderingTrips.notification",
    description: "Notification when trip is updated",
    defaultMessage: "Trip updated!",
  },
});

export default messages;
