import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
  Dispatch,
  useMemo,
} from "react";

type TwoColumnLayoutContextState = {
  setIsWideTwoColLayout: Dispatch<SetStateAction<boolean>>;
  setSegmentScreenActive: Dispatch<SetStateAction<boolean>>;
  isWideTwoColLayout: boolean;
  segmentScreenActive: boolean;
};

export const TwoColumnLayoutContext =
  createContext<TwoColumnLayoutContextState>({
    setIsWideTwoColLayout: () => {},
    setSegmentScreenActive: () => {},
    isWideTwoColLayout: false,
    segmentScreenActive: false,
  });

export function useTwoColumnLayoutContext() {
  const context = useContext(TwoColumnLayoutContext);
  if (!context) {
    throw new Error(
      `useTwoColumnLayoutContext must be used within a TwoColumnLayoutProvider.`
    );
  }
  return context;
}

export function TwoColumnLayoutProvider(props: PropsWithChildren<{}>) {
  const [isWideTwoColLayout, setIsWideTwoColLayout] = useState<boolean>(true);
  const [segmentScreenActive, setSegmentScreenActive] = useState<boolean>(true);

  const providerValue = useMemo(
    () => ({
      isWideTwoColLayout: isWideTwoColLayout,
      segmentScreenActive: segmentScreenActive,
      setIsWideTwoColLayout,
      setSegmentScreenActive,
    }),
    [isWideTwoColLayout, segmentScreenActive]
  );

  return (
    <TwoColumnLayoutContext.Provider value={providerValue}>
      {props.children}
    </TwoColumnLayoutContext.Provider>
  );
}
