import { CSSProperties, ReactNode, useCallback, useState } from "react";
import { animated, useTransition } from "@react-spring/web";
import styled from "styled-components";
import { MergeProps } from "../../utils/MergeProps";
import { TransitionProps } from "./TransitionProps";

type CollapseProps = MergeProps<
  TransitionProps,
  {
    children: ReactNode;
    className?: string;
    childClassName?: string;
    skipInitialAnimation?: boolean;
  }
>;

export function Collapse(props: CollapseProps) {
  const { open, onEnter, onExited, children, className, childClassName } =
    props;
  const [viewHeight, setViewHeight] = useState(0);
  const [staticStyle, setStaticStyle] = useState<CSSProperties>({});

  const childRef = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    setViewHeight(node.clientHeight);
  }, []);

  const transitions = useTransition(open, {
    initial: props.skipInitialAnimation ? false : null,
    from: { height: 0 },
    enter: { height: viewHeight },
    leave: { height: 0 },
    update: { height: viewHeight },
    onStart: () => {
      setStaticStyle({});
      if (open) {
        onEnter && onEnter();
      }
    },
    onRest: () => {
      if (open) {
        setStaticStyle({ height: "auto" });
      } else {
        onExited && onExited();
        setStaticStyle({});
      }
    },
  });

  return transitions(
    (style, item, { key }) =>
      item && (
        <CollapseRoot
          style={{ ...style, ...staticStyle }}
          className={className}
          key={key}
        >
          <div ref={childRef} className={childClassName}>
            {children}
          </div>
        </CollapseRoot>
      )
  );
}

const CollapseRoot = styled(animated.div)`
  will-change: height;
  overflow: hidden;
`;
