import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { searchParamsChange } from "src/utils/location/searchParamsChange";
import { useFeature } from "src/feature/useFeature";

export const MAX_SEARCH_PARAM_PLACES = 6;

/** Manually switch to using TripId in the URL*/
export function useSwitchURLToTripId() {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const isTripURLFeature = useFeature("TripURL");

  const switchToTripId = useCallback(
    (tripId: string, pathname?: string) => {
      if (isTripURLFeature) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("tripId", tripId);
        searchParams.delete("search");
        navigate(
          searchParamsChange(searchParams, {
            ...location,
            pathname: pathname ?? location.pathname,
          }),
          {}
        );
      }
    },
    [isTripURLFeature, navigate, location]
  );

  const clearTripId = useCallback(
    (pathname?: string) => {
      if (isTripURLFeature) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("tripId");
        navigate(
          searchParamsChange(searchParams, {
            ...location,
            pathname: pathname ?? location.pathname,
          }),
          {}
        );
      }
    },
    [isTripURLFeature, location, navigate]
  );
  return { switchToTripId, clearTripId };
}
