import { defineMessages } from "react-intl";

const messages = defineMessages({
  transfer: {
    id: "timeline.transfer",
    description: "Note for when to change or transfer between modes",
    defaultMessage: "Change / Transfer",
  },
});

export default messages;
