import { defineMessages } from "react-intl";

const messages = defineMessages({
  currentTrip: {
    id: "myTripsModal.currentTrip",
    description: "Label for the current trip in the list",
    defaultMessage: "CURRENT TRIP",
  },
  deleteTrip: {
    id: "myTripsModal.deleteTrip",
    description: "Label for the delete trip button",
    defaultMessage: "Delete trip",
  },
  destinations: {
    id: "myTripsModal.destinations",
    description: "Label for the number of destinations in the trip",
    defaultMessage: "{numDestinations} destinations",
  },
});

export default messages;
