import { useEffect } from "react";
import { useTripTransportIndex } from "src/utils/hooks/useTripTransportIndex";
import { useTripPlannerContext } from "./useTripPlannerContext";

export function useTripSearchResponse() {
  const { tripRoutes } = useTripPlannerContext();
  const transportIndex = useTripTransportIndex();
  const searchQuery = tripRoutes.queries[transportIndex ?? 0];
  const placePair = tripRoutes.placePairs[transportIndex ?? 0];

  useEffect(() => {
    if (
      transportIndex !== undefined &&
      !tripRoutes.activeQueries[transportIndex]
    ) {
      tripRoutes.setActiveQueries({
        index: transportIndex,
        value: true,
      });
    }
  }, [transportIndex, tripRoutes, searchQuery]);

  if (!placePair) {
    return {
      tripOrigin: undefined,
      tripDestination: undefined,
      tripSearchResponse: undefined,
      isTripSearchResponseLoading: false,
    };
  }

  return {
    tripOrigin: placePair.origin,
    tripDestination: placePair.destination,
    tripSearchResponse: searchQuery?.data,
    isTripSearchResponseLoading: searchQuery?.isLoading,
  };
}
