import { SearchResponse } from "src/api/SearchResponse";
import {
  destinationPlaceFromSearch,
  originPlaceFromSearch,
} from "src/utils/adapters/place";
import { GeocodedPlace } from "src/PrefetchData";
import { TripPlannerDetails } from "../TripPlannerProvider";

type TripPlanFromSearch = {
  places: TripPlannerDetails["places"];
  transport: {};
};

export function createTripPlanFromSearchResponse(
  searchResponse: SearchResponse
): TripPlanFromSearch {
  if (searchResponse.places.length < 2) {
    return {
      places: [],
      transport: {},
    };
  }

  const origin = originPlaceFromSearch(searchResponse);
  const destination = destinationPlaceFromSearch(searchResponse);

  return {
    places: [origin as GeocodedPlace, destination as GeocodedPlace],
    transport: {},
  };
}
