import { useIntl } from "react-intl";
import { Icon } from "src/components/Icon/Icon";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { Suitcase } from "src/svg/Suitcase";
import { Trash } from "src/svg/Trash";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  letterSpacing,
  spacing,
} from "src/theme";
import { A11yOutline } from "src/utils/accessibility";
import styled from "styled-components";
import messages from "./MyTripCardContent.messages";
type MyTripCardCallbacks = {
  onClickDelete?: () => void;
  onClick?: () => void;
};

export type MyTripCardContentProps = {
  title: string;
  numDestinations: number;
  isCurrent?: boolean;
  isLoggedOut?: boolean;
};

export function MyTripCardContent(
  props: MyTripCardContentProps & MyTripCardCallbacks
) {
  const intl = useIntl();

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const focusableElements = document.getElementsByClassName(
        "myTripCard"
      ) as HTMLCollectionOf<HTMLElement>;
      if (!focusableElements) {
        return;
      }
      const currentIndex = Array.from(focusableElements).findIndex(
        (element) => element === document.activeElement
      );
      if (currentIndex === -1) {
        return;
      }
      if (event.key === "ArrowUp" && currentIndex > 0) {
        focusableElements[currentIndex - 1].focus();
      }
      if (
        event.key === "ArrowDown" &&
        currentIndex < focusableElements.length - 1
      ) {
        focusableElements[currentIndex + 1].focus();
      }
    }
    if (event.key === " " || event.key === "Enter") {
      props.onClick?.();
    }
  }

  return (
    <MyTripCardWrapper
      title={props.title}
      onClick={props.onClick}
      isLoggedOut={props.isLoggedOut}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className="myTripCard"
      data-testid="myTripCard"
    >
      <TripCardInner>
        <IconWrapper>
          <Icon size="sm">
            <Suitcase tint={props.isLoggedOut ? "n50" : "cod"} />
          </Icon>
        </IconWrapper>
        <DetailsWrapper>
          <Title isLoggedOut={props.isLoggedOut}>{props.title}</Title>
          <Destinations isLoggedOut={props.isLoggedOut}>
            {intl.formatMessage(messages.destinations, {
              numDestinations: props.numDestinations,
            })}
          </Destinations>
        </DetailsWrapper>
        {!props.isLoggedOut && !props.isCurrent && (
          <DeleteButton
            title={intl.formatMessage(messages.deleteTrip)}
            onKeyDown={(e) => {
              // Prevent click from bubbling up to the card.
              e.stopPropagation();
            }}
            onClick={(e) => {
              // Prevent click from bubbling up to the card.
              e.stopPropagation();
              props.onClickDelete?.();
            }}
          >
            <Icon size="sm">
              <Trash tint="cod" />
            </Icon>
          </DeleteButton>
        )}
      </TripCardInner>
      {props.isCurrent && !props.isLoggedOut && (
        <Pill as="label">{intl.formatMessage(messages.currentTrip)}</Pill>
      )}
    </MyTripCardWrapper>
  );
}

export function MyTripCardSkeleton() {
  return (
    <MyTripCardWrapper>
      <TripCardInner>
        <IconWrapper>
          <Icon size="sm">
            <Suitcase tint="n50" />
          </Icon>
        </IconWrapper>
        <DetailsWrapper>
          <Skeleton width="100px" height="20px" margin={`0 0 ${spacing.sm}`} />
          <Skeleton width="40px" height="10px" />
        </DetailsWrapper>
      </TripCardInner>
    </MyTripCardWrapper>
  );
}

const DeleteButton = styled.button`
  border-radius: ${borderRadius.md};
  border: none;
  opacity: 0;
  width: 0;
  cursor: pointer;
  ${A11yOutline}

  &:focus-visible {
    width: unset;
    padding: ${spacing.lg};
    opacity: 1;
  }

  &:hover {
    background-color: ${color.white};
    svg {
      path {
        fill: ${color.red};
        stroke: ${color.red};
      }
    }
  }
`;

const MyTripCardWrapper = styled.li<{ isLoggedOut?: boolean }>`
  list-style: none;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius.md};
  padding: ${spacing.lg} ${spacing.lg} ${spacing.lg} 0;
  ${A11yOutline};
  outline-offset: -2px;

  &:hover {
    background-color: ${(props) =>
      props.isLoggedOut ? "transparent" : "#f2f2f2"};

    cursor: ${(props) => (props.isLoggedOut ? "default" : "pointer")};
    ${DeleteButton} {
      width: unset;
      padding: ${spacing.lg};
      opacity: 1;
    }
  }
  &:focus-visible {
    ${DeleteButton} {
      width: unset;
      padding: ${spacing.lg};
      opacity: 1;
    }
  }
`;

const TripCardInner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  gap: ${spacing.lg};
`;

const Title = styled.h4<{ isLoggedOut?: boolean }>`
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.medium};
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(props) => (props.isLoggedOut ? color.n50 : color.cod)};
`;

const IconWrapper = styled.div`
  border-radius: ${borderRadius.md};
  padding: ${spacing.lg};
  background-color: #f2f2f2;
`;

const DetailsWrapper = styled.div`
  max-width: 100%;
  flex: 1;
`;

const Destinations = styled.div<{ isLoggedOut?: boolean }>`
  color: ${(props) => (props.isLoggedOut ? color.n40 : color.n90)};
  font-size: ${fontSize.md};
  margin-bottom: ${spacing.sm};
`;

const Pill = styled.span`
  margin-left: ${spacing.xxxxl};
  background-color: #e6f2ee;
  border-radius: ${borderRadius.lg};
  color: #007c50;
  text-transform: uppercase;
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.medium};
  letter-spacing: ${letterSpacing.wider};
  padding: 5px; // ds is 5px :/
`;
