import styled from "styled-components";
import { useIntl } from "react-intl";
import { Fragment } from "react";
import { useNavigate } from "react-router";
import { isSaveableSearchResponse } from "src/domain/TripPlanner/util/isSaveableSearchResponse";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { updateTripPlanningState } from "src/domain/TripPlanner/hooks/useTripPlanningState";
import { mobileLayout } from "src/utils/hooks/useLayout";
import { Icon } from "../../../components/Icon/Icon";
import { LetterLogo } from "../../../svg/LetterLogo";
import { ArrowForward } from "../../../svg/ArrowForward";
import { Search as SearchIcon } from "../../../svg/Search";
import { fontSize, spacing } from "../../../theme";
import { useTheme } from "../../../utils/hooks/useTheme";
import { SaveScreenButton } from "../../../components/TripPlanner/SaveButton/SaveButton";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";
import useSearch from "../../../utils/hooks/useSearch";
import { useIsHotelsUrlDeeplink } from "../../../utils/hooks/useNavigateToHotelsPage";
import { navigateToNewStateHash } from "../../../utils/location/navigateToNewStateHash";
import messages from "./SmallSearchBox.messages";

type Props = {
  origin?: string;
  destination?: string;
  onClick: () => void;
  className?: string;
  textOverride?: string;
};

export function SmallSearchBox(props: Props) {
  const intl = useIntl();
  const theme = useTheme();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { searchResponse } = useSearch();
  const { tripPlannerDetails, dispatch, tripPlanningState } =
    useTripPlannerContext();
  const isHotelScreen = useIsHotelsUrlDeeplink();
  const isSaveableScreen = !isHotelScreen && searchResponse;

  const SearchIconsWrapper = isSaveableScreen ? SearchIcons : Fragment;
  function handleTripSave() {
    if (searchResponse) {
      dispatch({
        type: "SAVE_SEARCH",
        searchResponse,
        url: {
          pathname: location.pathname,
          hash: location.hash,
        },
      });

      updateTripPlanningState(
        tripPlanningState,
        tripPlannerDetails,
        searchResponse,
        undefined,
        undefined,
        "Search"
      );

      navigateToNewStateHash(navigate, { highlightedTab: "trips" }, "#trips", {
        ...location,
      });
    }
  }

  return (
    <Search className={props.className}>
      <SearchInner>
        <LogoButton
          title={intl.formatMessage(messages.search)}
          onClick={(event) => {
            props.onClick();
            event.preventDefault();
          }}
        >
          <StyledLogoIcon size="xxl">
            <LetterLogo
              title={intl.formatMessage(messages.logoLabel)}
              tint="pink"
            />
          </StyledLogoIcon>
        </LogoButton>
        <SearchButton
          title={intl.formatMessage(messages.search)}
          onClick={(event) => {
            props.onClick();
            event.preventDefault();
          }}
        >
          <SearchTextWrapper>
            <SearchTextRow>
              {props.textOverride ? (
                <LongSearchText>{props.textOverride}</LongSearchText>
              ) : (
                <>
                  <SearchText>{props.origin ?? ""}</SearchText>
                  <Icon size="sm">
                    <ArrowForward
                      title="arrow-forward"
                      tint={theme.searchBar.iconTint}
                    />
                  </Icon>
                  <SearchText>{props.destination ?? ""}</SearchText>
                </>
              )}
            </SearchTextRow>
          </SearchTextWrapper>
        </SearchButton>
        <SearchIconsWrapper>
          <SearchIconButton onClick={props.onClick}>
            <StyledSearchIcon
              spacingSize="sm"
              size={isSaveableScreen ? "md" : "xl"}
            >
              <SearchIcon
                title="search"
                tint={isSaveableScreen ? "n90" : theme.searchBar.input.iconTint}
              />
            </StyledSearchIcon>
          </SearchIconButton>

          {isSaveableScreen && (
            <SaveScreenButton
              padding={`0 0 0 ${spacing.sm}`}
              isDisabled={!isSaveableSearchResponse(searchResponse)}
              saveStateClick={handleTripSave}
            />
          )}
        </SearchIconsWrapper>
      </SearchInner>
    </Search>
  );
}

const Search = styled.div`
  padding: ${spacing.lg};
  background-color: ${(props) => props.theme.searchBar.background};
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
`;

const SearchInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;

  padding: 0 ${spacing.md};
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  background-color: ${(props) => props.theme.searchBar.input.background};

  &:active {
    background-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
    -webkit-tap-highlight-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
  }
`;

const LogoButton = styled.button`
  display: flex;
  align-items: center;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  ${mobileLayout} {
    max-width: 75%;
  }
`;

const SearchTextWrapper = styled.div`
  margin: 0 ${spacing.sm};
  flex-grow: 1;
  overflow: hidden;
`;

const SearchTextRow = styled.div`
  margin: 0 ${spacing.sm};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchText = styled.span`
  display: block;
  border: none;
  font-size: ${fontSize.body};
  text-overflow: ellipsis;
  background-color: transparent;
  white-space: nowrap;
  overflow-x: hidden;
  text-align: left;
  margin: 0 ${spacing.md};
  max-width: 50%;
  color: ${(props) => props.theme.searchBar.input.text};

  &:focus {
    outline: none;
  }
`;

const LongSearchText = styled(SearchText)`
  max-width: 100%;
`;

const StyledLogoIcon = styled(Icon)`
  margin-left: ${spacing.sm};
`;

const SearchIconButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledSearchIcon = styled(Icon)<{ spacingSize?: keyof typeof spacing }>`
  margin-right: ${({ spacingSize }) => spacingSize && spacing[spacingSize]};
  margin-top: -1px;
`;
const SearchIcons = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${spacing.sm} 0 0;
  gap: ${spacing.sm};
  > *:last-child {
    margin-right: 0;
    height: 100%;
  }
`;
