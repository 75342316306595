import Cookies from "js-cookie";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import { Dropdown } from "src/components/Dropdown/Dropdown";
import { useShowMyTripsModal } from "src/components/Map/TripPlannerMap/util/useShowMyTripsModal";
import { borderRadius, color, fontSize, fontWeight, spacing } from "src/theme";
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import styled from "styled-components";
import { NavButton } from "src/domain/TopNav/NavButton";
import { isLoggedIn } from "src/auth/utils/session";
import { useTripList } from "../hooks/useTripList";
import { MyTrips } from "../MyTrips/MyTrips";
import { MultiSearchModal } from "../MultiSearch/MultiSearchModal";
import messages from "./MyTripsModal.messsages";

const PAGE_SIZE = 5;
const HAS_SEEN_MY_TRIPS_LOGIN_BANNER = "hasSeenMyTripsLoginBanner";

export function MyTripsModal() {
  const [showMultiSearchModal, setShowMultiSearchModal] = useState(false);
  const intl = useIntl();
  const isTripsScreen = useIsTripScreen();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const { data } = useTripList();
  const startedLoggedIn = useRef(isLoggedIn());
  const { user, loadingSession } = useUser();
  const isUserLoggedIn = !!user?.id;
  const { showMyTripsModal, setShowMyTripsModal } = useShowMyTripsModal();
  const seenLoginBanner =
    Cookies.get(HAS_SEEN_MY_TRIPS_LOGIN_BANNER) === "true";
  const [numVisibleTrips, setNumVisibleTrips] = useState(PAGE_SIZE);

  const handleMyTripsClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      sendAnalyticsInteractionEvent(
        "TripPlanner",
        "Click:MyTrips:MultipleTrips"
      );
      setShowMyTripsModal(!showMyTripsModal);

      if (!isTripsScreen && !isUserLoggedIn) {
        navigateToNewStateHash(
          navigate,
          { highlightedTab: "trips" },
          "#trips",
          {
            ...location,
          }
        );
      }
    },
    [
      showMyTripsModal,
      setShowMyTripsModal,
      isTripsScreen,
      isUserLoggedIn,
      navigate,
      location,
    ]
  );

  function closeMyTripsModal() {
    setShowMyTripsModal(false);

    if (isUserLoggedIn && !seenLoginBanner) {
      Cookies.set(HAS_SEEN_MY_TRIPS_LOGIN_BANNER, "true", {
        expires: 365, // Set cookie to expire in a year.
      });
    }
  }

  function handleNewTrip() {
    setShowMultiSearchModal(true);
    sendAnalyticsInteractionEvent("TripPlanner", "Click:NewTrip:MultipleTrips");
    setShowMyTripsModal(false);
  }

  // Once logged in, show the trips modal and hide the signin modal.
  useEffect(() => {
    if (!startedLoggedIn.current && !loadingSession && user?.id) {
      setShowMyTripsModal(true);
    }
    // We only want this effect to fire after login.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSession, user?.id]);

  return (
    <>
      <StyledClickAwayListener onClickAway={closeMyTripsModal}>
        <TopNavWrapper>
          <NavButton $small $isActive={showMyTripsModal || isTripsScreen}>
            <Link id="my-trips" href="#trips" onClick={handleMyTripsClick}>
              <span> {intl.formatMessage(messages.myTrips)}</span>
              {!!data?.length && <Counter>{data.length}</Counter>}
            </Link>
          </NavButton>
        </TopNavWrapper>
        {showMyTripsModal && (
          <StyledDropdown
            role="dialog"
            data-testid="my-trips-modal"
            anchor="left"
            aria-labelledby="my-trips"
          >
            <MyTrips
              onClose={closeMyTripsModal}
              onNewTrip={handleNewTrip}
              numVisibleTrips={numVisibleTrips}
              setNumVisibleTrips={setNumVisibleTrips}
            />
          </StyledDropdown>
        )}
      </StyledClickAwayListener>

      {showMultiSearchModal && (
        <MultiSearchModal setShowMultiSearchModal={setShowMultiSearchModal} />
      )}
    </>
  );
}

const StyledClickAwayListener = styled(ClickAwayListener)`
  display: flex;
  position: relative;
`;

const StyledDropdown = styled(Dropdown)`
  width: 352px;
  top: calc(100% + ${spacing.md});
  display: block;
  border-radius: ${borderRadius.lg};
`;
const TopNavWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const Link = styled.a`
  color: ${(props) => props.theme.topNav.text};
  text-decoration: none !important; // Removed underline and added it to child span
  font-weight: ${fontWeight.medium};
  padding: ${spacing.lg} ${spacing.md};
  border-radius: ${borderRadius.lg};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  span:first-of-type {
    text-decoration: underline;
  }

  &:hover {
    color: ${(props) => props.theme.topNav.textHover};
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const Counter = styled.div`
  background-color: ${color.pink};
  border-radius: 100%;
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.bold};
  color: white;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  margin-left: ${spacing.sm};
`;
