import { SearchResponse } from "src/api/SearchResponse";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { SearchCard } from "../SearchCard/SearchCard";
import { SearchResultCard } from "../SearchResultCard/SearchResultCard";
import {
  RouteTripCard,
  RouteTripCardProps,
} from "./RouteTripCard/RouteTripCard";
import {
  ScheduleOrSegmentTripCard,
  ScheduleOrSegmentTripCardProps,
} from "./ScheduleTripCard/ScheduleTripCard";
import { SearchResultListCard } from "./SearchResultListCard/SearchResultListCard";
import { SearchTripCard } from "./SearchTripCard/SearchTripCard";
import { TripCardTypeProps } from "./TripCard";
import SimpleRouteCard from "./SimpleRouteCard/SimpleRouteCard";

type AnyTripCardProps =
  | (TripCardTypeProps & {
      index: number;
      isExpanded?: boolean;
      toggle?: () => void;
      isDragging?: boolean;
      isPendingDragChanges?: boolean;
    })
  | RouteTripCardProps
  | ScheduleOrSegmentTripCardProps;

export function AnyTripCard(cardProps: AnyTripCardProps) {
  const isInlineSearchResults = useIsTripsAsCoreFullExperience();
  const { isMultiTrip } = useTripPlannerContext();

  if (isMultiTrip) {
    return (
      <SimpleRouteCard {...cardProps} onboarding={cardProps.index === 0} />
    );
  }

  switch (cardProps.type) {
    case "search":
    case "searchPrompt": {
      if (isInlineSearchResults && cardProps.searchResponse) {
        return (
          <SearchResultListCard
            {...cardProps}
            // We're forcing a full (live) search response for this experiment.
            // All other cards use a static PartialSearchResponse.
            // TODO: Remove this cast _if_ the experiment is graduated, and update the `AnyTripCardProps` type to require a full search response for search cards.
            searchResponse={cardProps.searchResponse as SearchResponse}
          />
        );
      } else if (!cardProps.searchResponse) {
        return <SearchCard {...cardProps} />;
      } else {
        return <SearchTripCard {...cardProps} />;
      }
    }

    case "route": {
      if (isInlineSearchResults) {
        return <SearchResultCard {...cardProps} />;
      } else {
        return <RouteTripCard {...cardProps} />;
      }
    }

    case "segment":
    case "schedule": {
      if (isInlineSearchResults) {
        return <SearchResultCard {...cardProps} />;
      } else {
        return <ScheduleOrSegmentTripCard {...cardProps} />;
      }
    }
    default:
      console.error(`Incorrect card type ${cardProps.type} in local storage`);
      return null;
  }
}
