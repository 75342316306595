// expects string as 24 hour time. ex: '02:23', '23:45'
export const twentyFourToTwelveHour = (twentyFourHourTime: string) => {
  const [hour, minutes] = twentyFourHourTime.split(":");
  const hourNum = parseInt(hour);
  const time = `${hourNum % 12 || 12}:${minutes}`;

  return {
    time,
    isAm: hourNum < 12 || hourNum === 24,
  };
};

export const FIFTEEN_MINUTES_IN_MILLISECONDS = 1000 * 60 * 15;

export const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

export const TWO_MINUTES_IN_MILLISECONDS = 2 * 60 * 1000;
