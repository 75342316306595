import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import styled from "styled-components";
import { useFeature } from "src/feature/useFeature";
import { useSwitchURLToTripId } from "src/domain/TripPlanner/hooks/useSwitchURLToTripId";
import { useApiConfig } from "../../../api/ApiConfigProvider";
import { Button } from "../../../components/Button/Button";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import {
  largeDesktopLayout,
  tabletLayout,
  useLayout,
} from "../../../utils/hooks/useLayout";
import useUser from "../../../utils/hooks/useUser";
import { logout } from "../../utils/session";
import { UserProfile, getUserEmail } from "../../utils/user";
import { ManageProfileDialog } from "../ManageProfile/ManageProfileDialog/ManageProfileDialog";
import { ManageProfileButton } from "../ManageProfile/ManageProfileScreen/ManageProfileScreen";
import { messages } from "./UserDetails.messages";

type UserProfileProps = {
  user: UserProfile;
};
export function UserDetails({ user }: UserProfileProps) {
  const { resetUser } = useUser();
  const isMobile = useLayout() === "mobile";
  const intl = useIntl();
  const config = useApiConfig();
  const isTripURLFeature = useFeature("TripURL");
  const { clearTripId } = useSwitchURLToTripId();
  function handleSignOutClick() {
    sendAnalyticsInteractionEvent("UserAccount", "Click:SignOut");
    logout(
      {
        apiConfig: config,
      },
      resetUser
    );
    if (isTripURLFeature) {
      clearTripId();
      window.location.reload();
    }
  }

  const mainEmail = getUserEmail(user);

  return (
    <UserDetailsContainer>
      <PictureContainer>
        <DefaultPicture name={user.name} />
      </PictureContainer>
      <Heading>{user.name}</Heading>
      <Email>{mainEmail}</Email>
      <ManageProfileContainer>
        {isMobile ? (
          <ManageProfileButton />
        ) : (
          <ManageProfileDialog user={user} />
        )}
      </ManageProfileContainer>
      <ButtonContainer>
        <SignOutButton
          backgroundColor="transparent"
          textColor="primaryOnLight"
          size="extra-small"
          inline
          onClick={handleSignOutClick}
          target="_blank"
          rel="noopener"
        >
          <SignOutText>{intl.formatMessage(messages.signOut)}</SignOutText>
        </SignOutButton>
      </ButtonContainer>
    </UserDetailsContainer>
  );
}

function DefaultPicture(props: { name: string }) {
  // Make the first letter of name
  const firstLetter = props.name?.trim().charAt(0).toUpperCase() ?? "";
  // make a component with the first letter that includes the background color and the letter in the middle
  return <DefaultPictureBackground>{firstLetter}</DefaultPictureBackground>;
}

const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${largeDesktopLayout} {
    align-items: flex-start;
  }
`;

export const PictureContainer = styled.div`
  overflow: hidden;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin-bottom: ${spacing.xxl};
  ${largeDesktopLayout} {
    height: 56px;
    width: 56px;
  }
`;

const DefaultPictureBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color.cod};
  // Alignment
  display: flex;
  align-items: center;
  justify-content: center;
  // Text
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h2};
  line-height: ${lineHeight.snug};
  color: ${color.white};
`;

const Heading = styled.h4`
  font-size: ${fontSize.h2};
  font-weight: ${fontWeight.semibold};
  color: ${color.cod};
  line-height: ${lineHeight.snug};
  min-height: ${spacing.xxl};
  padding-bottom: ${spacing.md};
  // Limit Character Length
  max-width: 234px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${largeDesktopLayout} {
    font-size: ${fontSize.h4};
    margin-bottom: ${spacing.sm};
  }
`;

const Email = styled.p`
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.normal};
  color: ${color.cod};
  line-height: ${lineHeight.tight};
  min-height: ${spacing.xl};
  padding-bottom: ${spacing.xxl};
  // Limit Character Length
  max-width: 234px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${largeDesktopLayout} {
    font-size: ${fontSize.body};
    font-weight: ${fontWeight.light};
  }
`;

const ManageProfileContainer = styled.div`
  height: 18px;
  margin-bottom: ${spacing.xxl};
  width: 100%;
  text-align: center;
  ${largeDesktopLayout} {
    margin-bottom: ${spacing.xl};
    height: 46px;
    border-bottom: 1px solid ${color.n40};
    text-align: start;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
`;

const SignOutText = styled.span`
  color: ${color.cod};
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
`;
const SignOutButton = styled(Button)`
  ${tabletLayout} {
    background-color: ${color.white};
    display: flex;
    padding: ${spacing.md} ${spacing.xl};
    width: 100%;
    height: 48px;
    border: 1px solid ${color.n40};
    border-radius: ${borderRadius.sm};
    * span {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
