export const font_size = {
  text_xs: "0.75rem" /* 12px */,
  text_sm: "0.875rem" /* 14px */,
  text_base: "1rem" /* 16px */,
  text_lg: "1.125rem" /* 18px */,
  text_xl: "1.25rem" /* 20px */,
  text_2xl: "1.375rem" /* 22px */,
} as const;
/**
 * font-size: ${font_size.text_base};
 */

export const font_line_height = {
  leading_none: "1rem" /* 16px */,
  leading_tight: "1.25rem" /* 20px */,
  leading_normal: "1.5rem" /* 24px */,
  leading_relaxed: "1.75rem" /* 28px */,
  leading_loose: "2rem" /* 32px */,
} as const;
/**
 * line-height: ${font_line_height.leading_none};
 */

export const font_weight = {
  normal: "400",
  medium: "500",
  bold: "700",
} as const;
/**
 * font-weight: ${font_weight.normal};
 */
