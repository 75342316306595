import { HotelCardLoading } from "./TripHotelCard/TripHotelCard";
import { CarouselContainer } from "./HotelCarouselResults/HotelCarouselResults";

export function CarouselLoadingView() {
  return (
    <CarouselContainer>
      <HotelCardLoading />
      <HotelCardLoading />
      <HotelCardLoading />
    </CarouselContainer>
  );
}
