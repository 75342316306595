import { Draggable } from "@hello-pangea/dnd";
import { useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { ChevronDown } from "src/svg/ChevronDown";
import { ChevronUp } from "src/svg/ChevronUp";
import { Minus } from "src/svg/tripplanner/Minus";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "src/theme";
import { MergeElementProps } from "src/utils/MergeElementProps";
import { desktopLayout } from "src/utils/hooks/useLayout";
import styled, { css, keyframes } from "styled-components";
import { DragHandle } from "src/svg/tripplanner/DragHandle";
import { ButtonBase } from "../Button/ButtonBase";
import { Icon } from "../Icon/Icon";
import { messages } from "./DragAndDropList.messages";

const DURATION_ANIMATION = 275;
const DELETE_DURATION_ANIMATION = 375;

type MovingProps = "up" | "down";

export type SortableObject<T> = { id: string; content: string } & T;

type ItemProps<T> = MergeElementProps<
  "li",
  {
    item: SortableObject<T>;
    index: number;
    totalItems: number;
    handleMoveUp: () => void;
    handleMoveDown: () => void;
    handleRemove: () => void;
    setMovingUp: (index?: number) => void;
    setMovingDown: (index?: number) => void;
    accessibleMode?: boolean;
    moving?: MovingProps;
  }
>;

export function DraggableItem<T>({
  item,
  index,
  accessibleMode,
  totalItems,
  handleMoveUp,
  handleMoveDown,
  handleRemove,
  setMovingUp,
  setMovingDown,
  moving,
  ...props
}: ItemProps<T>) {
  const intl = useIntl();
  // isDeleting state is used to activate the button confirmation
  const [isDeleting, setIsDeleting] = useState(false);
  // deleteAnimation state is used to trigger the animation
  const [deleteAnimation, setDeleteAnimation] = useState(false);
  const confirmFocusRef = useRef<HTMLButtonElement>(null);
  const isMovingUpDisabled = index <= 0;
  const isMovingDownDisabled = index >= totalItems - 1;

  function onClickRemoveButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setDeleteAnimation(true);
    setTimeout(() => {
      handleRemove();
    }, DELETE_DURATION_ANIMATION);
  }

  function onClickMovingUp() {
    setMovingUp(index);
    setMovingDown(index - 1);
    setTimeout(() => {
      handleMoveUp();
      setMovingUp(undefined);
      setMovingDown(undefined);
    }, DURATION_ANIMATION);
  }

  function onClickMovingDown() {
    setMovingUp(index + 1);
    setMovingDown(index);
    setTimeout(() => {
      handleMoveDown();
      setMovingUp(undefined);
      setMovingDown(undefined);
    }, DURATION_ANIMATION);
  }

  useLayoutEffect(() => {
    if (isDeleting && confirmFocusRef.current) {
      confirmFocusRef.current.focus();
    }
  }, [isDeleting]);

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...props}
          $delete={deleteAnimation}
          $moving={moving}
          $index={index}
          $isDraggingOver={snapshot.isDragging}
          data-index={index}
          onClick={() => setIsDeleting(false)}
          aria-label={item.content}
          aria-describedby={`text-${item.id}`}
        >
          <Container>
            <DragAndDropContainer
              {...provided.dragHandleProps}
              aria-label={intl.formatMessage(messages.dragElement, {
                element: item.content,
              })}
              data-rbd-drag-handle-draggable-id={item.id}
            >
              <Title id={`text-${item.id}`}>{item.content}</Title>
              {!accessibleMode && (
                <Controlls>
                  <Icon size="xxl">
                    <DragHandle tint="n60" />
                  </Icon>
                </Controlls>
              )}
            </DragAndDropContainer>
            {accessibleMode && (
              <Controlls>
                <ControllsButton
                  onClick={onClickMovingUp}
                  disabled={isMovingUpDisabled}
                  data-testid={`reorder-${index + 1}-${index}`}
                  aria-disabled={isMovingUpDisabled}
                  aria-label={intl.formatMessage(messages.moveElement, {
                    element: item.content,
                    from: index + 1,
                    to: index,
                  })}
                >
                  <Icon size="md">
                    <ChevronUp tint={isMovingUpDisabled ? "n40" : "n60"} />
                  </Icon>
                </ControllsButton>
                <ControllsButton
                  onClick={onClickMovingDown}
                  disabled={isMovingDownDisabled}
                  data-testid={`reorder-${index + 1}-${index + 2}`}
                  aria-disabled={isMovingDownDisabled}
                  aria-label={intl.formatMessage(messages.moveElement, {
                    element: item.content,
                    from: index + 1,
                    to: index + 2,
                  })}
                >
                  <Icon size="md">
                    <ChevronDown tint={isMovingDownDisabled ? "n40" : "n60"} />
                  </Icon>
                </ControllsButton>
              </Controlls>
            )}
            <DeleteButton
              title={intl.formatMessage(messages.delete, {
                element: item.content,
              })}
              aria-label={intl.formatMessage(messages.delete, {
                element: item.content,
              })}
              onClick={(event) => {
                event.stopPropagation();
                setIsDeleting(!isDeleting);
              }}
            >
              <Icon size="xxl">
                <Minus tint="high" />
              </Icon>
            </DeleteButton>
          </Container>
          <ConfirmDeleteButton
            ref={confirmFocusRef}
            $isDelete={isDeleting}
            onClick={onClickRemoveButton}
            disabled={!isDeleting}
            aria-hidden={!isDeleting}
            title={intl.formatMessage(messages.confirmDelete)}
            aria-label={intl.formatMessage(messages.confirmDeleteMessage, {
              element: item.content,
            })}
          >
            {intl.formatMessage(messages.delete, {
              element: "",
            })}
          </ConfirmDeleteButton>
        </Card>
      )}
    </Draggable>
  );
}

const RemoveAnimation = keyframes`
  0% {
    transform: scaleY(1);
    opacity: 1;
    margin-bottom: 0;
  }
  100% {
    transform: scaleY(0);
    opacity: 0;
    margin-bottom: -48px;
  }
`;

const MoveAnimation = ($moving?: MovingProps) => keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(${
      $moving === "up" ? "calc(-100% - 2px)" : "calc(100% + 2px)"
    });
  }
`;

const Card = styled.li<{
  $delete: boolean;
  $moving?: MovingProps;
  $index: number;
  $isDraggingOver?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  background: ${color.white};
  border: 1px solid
    ${({ $isDraggingOver }) => ($isDraggingOver ? color.n50 : color.n30)};
  border-radius: ${borderRadius.md};

  margin-bottom: ${spacing.xs};

  overflow: hidden;
  transform-origin: top;

  ${({ $delete, $moving }) => {
    if ($delete) {
      return css`
        animation: ${RemoveAnimation} ${DELETE_DURATION_ANIMATION}ms ease-in-out
          both;
      `;
    }
    if ($moving) {
      return css`
        animation: ${MoveAnimation($moving)} ${DURATION_ANIMATION}ms ease-in-out
          both;
      `;
    }
    return css`
      animation: "none";
    `;
  }};

  ${desktopLayout} {
    margin-bottom: ${spacing.md};
  }
`;

const Container = styled.div`
  padding: ${spacing.sm} ${spacing.md};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
  gap: 31px;
  min-height: 48px;
`;

const DeleteButton = styled(ButtonBase)`
  flex-grow: 0;
  order: 1;
  margin-left: ${spacing.lg};

  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
  }
`;

const ConfirmDeleteButton = styled(ButtonBase)<{ $isDelete: boolean }>`
  flex-grow: 0;
  background-color: ${color.high};
  overflow: hidden;

  padding-left: ${({ $isDelete }) => ($isDelete ? spacing.xl : "0px")};
  padding-right: ${({ $isDelete }) => ($isDelete ? spacing.xl : "0px")};

  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h6};
  color: ${color.white};

  width: ${({ $isDelete }) => ($isDelete ? "auto" : "0px")};
  transition: width ${DURATION_ANIMATION}ms ease-in-out,
    padding-left ${DURATION_ANIMATION}ms ease-in-out,
    padding-right ${DURATION_ANIMATION}ms ease-in-out;
  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
  }
`;

const Controlls = styled.div`
  flex-grow: 0;
  order: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  & > *:last-child {
    margin-right: ${spacing.md};
  }
`;

const Title = styled.p`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  color: ${color.n300};
`;

const DragAndDropContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  // Increase the drag area
  width: calc(24px * 1.5);
  flex-grow: 2;
  order: 2;
  display: flex;
  align-items: center;

  ${desktopLayout} {
    font-size: ${fontSize.h4};
  }
`;

const ControllsButton = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 100%;
  padding-right: ${spacing.md};
  padding-left: ${spacing.md};
  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
  }
`;
