import styled, { css } from "styled-components";
import { IntlShape, useIntl } from "react-intl";
import React, { ComponentProps, MouseEvent, PropsWithChildren } from "react";
import { useHref } from "react-router";
import { useLinkClickHandler } from "react-router-dom";
import useSearch from "src/utils/hooks/useSearch";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import { DistanceSystem } from "src/utils/distanceSystem";
import { primitive } from "src/design-system/tokens/color";
import titleMessages from "src/components/Timeline/ScheduleTimeline/TroniconTimelineDetail.messages";
import { SupportedCurrencyCode } from "src/utils/currency";
import { useFeature } from "src/feature/useFeature";
import { modeMessages } from "src/domain/SegmentScreen/DynamicSchedules/Schedule/Schedule.messages";
import { color, fontSize, fontWeight, spacing } from "../../../../theme";
import { DetailCell } from "../cell/DetailCell";
import { Icon } from "../../../Icon/Icon";
import useUser from "../../../../utils/hooks/useUser";
import {
  FormattedDuration,
  getFormattedDurationText,
} from "../../../FormattedDuration/FormattedDuration";
import { formatFrequency } from "../../../Frequency/Frequency";
import { formatDistance } from "../../../FormattedDistance/FormattedDistance";
import { Kind } from "../../../../api/Kind";
import { LineName } from "../../../LineName/LineName";
import {
  formatPriceRange,
  FormattedPriceRange,
} from "../../../FormattedPriceRange/FormattedPriceRange";
import { Timeline } from "../../Timeline";
import { useTypedLocation } from "../../../../utils/hooks/useTypedLocation";
import { useRouteCanonical } from "../../../../utils/hooks/useRoute";
import { hashChange } from "../../../../utils/location/hashChange";
import { ChevronRight } from "../../../../svg/ChevronRight";
import messages from "./TroniconTimelineDetail.messages";

type Props = {
  context: "transport" | "tripPlanner";
  cell: DetailCell;
  onClick?: (segmentIndex: number) => void;
  onHover: (segmentIndex?: number) => void;
  className?: string;
  isCompact?: boolean;
  timeline?: React.ReactNode;
  origin?: string;
  destination?: string;
};

export function TroniconTimelineDetail(props: Props) {
  const hasCta = props.onClick !== undefined;
  const intl = useIntl();
  const { distanceSystem, currencyCode } = useUser();
  const isNewRoutePane = useFeature("NewRoutePane");

  const ariaLabel = getAriaLabelForDetailCell(
    props.cell,
    intl,
    distanceSystem,
    currencyCode,
    props.origin,
    props.destination
  );

  return (
    <Container
      context={props.context}
      onClick={props.onClick}
      className={props.className}
      onMouseEnter={() => props.onHover(props.cell.segmentIndex)}
      onMouseLeave={() => props.onHover(undefined)}
      segmentIndex={props.cell.segmentIndex}
      hasCta={hasCta}
      aria-label={isNewRoutePane ? ariaLabel : null}
      isNewRoutePane={isNewRoutePane}
    >
      {props.timeline ?? (
        <FullWidthTimeline type="detail" transitMode={props.cell.transitMode} />
      )}
      <LeftAligned $isCompact={props.isCompact}>
        <TopDetailLine cell={props.cell} />
        <LineNameRow cell={props.cell} />
        {props.cell.priceRange && (
          <PriceRangeDetailText {...props.cell.priceRange} />
        )}
      </LeftAligned>
      {hasCta && (
        <RightAligned size="md" isNewRoutePane={isNewRoutePane}>
          <ChevronRight tint="pink" />
        </RightAligned>
      )}
    </Container>
  );
}

function getAriaLabelForDetailCell(
  cell: DetailCell,
  intl: IntlShape,
  distanceSystem: DistanceSystem,
  currencyCode: SupportedCurrencyCode,
  origin?: string,
  destination?: string
) {
  const duration = getFormattedDurationText(cell.durationInMinutes, intl);
  const { additionalInfo } = getTimelineDetailTop(cell, intl, distanceSystem);
  const priceText = cell.priceRange
    ? formatPriceRange(
        intl,
        cell.priceRange.low,
        cell.priceRange.high,
        currencyCode
      )
    : "";

  const fromToText = intl.formatMessage(messages.fromTo, {
    origin,
    destination,
  });
  return `${fromToText} ${duration} ${additionalInfo} ${priceText}`;
}

type ContainerProps = PropsWithChildren<{
  context: "transport" | "tripPlanner";
  onClick?: (segmentIndex: number) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  segmentIndex: number;
  hasCta: boolean;
  title?: string;
  className?: string;
  isNewRoutePane?: boolean;
}>;

function Container({
  context,
  onClick,
  segmentIndex,
  ...props
}: ContainerProps) {
  const routeCanonical = useRouteCanonical();
  const { searchResponse } = useSearch();
  const tripHash = tripHashFromSearchResponse(searchResponse);
  const hash =
    context === "tripPlanner"
      ? `${tripHash}/r/${routeCanonical}/s/${segmentIndex}`
      : `#r/${routeCanonical}/s/${segmentIndex}`;

  const location = useTypedLocation();
  const { state, ...to } = hashChange(hash, location);

  /*
  We can't use react-router's <Link> component because it doesn't give us the standard
  behaviour of an anchor tag that we want eg. ctrl+click opens the link in a new tab,
  but also navigates to the link in the current tab. Instead we need to use an anchor
  tag with the helper hook useLinkClickHandler.
  */
  const href = useHref(to);
  const linkClickHandler = useLinkClickHandler(to, { state });

  // Handler for react-router navigation on an anchor tag.
  function handleLinkClick(event: MouseEvent<HTMLAnchorElement>) {
    handleClick();
    linkClickHandler(event);
  }

  // Original handler.
  function handleClick() {
    onClick && onClick(segmentIndex);
  }

  if (props.hasCta) {
    return (
      <ContainerLink
        onClick={handleLinkClick}
        href={href}
        {...props}
        title={props.title}
      />
    );
  } else {
    return <ContainerDiv {...props} />;
  }
}

const FullWidthTimeline = styled(Timeline)<ComponentProps<typeof Timeline>>`
  flex-shrink: 0;
`;

function getTimelineDetailTopVariant(
  cell: DetailCell,
  intl: IntlShape,
  distanceSystem: DistanceSystem
) {
  const frequency = cell.frequencyPerWeek
    ? formatFrequency(cell.frequencyPerWeek, intl)
    : undefined;
  const distance =
    cell.distanceInMeters && showDistance(cell.vehicleKind)
      ? formatDistance(cell.distanceInMeters, distanceSystem, intl)
      : undefined;

  // Walks and Flights shouldn’t have "On demand" as frequency
  const isPlane = cell.vehicleKind === "plane";
  const isWalk = cell.vehicleKind === "walk";
  const isOnDemand = cell.frequencyPerWeek && cell.frequencyPerWeek <= 0;
  const hideFrequency = isOnDemand && (isWalk || isPlane);
  const byTransitMode = intl.formatMessage(modeMessages[cell.transitMode]);

  let additionalInfo = [byTransitMode];
  !hideFrequency && frequency && additionalInfo.push(frequency);
  distance && additionalInfo.push(distance);

  return { additionalInfo };
}

export function getTimelineDetailTop(
  cell: DetailCell,
  intl: IntlShape,
  distanceSystem: DistanceSystem
) {
  const frequency = cell.frequencyPerWeek
    ? formatFrequency(cell.frequencyPerWeek, intl)
    : undefined;
  const distance =
    cell.distanceInMeters && showDistance(cell.vehicleKind)
      ? formatDistance(cell.distanceInMeters, distanceSystem, intl)
      : undefined;

  // Walks and Flights shouldn’t have "On demand" as frequency
  const isPlane = cell.vehicleKind === "plane";
  const isWalk = cell.vehicleKind === "walk";
  const isOnDemand = cell.frequencyPerWeek && cell.frequencyPerWeek <= 0;
  const hideFrequency = isOnDemand && (isWalk || isPlane);
  const byKind = getKindString(cell.vehicleKind, intl);

  const additionalInfo = [
    " ",
    byKind,
    hideFrequency ? undefined : frequency,
    distance ? `(${distance})` : undefined,
  ].join(" ");
  return { additionalInfo };
}

function TopDetailLine({ cell }: { cell: DetailCell }) {
  const intl = useIntl();
  const { distanceSystem } = useUser();
  const isNewRoutePane = useFeature("NewRoutePane");

  if (isNewRoutePane) {
    const { additionalInfo } = getTimelineDetailTopVariant(
      cell,
      intl,
      distanceSystem
    );
    return (
      <DetailTextVariant>
        <FormattedDuration totalMinutes={cell.durationInMinutes} />
        {additionalInfo.map((text, i) => {
          return (
            <div style={{ display: "contents" }} key={i}>
              <DotDiv />
              {text}
            </div>
          );
        })}
      </DetailTextVariant>
    );
  }
  const { additionalInfo } = getTimelineDetailTop(cell, intl, distanceSystem);

  return (
    <DetailText>
      <BoldText>
        <FormattedDuration totalMinutes={cell.durationInMinutes} />
      </BoldText>
      {additionalInfo}
    </DetailText>
  );
}

function LineNameRow({ cell }: { cell: DetailCell }) {
  const intl = useIntl();
  const isNewRoutePane = useFeature("NewRoutePane");
  const serviceLabel = getServiceNameLabelForCell(intl, cell);

  if (cell.lineNames.length) {
    return (
      <LineNameContainer>
        {cell.lineNames.map((line) => {
          return (
            <FullWidthLineName
              key={line.name}
              {...line}
              title={isNewRoutePane ? serviceLabel : undefined}
            />
          );
        })}
      </LineNameContainer>
    );
  } else {
    return null;
  }
}

function getServiceNameLabelForCell(intl: IntlShape, cell: DetailCell) {
  if (cell.transitMode === "plane") {
    return intl.formatMessage(titleMessages.flightNumber);
  } else {
    return intl.formatMessage(titleMessages.line, {
      transitMode: cell.transitMode,
    });
  }
}

export function getKindString(transitMode: Kind, intl: IntlShape): string {
  switch (transitMode) {
    case "animal":
      return intl.formatMessage(messages.animal);
    case "bus":
      return intl.formatMessage(messages.bus);
    case "cycle":
      return intl.formatMessage(messages.bicycle);
    case "busferry":
      return intl.formatMessage(messages.busferry);
    case "cablecar":
      return intl.formatMessage(messages.cablecar);
    case "car":
      return intl.formatMessage(messages.car);
    case "carferry":
      return intl.formatMessage(messages.carferry);
    case "cartrain":
      return intl.formatMessage(messages.cartrain);
    case "ferry":
      return intl.formatMessage(messages.ferry);
    case "plane":
      return intl.formatMessage(messages.flight);
    case "walk":
      return intl.formatMessage(messages.walk);
    case "helicopter":
      return intl.formatMessage(messages.helicopter);
    case "nightbus":
      return intl.formatMessage(messages.nightbus);
    case "nighttrain":
      return intl.formatMessage(messages.nighttrain);
    case "rideshare":
      return intl.formatMessage(messages.rideshare);
    case "shuttle":
      return intl.formatMessage(messages.shuttle);
    case "subway":
      return intl.formatMessage(messages.subway);
    case "taxi":
      return intl.formatMessage(messages.taxi);
    case "towncar":
      return intl.formatMessage(messages.towncar);
    case "train":
      return intl.formatMessage(messages.train);
    case "tram":
      return intl.formatMessage(messages.tram);
    case "none":
    case "transfer":
    case "unknown":
    case "hotelcity":
      return "";
  }
}

function showDistance(vehicleKind: Kind): boolean {
  const showDistance = [
    "walk",
    "foot",
    "car",
    "taxi",
    "rideshare",
    "towncar",
    "animal",
    "bicycle",
    "cycle",
  ];

  return showDistance.includes(vehicleKind);
}

const DetailText = styled.span`
  font-size: ${fontSize.h6};
  line-height: 24px;
`;

const DetailTextVariant = styled(DetailText)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DotDiv = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #707070;
`;

const PriceRangeDetailText = styled(FormattedPriceRange)`
  font-size: ${fontSize.h6};
  line-height: 24px;
`;

const BoldText = styled.span`
  font-weight: ${fontWeight.medium};
`;

const RightAligned = styled(Icon)<{ isNewRoutePane?: boolean }>`
  align-self: center;
  margin-right: ${({ isNewRoutePane }) => (isNewRoutePane ? null : `16px`)};
`;

const LeftAligned = styled.div<{ $isCompact?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  padding-top: ${(props) => (props.$isCompact ? spacing.lg : spacing.xxl)};
  padding-bottom: ${(props) => (props.$isCompact ? spacing.lg : spacing.xxl)};
  padding-left: 16px;
  z-index: 1;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const containerStyles = css<{ hasCta: boolean; isNewRoutePane?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-left: 16px;
  cursor: ${(props) => (props.hasCta ? "pointer" : "default")};
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  ${({ isNewRoutePane }) =>
    isNewRoutePane
      ? css`
          border-radius: ${spacing.md};
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }

          &:focus {
            outline: none;
            &::before {
              outline: 3px solid ${primitive.blue_500};
              border-radius: ${spacing.md};
            }
          }
        `
      : css`
          &:active {
            background-color: ${color.grey1};
          }
          &:hover {
            background-color: ${color.grey2};
            // Reset the background-color on touch devices so that they don't get a
            // lingering hover effect after a click event.
            @media (hover: none) {
              background-color: transparent;
            }
          }
        `}
`;

const ContainerDiv = styled.div`
  ${containerStyles}
`;

const ContainerLink = styled.a`
  color: inherit;
  text-decoration: none;
  ${containerStyles}
`;

const LineNameContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  & > * {
    margin-right: ${spacing.sm};
  }
`;

const FullWidthLineName = styled(LineName)`
  line-height: 16px;
  vertical-align: top;
  max-width: 100%;
`;
