import { defineMessages } from "react-intl";

const messages = defineMessages({
  travelOptions: {
    id: "searchresult.travelOptions",
    description: "Title for the search results screen",
    defaultMessage: "Travel options",
  },
  saveOnboarding: {
    id: "searchresult.saveOnboarding",
    description: "Save onboarding message",
    defaultMessage:
      "Prefer a different option? Use {svg} to select an alternative travel option.",
  },
  onboardingDismiss: {
    id: "searchresult.onboardingDismiss",
    description: "Onboarding dismiss button",
    defaultMessage: "Got it",
  },
});

export default messages;
