import { PassengerDetails } from "src/PassengerDetailsProvider";
import {
  SOJERN_CONSENT_PURPOSES_REQUIRED,
  SOJERN_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
} from "src/data-partners/sojern/sojernPixel";
import { InterestData, SearchResponse } from "src/api/SearchResponse";
import { SchedulesResponse } from "src/api/SchedulesResponse";
import useSojernPixel from "src/data-partners/sojern/useSojernPixel";
import { useTravelAudience } from "src/data-partners/travelAudience/useTravelAudience";
import { useRegisterOnConsent } from "src/utils/hooks/useRegisterOnConsent";
import { Mode } from "src/utils/types/mode";
import useAdForm from "./adForm/useAdForm";
import useCrimtan from "./crimtan/useCrimtan";
import { useAdara } from "./adara/useAdara";

type DataPartner = "AdForm" | "Crimtan" | "Sojern" | "TravelAudience";
export type DataPartners = DataPartner[];

const useDataPartners = (
  searchResponse?: SearchResponse,
  interestData?: InterestData,
  uidCookie?: string,
  schedulesResponse?: SchedulesResponse,
  passengers?: PassengerDetails,
  transitMode?: Mode,
  routeIndex?: number
) => {
  const enabledPartners: DataPartners = Object.keys(interestData ?? {}).filter(
    (key) => interestData?.[key].enabled
  ) as DataPartners;

  const hasSojernConsent = useRegisterOnConsent(
    SOJERN_CONSENT_PURPOSES_REQUIRED,
    SOJERN_LEGITIMATE_INTEREST_PURPOSES_REQUIRED
  );

  useAdForm(
    enabledPartners.includes("AdForm"),
    searchResponse?.analytics?.custom_dimensions
  );

  useCrimtan({
    searchResponse,
    isCrimtanEnabled: enabledPartners.includes("Crimtan"),
    schedulesResponse,
    passengers,
    transitMode,
  });

  useAdara(
    searchResponse,
    schedulesResponse,
    passengers,
    transitMode,
    routeIndex
  );

  useSojernPixel(
    searchResponse?.request,
    enabledPartners.includes("Sojern") && hasSojernConsent,
    uidCookie,
    schedulesResponse?.request,
    passengers,
    transitMode
  );

  useTravelAudience(
    searchResponse,
    enabledPartners.includes("TravelAudience"),
    schedulesResponse?.request,
    transitMode
  );
};

export default useDataPartners;
