import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { hotelPaneWidth, segmentPaneWidth } from "src/PaneContentWidths";
import { useIsTripScreen } from "./useIsTripScreen";
import { useTypedLocation } from "./useTypedLocation";
import { useLayout } from "./useLayout";
import { useLeftPaneWidth } from "./useLeftPaneWidth";
import { useScreenMinWidth } from "./useScreenMinWidth";

export const TRIP_PANE_GAP = 51;

// The stacked navigation pane overlaps the trip map which affects how elements are centered on the map
export default function useGetStackedMapOffset(props?: { centered?: boolean }) {
  const layout = useLayout();
  const { stackedNavigationMapOverlap } = useGetStackedNavigationMapOverlap();
  const isTripsScreen = useIsTripScreen();

  if (layout === "desktop" && isTripsScreen) {
    return stackedNavigationMapOverlap / (props?.centered ? 2 : 1);
  }
}

export function getStackedPaneWidth(
  screenKey?: "hotels" | "transport" | "destination"
) {
  if (screenKey === "hotels") {
    return hotelPaneWidth;
  }
  return segmentPaneWidth;
}

export function useStackPaneWidth(
  screenKey?: "hotels" | "transport" | "destination"
) {
  const { isMdScreen, isLgScreen, isXlScreen } = useScreenMinWidth();
  if (screenKey === "hotels") return hotelPaneWidth;
  if (isXlScreen) return 576;
  if (isLgScreen || isMdScreen) return 496;
  return 360;
}

export function useGetStackedNavigationMapOverlap() {
  const location = useTypedLocation();
  const screenKey = getScreenKey(location.hash);
  const leftPaneWidth = useLeftPaneWidth();
  return {
    stackedNavigationMapOverlap:
      getStackedPaneWidth(screenKey) - leftPaneWidth + TRIP_PANE_GAP,
  };
}
