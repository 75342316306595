import { defineMessages } from "react-intl";

const messages = defineMessages({
  savedTrips: {
    id: "myTripsModal.savedTrips",
    description: "Button / Title to show all the trips youve saved",
    defaultMessage: "Saved Trips",
  },
});

export default messages;
