import { GeocodedPlace } from "src/PrefetchData";
import { SortableObject } from "src/components/DragAndDropList/DraggableItem";

export function hasTripChanged(
  places: GeocodedPlace[],
  newOrder: SortableObject<GeocodedPlace>[]
) {
  if (places.length !== newOrder.length) {
    return true;
  }

  for (let i = 0; i < places.length; i++) {
    if (places[i].canonicalName !== newOrder[i].canonicalName) {
      return true;
    }
  }
  return false;
}
