import {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

export type FilterStops = "any" | "direct" | "onestop" | "twostops";

export type SortingOptions = "cheapest" | "fastest" | "soonest";
export type FilterOptions = {
  stops: FilterStops;
};

export const DEFAULT_SORT = "soonest";
export const DEFAULT_FLIGHT_SORT = "fastest";
export const DEFAULT_FILTER: FilterOptions = {
  stops: "any",
};

type TravelSearchSettingsProviderProps = {
  initialDate?: string;
  initialTime?: string;
  sort?: SortingOptions;
  filter?: FilterOptions;
};

export function TravelSearchSettingsProvider(
  props: PropsWithChildren<TravelSearchSettingsProviderProps>
) {
  const [dateTimeObject, setDateTimeObject] = useState({
    departureDate: props.initialDate ?? "",
    time: props.initialTime ?? "",
  });
  const [sort, setSort] = useState<SortingOptions | undefined>();
  const [filter, setFilter] = useState(props.filter ?? DEFAULT_FILTER);

  const providerValue = useMemo(
    () => ({
      dateTimeObject,
      sort,
      filter,
      setDateTimeObject,
      setSort,
      setFilter,
    }),
    [dateTimeObject, sort, filter]
  );

  return (
    <TravelSearchContext.Provider value={providerValue}>
      {props.children}
    </TravelSearchContext.Provider>
  );
}

export function useTravelSearchSettings() {
  const context = useContext(TravelSearchContext);
  if (!context) {
    throw new Error(
      `useTravelSearchSettings must be used within TravelSearchSettingsProvider`
    );
  }
  return context;
}

const TravelSearchContext = createContext<{
  dateTimeObject: {
    departureDate: string;
    returnDate?: string;
    time: string;
  };
  sort: SortingOptions | undefined;
  filter: FilterOptions;
  setDateTimeObject: Dispatch<
    SetStateAction<{
      departureDate: string;
      returnDate?: string;
      time: string;
    }>
  >;
  setSort: Dispatch<SetStateAction<SortingOptions | undefined>>;
  setFilter: Dispatch<SetStateAction<FilterOptions>>;
}>({
  dateTimeObject: {
    departureDate: "",
    time: "",
  },
  sort: DEFAULT_SORT,
  filter: DEFAULT_FILTER,
  setDateTimeObject: () => {},
  setSort: () => {},
  setFilter: () => {},
});
