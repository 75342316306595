import { UseQueryResult } from "react-query";
import { SearchResponse } from "src/api/SearchResponse";
import { LatLng, distanceBetween } from "src/utils/distanceBetween";
import { TripPlannerDetails } from "../TripPlannerProvider";

/**
 * This function adjusts the TripPlannerDetails to have meaningful origin coordinates when users enter with 'Nearby-Airports' or 'Hotels-Near'.
 * The purpose is to help with setting map bounds and positioning the place index on the map.
 */
export function adjustTripPlannerDetailsForNearby(
  tripRoutesQueries: UseQueryResult<SearchResponse, unknown>[],
  tripPlannerDetails: TripPlannerDetails
) {
  if (tripPlannerDetails.places.length) {
    const updatedTripPlannerDetails = { ...tripPlannerDetails };
    const oCanonicalName = tripPlannerDetails.places[0].canonicalName;
    if (
      (oCanonicalName === "Nearby-Airports" ||
        oCanonicalName === "Hotels-Near") &&
      tripRoutesQueries.length &&
      tripPlannerDetails.places.length > 1
    ) {
      const nearbyTargetPlace = tripPlannerDetails.places[1];
      const coordinate = getFurthestCoordinateOfNearbyRoutes(
        tripRoutesQueries[0].data,
        {
          ...nearbyTargetPlace,
        }
      );
      // Update first special nearby place with meaningful coordinates
      updatedTripPlannerDetails.places[0] = {
        ...updatedTripPlannerDetails.places[0],
        ...coordinate,
      };

      return updatedTripPlannerDetails;
    }
  }
  return tripPlannerDetails;
}

function getFurthestCoordinateOfNearbyRoutes(
  searchResponse: SearchResponse | undefined,
  coordinates: LatLng
) {
  if (!searchResponse) return null;
  let furthestDistance = 0;
  let furthestCoordinate: LatLng = { lat: 0, lng: 0 };
  searchResponse.routes.forEach((route) => {
    const index = route.places[1];
    const place = searchResponse.places[index];
    const result = distanceBetween({ ...place }, { ...coordinates });
    if (result > furthestDistance) {
      furthestCoordinate.lng = place.lng;
      furthestCoordinate.lat = place.lat;
    }
  });

  return furthestCoordinate;
}
