import { Button } from "src/components/Button/Button";
import styled from "styled-components";
import {
  borderRadius,
  color as themeColor,
  fontSize,
  fontWeight,
  iconSize as sharedIconSize,
  lineHeight,
  spacing,
} from "src/theme";
import { NewTabRounded } from "src/svg/NewTab";
import { Icon } from "src/components/Icon/Icon";
import { A11yOutline } from "src/utils/accessibility";
import {
  LabelMd,
  typographyVariants,
} from "src/design-system/components/Typography/Typography";
import color from "src/design-system/tokens/color";
import ToolTip from "src/svg/ToolTip.svg?react";

type ConditionalProps =
  | {
      tagline?: string;
      tooltipCta?: never;
      tooltipOnClick?: never;
    }
  | {
      tagline?: never;
      tooltipCta?: string;
      tooltipOnClick?: (event?: React.MouseEvent) => void;
    };

type PanePromoButtonProps = {
  promoText: string;
  children: JSX.Element;
  iconBgColor?: string;
  iconSize?: keyof typeof sharedIconSize;
  iconBgSize?: "lg" | "md";
  onClick: (event?: React.MouseEvent) => void;
} & ConditionalProps;

export function PanePromoButton({
  promoText,
  tagline,
  tooltipCta,
  tooltipOnClick,
  children,
  onClick,
  iconBgColor = themeColor.n20,
  iconBgSize,
  iconSize = "xxl",
}: PanePromoButtonProps) {
  return (
    <PromoButton
      textColor="primaryOnLight"
      backgroundColor="white"
      onClick={onClick}
      justify="flex-start"
      border={themeColor.n30}
      borderRadius="lg"
      padding={spacing.xl}
      fontWeight="normal"
    >
      <LeftIconWrapper
        $iconBgColor={iconBgColor}
        iconBgSize={iconBgSize === "lg" ? "56px" : spacing.xxxxl}
      >
        <Icon size={iconSize}>{children}</Icon>
      </LeftIconWrapper>
      <TextWrapper>
        <Header>{promoText}</Header>
        {tagline && <Tagline>{tagline}</Tagline>}
        {tooltipCta && (
          <TooltipWrapper
            onClick={(e: React.MouseEvent) => {
              if (tooltipOnClick) {
                e.stopPropagation();
                tooltipOnClick();
              }
            }}
          >
            <LabelMd
              className={typographyVariants({ decoration: "underline" })}
              color={color.text.primary}
            >
              {tooltipCta}
            </LabelMd>
            <TooltipIcon size="md">
              <ToolTip color={color.icon.secondary} />
            </TooltipIcon>
          </TooltipWrapper>
        )}
      </TextWrapper>
      <IconWrapper size="md">
        <NewTabRounded tint="pink" />
      </IconWrapper>
    </PromoButton>
  );
}

const PromoButton = styled(Button)`
  ${A11yOutline};
  background-color: ${themeColor.white};
  &:hover {
    background-color: ${themeColor.n10};
  }
  &:focus-visible {
    background-color: ${themeColor.n10};
  }
`;

const LeftIconWrapper = styled.div<{
  $iconBgColor: string;
  iconBgSize: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius.md};
  background-color: ${(props) => props.$iconBgColor};
  min-width: ${({ iconBgSize }) => iconBgSize};
  min-height: ${({ iconBgSize }) => iconBgSize};
`;

const TextWrapper = styled.div`
  text-align: left;
  line-height: ${lineHeight.tight};
  margin-left: ${spacing.xl};
  margin-right: ${spacing.lg};
`;

const Tagline = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${fontSize.h6};
`;

const TooltipWrapper = styled.div`
  margin-top: ${spacing.sm};

  &:hover span {
    text-decoration: none;
  }
`;

const TooltipIcon = styled(Icon)`
  margin-left: ${spacing.md};
  margin-bottom: ${spacing.xs};
  vertical-align: middle;
`;

const Header = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h5};
`;

const IconWrapper = styled(Icon)`
  margin-left: auto;
`;
