import { PropsWithChildren, KeyboardEvent, ForwardedRef } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { animated } from "@react-spring/web";
import { VisuallyHidden } from "../VisuallyHidden/VisuallyHidden";
import { ArrowForward } from "../../svg/ArrowForward";
import { Icon } from "../Icon/Icon";
import { Button } from "../Button/Button";
import {
  color,
  fontSize,
  fontWeight,
  spacing,
  textColor,
  lineHeight,
  iconSize,
} from "../../theme";
import { Modal } from "../Modal/Modal";
import { Slide } from "../Transition/Slide";
import { Close } from "../../svg/Close";
import { ButtonBase } from "../Button/ButtonBase";
import messages from "./Drawer.messages";

type DrawerProps = PropsWithChildren<{
  onBackdropClicked: () => void;
  onCloseClicked: () => void;
  title?: string;
  ctaText?: string;
  onCtaClick?: () => void;
  isOpen: boolean;
  anchor?: Anchor;
  hasPadding?: boolean;
  hasRoundedCorners?: boolean;
  hideCloseButton?: boolean;
  height?: string;
  heightPercentage?: string;
  closeSize?: keyof typeof iconSize;
  greyBackground?: boolean;
  contentRef?: ForwardedRef<HTMLDivElement>;
  order?: number;
}>;

type Anchor = "top" | "bottom" | "right";

export function Drawer({
  onBackdropClicked,
  onCloseClicked,
  title,
  ctaText,
  onCtaClick,
  isOpen,
  anchor,
  hasPadding,
  hasRoundedCorners,
  hideCloseButton,
  height,
  heightPercentage,
  closeSize,
  greyBackground,
  contentRef,
  order,
  children,
}: DrawerProps) {
  const intl = useIntl();

  const anchorValue = anchor ?? "bottom";
  const paddingExists = hasPadding ?? true;
  const roundedCornersExist = hasRoundedCorners ?? true;

  const showCta = !!(onCtaClick && ctaText);

  function handleKeyDown(event: KeyboardEvent<HTMLButtonElement>) {
    switch (event.key) {
      case "Enter":
        onCloseClicked();
        break;
    }
  }

  return (
    <Modal order={order} open={isOpen} onBackdropClicked={onBackdropClicked}>
      <Slide open={isOpen} anchor={anchorValue}>
        <DrawerBackground
          data-testid="drawer"
          anchor={anchorValue}
          hasRoundedCorners={roundedCornersExist}
          height={height}
          heightPercentage={heightPercentage}
          greyBackground={greyBackground}
        >
          <DrawerContent hasPadding={paddingExists} ref={contentRef}>
            {hideCloseButton ? (
              <VisuallyHidden>
                <button onKeyDown={handleKeyDown}>
                  {intl.formatMessage(messages.close)}
                </button>
              </VisuallyHidden>
            ) : (
              <DrawerCloseButton
                size={closeSize ?? "lg"}
                onClose={onCloseClicked}
              />
            )}
            {title && <Title>{title}</Title>}
            <Content>{children}</Content>
          </DrawerContent>
          {showCta && (
            <DrawerCta>
              <Button
                backgroundColor="pink"
                textColor="primaryOnDark"
                size="large"
                rightIcon={<ArrowForward tint="primaryOnDark" />}
                onClick={onCtaClick}
              >
                {ctaText}
              </Button>
            </DrawerCta>
          )}
        </DrawerBackground>
      </Slide>
    </Modal>
  );
}

const DrawerBackground = animated(styled.div<{
  anchor: Anchor;
  hasRoundedCorners: boolean;
  height: string;
  tripPlannerHeight?: boolean;
  greyBackground?: boolean;
  heightPercentage?: string;
}>`
  background-color: ${(props) =>
    props.greyBackground ? color.grey0 : color.white};
  position: fixed;
  color: ${textColor.primaryOnLight};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  ${(props) => {
    switch (props.anchor) {
      case "top":
        return `
          top: 0;
          max-height: 80%;
          width: 100%;
          `;
      case "bottom":
        return `
          bottom: 0;
          min-height: 40%;
          max-height: 80%;
          width: 100%;
          `;
      case "right":
        return `
          top: 0;
          right: 0;
          height: 100%;
          min-width: 40%;
          max-width: 80%;
          `;
    }
  }};

  ${({ height }) => !!height && `height:${height}; min-height: 50%;`}

  ${({ heightPercentage }) =>
    !!heightPercentage && `height:${heightPercentage}%; min-height: 50%;`}

    ${({ heightPercentage }) =>
    !!heightPercentage && `max-height:${heightPercentage}%; min-height: 50%;`}

  ${(props) => {
    if (props.hasRoundedCorners) {
      switch (props.anchor) {
        case "top":
          return `border-radius: 0 0 24px 24px;`;
        case "bottom":
          return `border-radius: 24px 24px 0 0;`;
        case "right":
          return `border-radius: 24px 0 0 24px;`;
      }
    }
  }}
`);

const DrawerContent = styled.div<{ hasPadding: boolean }>`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${(props) => {
    if (props.hasPadding) {
      return `
        padding-bottom: ${spacing.xl};
        padding-top: ${spacing.xl};
      `;
    }
  }}
`;

const Title = styled.h2`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h4};
  padding-left: ${spacing.xl};
  padding-top: ${spacing.xl};
  padding-right: ${spacing.xl};
`;

const Content = styled.div`
  font-size: ${fontSize.body};
  line-height: ${lineHeight.normal};
`;

const DrawerCta = styled.div`
  width: 100%;
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
  padding-top: ${spacing.lg};
  padding-bottom: ${spacing.lg};
  box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.1);
`;

type DrawerCloseButtonType = {
  onClose: () => void;
  size: keyof typeof iconSize;
};

export function DrawerCloseButton({ onClose, size }: DrawerCloseButtonType) {
  const intl = useIntl();

  return (
    <CloseButtonWrapper>
      <IconButton onClick={onClose}>
        <Icon size={size}>
          <Close tint="cod" title={intl.formatMessage(messages.close)} />
        </Icon>
      </IconButton>
    </CloseButtonWrapper>
  );
}

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IconButton = styled(ButtonBase)`
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};
`;
