import { Fragment } from "react/jsx-runtime";
import { Icon } from "src/components/Icon/Icon";
import { TransitIcon } from "src/components/Icon/TransitIcon";
import { spacing } from "src/design-system/tokens/spacing";
import { ChevronRight } from "src/svg/ChevronRight";
import { iconSize } from "src/theme";
import { Mode } from "src/utils/types/mode";
import styled from "styled-components";

export function TripCardModes({
  separator = true,
  majorModes,
  duration,
}: {
  majorModes: Mode[];
  duration?: number;
  separator?: boolean;
}) {
  return (
    <TransitIcons $gap={!separator}>
      {majorModes.map((mode, i) => {
        const key = `${mode}-${i + Number(duration)}`;
        return (
          <Fragment key={key}>
            {separator && i !== 0 && (
              <Separator>
                <ChevronRight strokeWidth="3" tint="n300" />
              </Separator>
            )}
            <TripCardIconWrapper rotation={getTransitModeRotation(mode)}>
              <Icon size="md">
                <TransitIcon transitMode={mode} tint={mode} />
              </Icon>
            </TripCardIconWrapper>
          </Fragment>
        );
      })}
    </TransitIcons>
  );
}

function getTransitModeRotation(transitMode: Mode): string | undefined {
  const rotations: { [key in Mode]?: string } = {
    plane: "90deg",
  };
  return rotations[transitMode];
}

const Separator = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${spacing.xs};

  svg {
    display: inline-block;
    height: 8px;
    width: auto;
  }
`;

const TransitIcons = styled.div<{ $gap: boolean }>`
  display: flex;
  align-items: center;
  ${({ $gap }) => $gap && `gap: ${spacing.lg};`}
`;

const TripCardIconWrapper = styled.div<{ rotation?: string }>`
  width: ${iconSize.sm};
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  svg {
    max-width: 100%;
    height: auto;
    ${({ rotation }) => rotation && `transform: rotate(${rotation});`}
  }
`;
