import { defineMessages } from "react-intl";

export const messages = defineMessages({
  moveElement: {
    id: "dragAndDropList.moveElement",
    description:
      "Message to move an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage: "Move {element} from position {from} to position {to}.",
  },
  dragElement: {
    id: "dragAndDropList.dragElement",
    description:
      "Message to drag an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage:
      "Press space bar to start dragging {element}. When dragging you can use the arrow keys to move the item around and escape to cancel.",
  },
  delete: {
    id: "dragAndDropList.delete",
    description:
      "Message to delete an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage: "Delete {element}",
  },
  confirmDelete: {
    id: "dragAndDropList.confirmDelete",
    description:
      "Message to delete an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage: "Confirm Delete",
  },
  confirmDeleteMessage: {
    id: "dragAndDropList.confirmDeleteMessage",
    description:
      "Message to delete an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage: "Are you sure you want to delete {element}?",
  },
});
