import { JSXElementConstructor, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { darken } from "polished";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  neutralColor,
  spacing,
} from "src/theme";
import { Icon } from "../Icon/Icon";
import { useRadioGroup } from "./RadioGroupContext";
import { RoundRadioIcon } from "./RoundRadioIcon";

type RadioProps = {
  value: string;
  className?: string;
  left?: boolean;
  disabled?: boolean;
  inactiveBackgroundColor?: keyof typeof color;
  cover?: boolean;
  CustomIcon?: JSXElementConstructor<{ isChecked: boolean }>;
};

/**
 * Radio must be a child of RadioGroup.
 */
export function RoundRadio({
  value,
  className,
  left,
  disabled,
  children,
  inactiveBackgroundColor,
  cover,
  CustomIcon,
}: PropsWithChildren<RadioProps>) {
  const radioGroupState = useRadioGroup();

  if (radioGroupState === undefined) {
    console.error("RoundRadio cannot be rendered outside a select");
    return <></>;
  }

  const isChecked = radioGroupState.value === value;

  const subContents =
    typeof children === "string" ? (
      <Typography isChecked={isChecked}>{children}</Typography>
    ) : (
      children
    );

  return (
    <Row className={className} left={left} disabled={disabled} cover={cover}>
      {left && subContents}
      <Checkbox>
        <Input
          type="radio"
          onChange={(event) => radioGroupState.onChange(event.target.value)}
          name={radioGroupState.name}
          value={value}
          checked={isChecked}
          disabled={disabled && !isChecked}
        />

        <Icon size={CustomIcon ? "xxxl" : "xxl"} aria-hidden={false}>
          {CustomIcon ? (
            <CustomIcon isChecked={isChecked} />
          ) : (
            <RoundRadioIcon
              isChecked={isChecked}
              disabled={disabled}
              inactiveBackgroundColor={inactiveBackgroundColor}
              tint="pink"
            />
          )}
        </Icon>
      </Checkbox>
      {!left && subContents}
    </Row>
  );
}

const Typography = styled.span<{ isChecked: boolean }>`
  font-size: ${fontSize.body};
  font-weight: ${({ isChecked }) =>
    isChecked ? fontWeight.medium : fontWeight.normal};
`;

const Checkbox = styled.span`
  position: relative;
  line-height: 0;
  margin: ${spacing.lg} 0;
`;

const Row = styled.label<{
  left?: boolean;
  disabled?: boolean;
  cover?: boolean;
}>`
  display: inline-flex;
  min-height: 48px;
  gap: ${spacing.lg};
  justify-content: ${({ left }) => (left ? "flex-end" : "flex-start")};
  align-items: center;
  padding: 0 ${spacing.md};

  border-radius: ${borderRadius.sm};
  ${({ disabled }) => disabled && `color: ${neutralColor.n40};`}
  cursor: ${({ disabled }) => (disabled ? "inherit" : "pointer")};
  ${({ cover }) =>
    cover &&
    css`
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    `}

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? "inherit" : darken(0.05, color.white)};

    @media (hover: none) {
      background-color: unset;
    }
  }
`;

const Input = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  overflow: hidden;
  white-space: nowrap;
`;
