import { useRef } from "react";
import { scrollbarHiddenStyling } from "src/utils/styles/common";
import styled, { css } from "styled-components";
import { useHorizontalMouseScroll } from "src/utils/hooks/useHorizontalMouseScroll";
import { Place } from "src/api/SearchResponse";
import { HotelViewModel } from "src/domain/HotelsScreen/hotelsScreenAdapter";
import { spacing } from "src/theme";
import { TripHotelCard } from "../TripHotelCard/TripHotelCard";
import { ViewAllStaysCTA } from "../ViewAllStaysCta";

type HotelCardsListProps = {
  hotelList: HotelViewModel[];
};

export function HotelCardsList({ hotelList }: HotelCardsListProps) {
  return (
    <>
      {hotelList.slice(0, 5).map((hotel: HotelViewModel) => {
        return <TripHotelCard {...hotel} key={hotel.hotelId} />;
      })}
    </>
  );
}

export type HotelCarouselResultsProps = {
  hotelList: HotelViewModel[];
  place?: Place;
};

export function HotelCarouselResults({
  hotelList,
  place,
}: HotelCarouselResultsProps) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { handleMouseDown, isDragging } = useHorizontalMouseScroll(carouselRef);

  return (
    <CarouselContainer
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      $isDragging={isDragging}
      data-testid="trip-planner-hotels-carousel"
    >
      <HotelCardsList hotelList={hotelList} />
      <ViewAllStaysCTA place={place} />
    </CarouselContainer>
  );
}

export const CarouselContainer = styled.div<{ $isDragging?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.md};
  margin: ${spacing.lg} 0;
  user-select: none;
  overflow-y: scroll;
  &:active {
    cursor: grabbing;
  }
  ${({ $isDragging }) =>
    $isDragging &&
    css`
      * {
        pointer-events: none;
      }
    `}
  ${scrollbarHiddenStyling}
`;
