import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Icon } from "src/components/Icon/Icon";
import { ChevronRight } from "src/svg/ChevronRight";
import { spacing } from "src/theme";
import useSearch from "src/utils/hooks/useSearch";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import styled from "styled-components";
import { hashChange } from "../../utils/location/hashChange";

import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { Kind } from "../../api/Kind";
import { Skeleton } from "../../components/Skeleton/Skeleton";
import { useTheme } from "../../utils/hooks/useTheme";
import {
  TypedLocation,
  useTypedLocation,
} from "../../utils/hooks/useTypedLocation";
import { hashAndStateChange } from "../../utils/location/hashAndStateChange";
import { getSegmentHeadingMessage } from "../SegmentScreen/getSegmentHeadingMessage";
import { messages } from "./NavigationBreadcrumbs.messages";

type NavigationBreadcrumbsProps = {
  routeCanonical?: string;
  segment?: {
    indexInRoute?: number;
    transitKind: Kind;
  };
  showSearchBreadcrumb?: boolean;
  showRouteBreadcrumb?: boolean;
  showHotelBreadcrumb?: boolean;
  showTripSummaryBreadcrumb?: boolean;
};

type Breadcrumb = {
  text: string;
  linkLocation?: TypedLocation;
};

// Breadcrumbs are shown on Route, Segment and Hotels screens.
export function NavigationBreadcrumbs(props: NavigationBreadcrumbsProps) {
  const intl = useIntl();
  const location = useTypedLocation();
  const theme = useTheme();
  const { searchResponse } = useSearch();

  const searchResultsBreadcrumb: Breadcrumb = {
    text: intl.formatMessage(messages.tripPlan),
    linkLocation: hashAndStateChange(
      "#trips",
      { highlightedTab: "search" },
      location
    ),
  };

  const breadcrumbs = [searchResultsBreadcrumb];

  if (props.showSearchBreadcrumb) {
    const isLinked = props.showRouteBreadcrumb || props.segment;
    breadcrumbs.push({
      text: intl.formatMessage(messages.results),
      linkLocation: isLinked
        ? hashChange(tripHashFromSearchResponse(searchResponse), location)
        : undefined,
    });
  }

  if (props.showRouteBreadcrumb) {
    const tripHash = `${tripHashFromSearchResponse(searchResponse)}/r/${
      props.routeCanonical
    }`;
    const hashLink = tripHash;
    const routeBreadcrumb: Breadcrumb = {
      text: intl.formatMessage(messages.route),
      // The route doesn't need to be clickable on the route screen because
      // we'd already be on the page we're linking to.
      linkLocation:
        props.segment || props.showHotelBreadcrumb
          ? hashChange(hashLink, location)
          : undefined,
    };
    breadcrumbs.push(routeBreadcrumb);
  }

  if (props.segment) {
    const hash = `${tripHashFromSearchResponse(searchResponse)}/r/${
      props.routeCanonical
    }/s/${props.segment.indexInRoute}`;
    const segmentBreadcrumb: Breadcrumb = {
      text: intl.formatMessage(
        getSegmentHeadingMessage(props.segment.transitKind)
      ),
      // Segment breadcrumbs shown on segment screen are not a link, but user can link back from hotels screen.
      linkLocation:
        props.showHotelBreadcrumb || props.showTripSummaryBreadcrumb
          ? hashChange(hash, location)
          : undefined,
    };
    breadcrumbs.push(segmentBreadcrumb);
  }

  if (props.showHotelBreadcrumb) {
    const hotelBreadcrumb: Breadcrumb = {
      text: intl.formatMessage(messages.hotel),
      // We only show hotel breadcrumbs in the hotels screen so they never have to be a link.
      linkLocation: undefined,
    };
    breadcrumbs.push(hotelBreadcrumb);
  }

  if (props.showTripSummaryBreadcrumb) {
    const summaryBreadcrumb: Breadcrumb = {
      text: intl.formatMessage(messages.tripSummary),
      // Trip Summary is the final location of the flow so we never need to link back to it.
      linkLocation: undefined,
    };
    breadcrumbs.push(summaryBreadcrumb);
  }

  return (
    <nav>
      <BreadcrumbList>
        {breadcrumbs.map((breadcrumb, index) => (
          <ListItem key={breadcrumb.text}>
            {index !== 0 && (
              <StyledIcon size="xs">
                <ChevronRight tint={theme.breadcrumbs.iconTint} />
              </StyledIcon>
            )}
            {breadcrumb.linkLocation ? (
              <BreadcrumbLink
                to={breadcrumb.linkLocation}
                onClick={handleBreadcrumbLinkClick(breadcrumb)}
              >
                {breadcrumb.text}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbCurrent>{breadcrumb.text}</BreadcrumbCurrent>
            )}
          </ListItem>
        ))}
      </BreadcrumbList>
    </nav>
  );
}

function handleBreadcrumbLinkClick(breadcrumb: Breadcrumb) {
  return () => {
    if (breadcrumb.text === "Results") {
      sendAnalyticsInteractionEvent("PaneNav", "Click:SearchResultsBreadcrumb");
    } else if (breadcrumb.text === "Trip Summary") {
      sendAnalyticsInteractionEvent("PaneNav", "Click:RouteBreadcrumb");
    }
  };
}

export function LoadingBreadcrumbs(props: { className?: string }) {
  // We know which colour variant of the skeleton to use based on the iconTint.
  const theme = useTheme();
  const lightOnDark = theme.breadcrumbs.iconTint === "white";

  return (
    <nav className={props.className}>
      <BreadcrumbList>
        <ListItem key={0}>
          <Skeleton
            variant="body"
            width="52px"
            margin="0 auto"
            inline
            lightOnDark={lightOnDark}
          />
        </ListItem>
        <ListItem key={1}>
          <Skeleton
            variant="body"
            width="120px"
            margin="0 auto"
            inline
            lightOnDark={lightOnDark}
          />
        </ListItem>
        <ListItem key={2}>
          <Skeleton
            variant="body"
            width="52px"
            margin="0 auto"
            inline
            lightOnDark={lightOnDark}
          />
        </ListItem>
      </BreadcrumbList>
    </nav>
  );
}

const BreadcrumbList = styled.ol`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.xl};
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${spacing.xl};
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.breadcrumbs.text};

  &:hover {
    text-decoration: underline;
  }
`;

const BreadcrumbCurrent = styled.span`
  // add opacity to the color to make it lighter as theme.breadcrumbs
  // is being overwritten elsewhere in code, so I can't add a new property to it
  // TODO: fix this (CONV-1371)
  color: ${(props) => props.theme.breadcrumbs.text + "AA"};
`;
