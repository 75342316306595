import styled, { css } from "styled-components";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "src/theme";
import { FormattedDuration } from "src/components/FormattedDuration/FormattedDuration";
import { RoundRadioIcon } from "src/components/RadioGroup/RoundRadioIcon";
import { Icon } from "src/components/Icon/Icon";
import { FormattedPriceRange } from "src/components/FormattedPriceRange/FormattedPriceRange";
import { ChevronRightBold } from "src/svg/ChevronRightBold";
import { TransitIcons } from "src/domain/SearchResultsScreen/SearchResult/TransitIcons";
import { MouseEvent, SyntheticEvent } from "react";
import { TripPlannerTransportKey } from "src/domain/TripPlanner/TripPlannerProvider";
import { createTransportKey } from "src/domain/TripPlanner/util/createTransportKey";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Close } from "src/svg/Close";
import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { useLayout } from "src/utils/hooks/useLayout";
import { Gas } from "src/svg/Gas";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { messages } from "../TripCard/TripCard.messages";
import { RouteTripCardProps } from "../TripCard/RouteTripCard/RouteTripCard";
import { ScheduleOrSegmentTripCardProps } from "../TripCard/ScheduleTripCard/ScheduleTripCard";

export function SearchResultCard(
  props: RouteTripCardProps | ScheduleOrSegmentTripCardProps
) {
  const intl = useIntl();
  const layout = useLayout();
  const { dispatch } = useTripPlannerContext();
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const isRadioHidden = isTripsAsCoreHoldback;
  const removeButtonTitle = intl.formatMessage(messages.unsave);
  const isDriveRoute =
    props.majorModes?.length === 1 && props.majorModes[0] === "car";
  let canonicalPair: TripPlannerTransportKey | undefined;

  if (props.origin.canonicalName && props.destination.canonicalName) {
    canonicalPair = createTransportKey(
      props.origin.canonicalName,
      props.destination.canonicalName
    );
  } else {
    throw new Error(
      `Invalid canonicalPair for ${props.origin.canonicalName} -> ${props.destination.canonicalName}`
    );
  }

  const tripCardClickHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    if (props.clickCallback) {
      props.clickCallback();
    }
  };

  function handleRemoveClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (canonicalPair) {
      dispatch({
        type: "REMOVE_TRANSPORT",
        originDestinationKey: canonicalPair,
      });
      sendAnalyticsInteractionEvent(
        "TripPlanner",
        "Deselect:TripOption",
        props.gaLabel
      );
    }
  }

  return (
    <SearchResultCardContainer
      onClick={tripCardClickHandler}
      href={props.url.hash}
      $isTripsAsCoreHoldback={isTripsAsCoreHoldback}
    >
      {!isRadioHidden ? (
        <RadioWrapper onClick={handleRemoveClick}>
          <Icon size="xxl">
            <RoundRadioIcon
              title={removeButtonTitle}
              isChecked={true}
              disabled={true}
              inactiveBackgroundColor="white"
              tint="pink"
            />
          </Icon>
        </RadioWrapper>
      ) : null}
      <RouteDetailsWrapper>
        <Row>
          {props.majorModes && <TransitIcons modes={props.majorModes} />}
          <DeleteButton onClick={handleRemoveClick}>
            <Icon size={layout === "desktop" ? "sm-1" : "xs"}>
              <Close title={removeButtonTitle} tint="n50" />
            </Icon>
          </DeleteButton>
        </Row>
        <TypographyHeading>{props.title}</TypographyHeading>
        <Row>
          <TypographyBody>
            {props.durationInMinutes && (
              <Duration totalMinutes={props.durationInMinutes} />
            )}
          </TypographyBody>
          <TypographyCta>
            {props.priceRange && isDriveRoute && (
              <Icon size="md">
                <Gas tint="n300" />
              </Icon>
            )}
            {props.priceRange && <FormattedPriceRange {...props.priceRange} />}
            <Icon size="sm-1" data-testid="chevron">
              <ChevronRightBold tint="pink" />
            </Icon>
          </TypographyCta>
        </Row>
      </RouteDetailsWrapper>
    </SearchResultCardContainer>
  );
}

const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: min(20%, 56px);
  background-color: ${color.n10};
  border-radius: ${borderRadius.md} 0 0 ${borderRadius.md};
  border-right: none;
`;
const RouteDetailsWrapper = styled.div`
  padding: ${spacing.lg};
  width: 100%;
`;

const Duration = styled(FormattedDuration)`
  line-height: ${lineHeight.tight};
`;

const TypographyHeading = styled.h1`
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.tight};
  font-weight: ${fontWeight.medium};
  color: ${color.cod};
  margin: ${spacing.md} 0;
`;

const TypographyBody = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.tight};
  color: ${color.cod};
`;

const TypographyCta = styled(TypographyBody)`
  font-weight: ${fontWeight.medium};
  color: ${color.pink};
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DeleteButton = styled(ButtonBase)`
  padding: ${spacing.md};
  margin: -${spacing.md} -${spacing.md} -${spacing.md} auto;
  opacity: 0;
  transition: opacity 0.3s ease;
`;
const SearchResultCardContainer = styled.a<{
  $isTripsAsCoreHoldback?: boolean;
}>`
  display: flex;
  text-decoration: none;
  background-color: ${color.white};
  border-radius: ${borderRadius.md};
  border: 1px solid transparent;

  ${({ $isTripsAsCoreHoldback }) =>
    $isTripsAsCoreHoldback
      ? css`
          box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 0px;
          border-color: ${color.n30};

          &:hover {
            border-color: ${color.n40};
            box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;

            ${DeleteButton} {
              opacity: 1;
            }
          }
        `
      : css`
          &:hover {
            border-color: ${color.n40};

            ${DeleteButton} {
              opacity: 1;
            }
          }
        `}

  &:hover {
    border-color: ${color.n40};

    ${DeleteButton} {
      opacity: 1;
    }
  }
`;
