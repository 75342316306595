import { SvgProps, useColor } from "./common";

export const Minus = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  const strokeWidth = props.strokeWidth ?? "2";

  return (
    <svg
      width="16"
      height="3"
      viewBox="0 0 16 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        d="M14.9999 1.49995L0.999908 1.49995"
        stroke={tintColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
