const color = {
  bg: {
    fill: {
      fill: "var(--color-fill)",
      hover: "var(--color-fill-hover)",
      active: "var(--color-fill-active)",
    },
    brand: {
      fill: "var(--color-fill-brand)",
      hover: "var(--color-fill-brand-hover)",
      active: "var(--color-fill-brand-active)",
    },
    transparent: {
      fill: "var(--color-fill-transparent)",
      hover: "var(--color-fill-transparent-hover)",
      active: "var(--color-fill-transparent-active)",
    },
    surface: {
      surface: "var(--color-surface)",
      active: "var(--color-surface-active)",
      secondary: "var(--color-surface-secondary)",
      primaryHover: "var(--color-surface-secondary-hover)",
    },
  },
  border: {
    border: "var(--color-border)",
    brand: "var(--color-border-brand)",
    hover: "var(--color-border-hover)",
    active: "var(--color-border-active)",
  },
  icon: {
    icon: "var(--color-icon)",
    secondary: "var(--color-icon-secondary)",
    error: "var(--color-border-error)",
    onBgFill: "var(--color-icon-on-bg-fill)",
  },
  text: {
    text: "var(--color-text)",
    success: "var(--color-text-success)",
    onBgFill: "var(--color-text-on-bg-fill)",
    primary: "var(--color-text-primary)",
    secondary: "var(--color-text-secondary)",
    tertiary: "var(--color-text-tertiary)",
    error: "var(--color-text-error)",
  },
  input: {
    bgSurface: "var(--color-input)",
    bgSurfaceActive: "var(--color-input-active)",
    border: "var(--color-input-border)",
    borderHover: "var(--color-input-border-hover)",
    borderHoverActive: "var(--color-input-border-active)",
    borderError: "var(--color-input-border-error)",
  },
  /**
   * @deprecated Use the CSS variable variant instead.
   */
  legacy: {
    bg: {
      brand: {
        fill: "#de007b",
      },
    },
  },
} as const;

/**
 * @deprecated Use tokens instead of primitives.
 */
export const primitive = {
  product_500: "#c90070",
  neutral_100: "#ffffff",
  neutral_300: "#fafafa",
  neutral_400: "#f7f7f7",
  neutral_500: "#f3f3f3",
  neutral_700: "#ebebeb",
  neutral_800: "#e3e3e3",
  neutral_900: "#d4d4d4",
  neutral_1100: "#b5b5b5",
  neutral_1500: "#303030",
  neutral_1600: "#1a1a1a",
  neutral_alpha_700: "rgba(0, 0, 0, 0.13)",
  neutral_alpha_1200: "rgba(0, 0, 0, 0.45)",
  blue_500: "#3269d9",
} as const;

export default color;
