import { defineMessages } from "react-intl";

export const transitSwitchMessages = defineMessages({
  transfer: {
    id: "timeline.transfer",
    description:
      "Note for when a user has to switch to a different transit mode. For example, Switching from Train to Bus",
    defaultMessage: "Transfer",
  },
  change: {
    id: "timeline.change",
    description:
      "Note for when to switch between the same transit mode at the same stop. For example, Switching from one train to another train at the same station",
    defaultMessage: "Change",
  },
});
