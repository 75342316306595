import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { Icon } from "src/components/Icon/Icon";
import { TripPlannerTransportKey } from "src/domain/TripPlanner/TripPlannerProvider";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { Close } from "src/svg/Close";
import { MenuLine } from "src/svg/MenuLine";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  iconSize,
  lineHeight,
  spacing,
} from "src/theme";
import styled, { css } from "styled-components";
import { AddDestinationDialog } from "../../AddDestinationButton/AddDestinationDialog/AddDestinationDialog";
import { placeNumberOffsetPx } from "../../Headings/PlaceTitle";
import { useEditPlace } from "../../MoreOptionsButton/useEditPlace";
import messages from "./TripCardHeading.messages";

type Props = {
  canonicalPair: TripPlannerTransportKey;
  geocodedPlace: GeocodedPlace;
  index: number;
  draggableProperties?: Partial<DraggableProvidedDragHandleProps> & {
    "data-rbd-drag-handle-draggable-id": string;
  };
  isDragEnding?: boolean;
  onRemove: () => void;
};

export function TripCardInputHeading({
  canonicalPair,
  index,
  geocodedPlace,
  draggableProperties,
  isDragEnding,
  onRemove,
}: Props) {
  const intl = useIntl();
  const { dispatch, tripPlannerDetails, dispatchExpandedPlaces } =
    useTripPlannerContext();
  const { setEditedPlace } = useEditPlace(index, (newPlace) => {
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Select:EditDestinationInline",
      newPlace.canonicalName
    );
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleEditDrawer = useCallback(() => {
    const action = `${!isDrawerOpen ? "Open" : "Close"}:EditDestination`;
    sendAnalyticsInteractionEvent("TripPlanner", action);
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen, setIsDrawerOpen]);

  const onSelectDestination = useCallback(
    (newPlace: AutocompletePlace, place: GeocodedPlace) => {
      setIsDrawerOpen(false);
      if (newPlace.canonicalName !== place.canonicalName) {
        setEditedPlace(newPlace);
      }
    },
    [setEditedPlace]
  );

  const onClickDelete = useCallback(() => {
    if (index === tripPlannerDetails.places.length - 1) {
      onRemove();
    }

    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:RemoveDestinationInline",
      canonicalPair.split("_")[0]
    );
    dispatch({
      type: "REMOVE_PLACE",
      index,
    });
    // We're going to have 2 places left, expand that pair.
    if (tripPlannerDetails.places.length === 3) {
      dispatchExpandedPlaces({
        type: "EXPAND_BY_POSITION",
        index: 0,
      });
    }
  }, [
    index,
    onRemove,
    dispatch,
    canonicalPair,
    tripPlannerDetails.places,
    dispatchExpandedPlaces,
  ]);

  return (
    <Container
      data-testid={`trip-card-heading-${index}`}
      {...draggableProperties}
      $isDraggable
    >
      <DragHandleButton
        data-testid={`drag-handle-${draggableProperties?.["data-rbd-drag-handle-draggable-id"]}`}
      >
        <Icon size="md">
          <MenuLine title={intl.formatMessage(messages.dragPlace)} tint="n80" />
        </Icon>
      </DragHandleButton>
      <PlaceNumber isDragEnding={isDragEnding}>{index + 1}</PlaceNumber>
      <InnerContentContainer>
        <PlaceDetailsContainer>
          <PlaceNameButton
            title={intl.formatMessage(messages.edit)}
            onClick={toggleEditDrawer}
            data-testid="place-name-btn"
          >
            <TruncatedText>
              {geocodedPlace.longName ?? geocodedPlace.shortName}
            </TruncatedText>
          </PlaceNameButton>
        </PlaceDetailsContainer>
        <PlaceDeleteButton
          data-testid="remove-place-btn"
          onClick={onClickDelete}
        >
          <Icon size="xs">
            <Close
              title={intl.formatMessage(messages.removePlace)}
              tint="cod"
            />
          </Icon>
        </PlaceDeleteButton>
      </InnerContentContainer>

      <AddDestinationDialog
        onSelectOption={(newPlace) => {
          onSelectDestination(newPlace, geocodedPlace);
        }}
        onBackdropClicked={toggleEditDrawer}
        onCloseClicked={toggleEditDrawer}
        isOpen={isDrawerOpen}
        place={geocodedPlace}
        initialValue={geocodedPlace.longName ?? geocodedPlace.shortName}
      />
    </Container>
  );
}
type LoadingProps = {
  index: number;
  name?: string;
};
export function LoadingHeading({ index, name }: LoadingProps) {
  return (
    <Container $isDraggable={false}>
      <InnerContentContainer>
        <PlaceDetailsContainer>
          <PlaceNumber>{index + 1}</PlaceNumber>
          <PlaceNameButton>
            <TruncatedText>{name}</TruncatedText>
          </PlaceNameButton>
        </PlaceDetailsContainer>
      </InnerContentContainer>
    </Container>
  );
}

const DragHandleButton = styled.div`
  position: absolute;
  left: -${spacing.xs};

  padding-top: ${spacing.xl};
  padding-left: 10px; // spacing.md + 2px;
  transform: translateX(-100%);
  opacity: 0;
  height: 48px;
  width: 30px;
  align-items: center;
  justify-content: center;
  cursor: grab;
`;

const PlaceEdit = styled.div`
  display: inline-block;
  transition: opacity 0.3s ease;
  opacity: 1;
  margin: ${spacing.sm} ${spacing.xs} 0 ${spacing.lg};
`;

export const PlaceNameButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h5};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
  background-color: ${color.n20};
  text-align: center;
  border: 1px solid transparent;
  border-radius: ${borderRadius.md};

  max-height: 40px;
  padding: ${spacing.lg} ${spacing.xl};
  margin-left: ${spacing.sm};
  max-width: 440px;
  min-width: 280px;
`;

export const PlaceNumber = styled.span<{ isDragEnding?: boolean }>`
  display: flex;
  align-items: center;
  align-self: center;
  flex-shrink: 0;
  justify-content: center;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.snug};
  border-radius: 50%;
  width: ${placeNumberOffsetPx * 2}px;
  height: ${placeNumberOffsetPx * 2}px;
  background-color: ${color.n30};
  color: ${color.cod};
  margin: 0 ${spacing.md} 0 0;
  opacity: 1;
  left: 10px;
  top: 10px;
  transform: translateX(-100%);
  position: absolute;
`;
const PlaceDeleteButton = styled(ButtonBase)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: center;
  width: ${iconSize.xxxl};
  margin-right: ${spacing.md};

  fill: ${color.n50};
  opacity: 0;
  transition: opacity 0.3s ease;
`;
const PlaceDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: ${borderRadius.xl};
  cursor: grab;
  width: 100%;
`;

const InnerContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;
  margin-left: 20px;
  height: 48px;
  gap: ${spacing.xl};
`;

const Container = styled.div<{ $isDraggable: boolean }>`
  margin-bottom: ${spacing.xs};
  margin-left: -${spacing.xxl};
  position: relative;

  ${({ $isDraggable }) =>
    $isDraggable &&
    css`
      &:hover {
        background-color: ${color.n10};
        border-top-right-radius: ${borderRadius.md};
        border-bottom-right-radius: ${borderRadius.md};

        ${PlaceNumber} {
          opacity: 0;
        }

        ${PlaceDeleteButton}, ${DragHandleButton}, ${PlaceEdit} {
          opacity: 1;
        }

        ${DragHandleButton} {
          transform: translateX(-70%);
          background-color: ${color.n10};
          border-top-left-radius: ${borderRadius.md};
          border-bottom-left-radius: ${borderRadius.md};
        }

        ${PlaceNameButton} {
          border: 1px solid ${color.cod};
          background-color: ${color.white};
        }
      }
      &:focus,
      &:active {
        background-color: ${color.n10};
        border-top-right-radius: ${borderRadius.md};
        border-bottom-right-radius: ${borderRadius.md};

        ${PlaceNumber} {
          opacity: 0;
        }

        ${DragHandleButton} {
          opacity: 1;
          transform: translateX(-70%);
          background-color: ${color.n10};
          border-top-left-radius: ${borderRadius.md};
          border-bottom-left-radius: ${borderRadius.md};
        }

        ${PlaceNameButton} {
          background-color: ${color.n20};
          border: 1px solid ${color.n30};
        }
      }
    `}
`;

export const TruncatedText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
