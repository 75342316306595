import styled, { css } from "styled-components";
import { GeocodedPlace } from "src/PrefetchData";
import { color, fontSize, fontWeight, lineHeight, spacing } from "src/theme";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { PlaceNumber, TruncatedText } from "./TripCardInputHeading";

type Props = {
  geocodedPlace: GeocodedPlace;
  index: number;
  draggableProperties?: Partial<DraggableProvidedDragHandleProps> & {
    "data-rbd-drag-handle-draggable-id": string;
  };
  isHidden?: boolean;
};

export function TripCardInputHeadingSimple({
  geocodedPlace,
  index,
  draggableProperties,
  isHidden,
}: Props) {
  return (
    <Container
      data-testid={`trip-card-heading-${index}`}
      {...draggableProperties}
      draggable={false}
      $isHidden={isHidden}
    >
      <PlaceNumber>{index + 1}</PlaceNumber>
      <PlaceTitle>
        <TruncatedText>
          {geocodedPlace.longName ?? geocodedPlace.shortName}
        </TruncatedText>
      </PlaceTitle>
    </Container>
  );
}

const Container = styled.div<{ $isHidden?: boolean }>`
  margin-left: 20px;
  position: relative;
  pointer-events: none;
  padding-bottom: ${spacing.md};

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      display: none;
    `}
`;

const PlaceTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h5};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
  text-align: center;

  max-height: 40px;
  padding: ${spacing.xl} 0 0 ${spacing.xxl};
  margin-left: ${spacing.sm};
  max-width: 440px;
  min-width: 280px;
`;
