import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { HotelProviderKind } from "src/utils/types/accommodationProviders";
import {
  Trigger,
  generateExitPoint,
} from "src/analytics/generateExitPoint/generateExitPoint";
import { logDataPartnerHotelExit } from "src/data-partners/logDataPartnerExit";
import { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import { SupportedLanguageCode } from "src/utils/language";
import { SearchResponse } from "src/api/SearchResponse";
import { DateCombos } from "./HotelsContext";

type ExitLocations =
  | "listCard"
  | "mapCard"
  | "tripCard"
  | "genericProviderExit"; //i.e to a general page rather than a specific hotel.

type Props = {
  variant: ExitLocations;
  actionType?: "Click" | "Enter" | "Tap";
  hotelUrl?: string;
  logExitCallback?: (
    destinationUrl: string,
    provider?: string,
    agencyIds?: string[],
    overrideTrigger?: Trigger
  ) => void;
  provider?: HotelProviderKind;
  cardIndex?: number;
  deal?: boolean;
  freeCancellation?: boolean;
  destinationName?: string;
  hotelName?: string;
  hotelStarRating?: number;
  stayDates?: DateCombos;
  languageCode?: SupportedLanguageCode;
  searchResponse?: SearchResponse;
  avgDailyRate?: number;
  destinationState?: string;
  isCrimtanEnabled?: boolean;
  isAdaraEnabled?: boolean;
};

// The goal here is to be a central utility for accommodation provider exits.
// This is important as we introduce 3rd party data activities on these exits, such as crimtan.
// We don't want to maintain that kind of thing across multiple locations.

// Exit locations unaccounted for:
// AccommodationPromoCell (currently unused 20240625) TODO: can delete?
export function exitToProviderWithLogging({
  variant,
  hotelUrl,
  actionType = "Click",
  logExitCallback,
  provider,
  cardIndex,
  deal = false,
  freeCancellation = false,
  destinationName,
  destinationState,
  hotelName,
  hotelStarRating,
  stayDates,
  languageCode,
  searchResponse,
  avgDailyRate,
  isCrimtanEnabled = false,
  isAdaraEnabled = false,
}: Props) {
  const capitalizedActionType =
    actionType.charAt(0).toUpperCase() + actionType.slice(1);
  let category: AnalyticsEventCategory = "Hotels",
    action = `${capitalizedActionType}:HotelProviderExit`,
    label,
    formattedHotelUrl;

  const dealLabel = deal ? "-deal" : "";
  const freeCancelLabel = freeCancellation ? "-freeCancel" : "";

  if (hotelUrl && provider && hotelUrl) {
    switch (variant) {
      case "listCard":
        label = `HotelListCard:card_${cardIndex}${dealLabel}`;
        formattedHotelUrl = getLabelledHotelListCardUrl(hotelUrl);
        break;
      case "mapCard":
        label = `HotelPinCard:card${freeCancelLabel}${dealLabel}`;
        formattedHotelUrl = getLabelledHotelListCardUrl(hotelUrl);
        break;
      case "tripCard":
        category = "TripHotel";
        label = `HotelListCard`;
        formattedHotelUrl = getLabelledHotelListCardUrl(hotelUrl);
        break;
      case "genericProviderExit":
        action = `${actionType}:MoreAccommodationButton`;
        label = "";
        formattedHotelUrl = hotelUrl;
        break;
    }

    sendAnalyticsInteractionEvent(category, action, label);

    if ((isCrimtanEnabled || isAdaraEnabled) && destinationName && hotelName) {
      logDataPartnerHotelExit({
        destination: destinationName,
        destinationState,
        hotelName,
        hotelStarRating,
        checkinDate: stayDates?.[0],
        checkoutDate: stayDates?.[1],
        languageCode,
        searchResponse,
        avgDailyRate,
        isCrimtanEnabled,
        isAdaraEnabled,
      });
    }

    if (logExitCallback) {
      logExitCallback(formattedHotelUrl, provider);
    }

    window.open(formattedHotelUrl, "_blank", "noopener");
  }
}

function getLabelledHotelListCardUrl(hotelUrl: string) {
  return `${hotelUrl}&label=${labelHotelListCardExitPoint}`;
} // The url does not contain tracking information, so we have to add it.

const labelHotelListCardExitPoint = generateExitPoint("hotels", "hotelCard");
