import { TripPlannerDetails } from "src/domain/TripPlanner/TripPlannerProvider";
import { useHotelListBase } from "src/utils/hooks/useHotelList";
import { useMeaningfulPlace } from "src/utils/hooks/useMeaningfulPlace";
import { CarouselLoadingView } from "./components/CarouselLoadingView";
import { NoHotelResults } from "./components/NoHotelResults";
import { HotelCarouselResults } from "./components/HotelCarouselResults/HotelCarouselResults";

export type HotelCarouselProps = {
  origin: TripPlannerDetails["places"][number];
  index: number;
};

export function HotelsCarousel({ origin }: HotelCarouselProps) {
  const place = useMeaningfulPlace(origin);
  const { hotelList, isLoading } = useHotelListBase({
    canonicalName: place?.canonicalName,
    lat: place?.lat,
    lng: place?.lng,
    enabled: !!place,
  });

  if (isLoading) return <CarouselLoadingView />;

  if (!hotelList || !hotelList.length) {
    return <NoHotelResults />;
  }

  return <HotelCarouselResults hotelList={hotelList} place={place} />;
}
