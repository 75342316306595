import {
  DragDropContext,
  DraggableProps,
  Droppable,
  OnBeforeCaptureResponder,
  OnDragUpdateResponder,
} from "@hello-pangea/dnd";
import { color, spacing } from "src/theme";
import styled, { StyledComponent } from "styled-components";
import {
  DraggableElement,
  DraggableElementChildrenFn,
} from "./DraggableElement";
import { DraggableItem, SortableObject } from "./DraggableItem";
import { getMoving, useDragAndDropList } from "./useDragAndDropList";

export type DragAndDropListProps<T> = {
  initialItems: SortableObject<T>[];
  onChange: (items: SortableObject<T>[]) => void;
  ListComponent: StyledComponent<
    "ul",
    any,
    {
      $isDraggingOver?: boolean;
    },
    never
  >;
  globalDraggableOptions?: Partial<DraggableProps>;
  accessibleMode?: boolean;
  onItemHover?: (index?: number) => void;
  isCustomItem?: boolean;
  children?: DraggableElementChildrenFn<T>;
  onDragUpdate?: OnDragUpdateResponder;
  onBeforeCapture?: OnBeforeCaptureResponder;
  onDragEnd?: () => void;
};

export function DragAndDropList<T>({
  initialItems,
  onChange,
  ListComponent,
  accessibleMode,
  onItemHover,
  isCustomItem,
  onDragUpdate,
  onBeforeCapture,
  onDragEnd,
  children,
  globalDraggableOptions,
}: DragAndDropListProps<T>) {
  checkForDuplicateId(initialItems);
  const {
    handleDragEnd,
    handleMoveDown,
    handleMoveUp,
    handleRemove,
    setMovingUp,
    setMovingDown,
    items,
    movingUp,
    movingDown,
  } = useDragAndDropList(initialItems, onChange, isCustomItem, onDragEnd);

  return (
    <DragDropContext
      onBeforeCapture={onBeforeCapture}
      onDragUpdate={onDragUpdate}
      onDragEnd={handleDragEnd}
    >
      <Droppable droppableId="list">
        {(providedDroppable, droppableStateSnapshot) => (
          <ListComponent
            {...providedDroppable.droppableProps}
            $isDraggingOver={droppableStateSnapshot.isDraggingOver}
            ref={providedDroppable.innerRef}
          >
            {items.map((item, index) => {
              if (isCustomItem && children) {
                return (
                  <DraggableElement
                    key={item.id}
                    item={item}
                    index={index}
                    handleRemove={() => handleRemove(index)}
                    droppableSnapshot={droppableStateSnapshot}
                    draggableOptions={globalDraggableOptions}
                  >
                    {children}
                  </DraggableElement>
                );
              }
              return (
                <DraggableItem
                  key={item.id}
                  item={item}
                  index={index}
                  totalItems={items.length}
                  handleMoveDown={() => handleMoveDown(index)}
                  handleMoveUp={() => handleMoveUp(index)}
                  handleRemove={() => handleRemove(index)}
                  setMovingUp={setMovingUp}
                  setMovingDown={setMovingDown}
                  accessibleMode={accessibleMode}
                  moving={getMoving(index, movingUp, movingDown)}
                  onMouseEnter={() => onItemHover?.(index)}
                  onMouseLeave={() => onItemHover?.(undefined)}
                />
              );
            })}
            {providedDroppable.placeholder}
          </ListComponent>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function checkForDuplicateId<T>(items: SortableObject<T>[]) {
  const ids = items.map((item) => item.id);
  const uniqueIds = new Set(ids);
  if (ids.length !== uniqueIds.size) {
    console.warn(
      "The items have repeated IDs. Please use unique IDs for this component."
    );
  }
}

export const DefaultList = styled.ul<{ $isDraggingOver?: boolean }>`
  list-style: none;
  padding: ${spacing.lg};
  margin: 0;
  border-radius: 2px;
  transition: background-color 0.2s ease;
  ${(props) =>
    props.$isDraggingOver &&
    `
      background-color: ${color.n30};
    `}
`;
