import { defineMessages } from "react-intl";

const messages = defineMessages({
  hideMap: {
    id: "stackedNavigation.hideMap",
    description: "Button that hides the full screen map",
    defaultMessage: "Hide Map",
  },
});

export default messages;
