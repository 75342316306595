import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { initializeAuth } from "src/auth/utils/session";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { useHandleCreateSession } from "src/auth/hooks/useHandleCreateSession";
import { Button } from "../../../../components/Button/Button";
import { DigitCode } from "../../../../components/DigitCode/DigitCode";
import { Input } from "../../../../components/Input/Input";
import { spacing } from "../../../../theme";
import { Heading, PageContent, Text } from "../Components";
import { messages } from "../UserAccess.messages";
import type { FormState } from "../UserAccess";

type Props = {
  onStateChange: (newState: FormState) => void;
};

type Step = "email" | "code";

// Simple regex checks there's an @ symbol followed by and preceded by non-whitespace characters
const emailPattern = /^\S+@\S+$/;

export function EmailLogin(props: Props) {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [initializeId, setInitializedId] = useState("");
  const [validation, setValidation] = useState({
    isValid: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<Step>("email");
  const [hasTriedToAutoSubmit, setHasTriedToAutoSubmit] = useState(false);
  const handleCreateSession = useHandleCreateSession("OneTimePassword");
  const [otpAttempts, setOtpAttempts] = useState(0);
  const apiConfig = useApiConfig();

  async function submitEmail() {
    if (!validateEmail(email)) {
      setValidation({
        isValid: false,
        message: intl.formatMessage(messages.invalidEmail),
      });
      return;
    }
    setValidation({
      isValid: true,
      message: "",
    });
    setIsLoading(true);

    const initializeData = await initializeAuth(email, apiConfig);

    if (initializeData?.browserId) {
      setInitializedId(initializeData.browserId);
      setStep("code");
    } else {
      setValidation({
        isValid: false,
        message: intl.formatMessage(messages.problem),
      });
    }
    setIsLoading(false);

    // Reset OTP attempts
    setOtpAttempts(0);
  }

  const submitCode = useCallback(async () => {
    setIsLoading(true);
    if (!code) {
      setValidation({
        isValid: false,
        message: intl.formatMessage(messages.invalidCode),
      });
      setIsLoading(false);
      return;
    }

    const success = await handleCreateSession(
      JSON.stringify({ browserId: initializeId, OneTimePassword: code }),
      undefined,
      true
    );

    if (!success) {
      // If third attempt, user has to restart
      // NB: Compare with 2 as the value won't be updated until next render
      if (otpAttempts === 2) {
        setValidation({
          isValid: false,
          message: intl.formatMessage(messages.maxOtpAttempts),
        });
      } else {
        setValidation({
          isValid: false,
          message: intl.formatMessage(messages.codeDidNotMatch),
        });
      }
      setOtpAttempts(otpAttempts + 1);
      setCode("");
    } else {
      setValidation({
        isValid: true,
        message: "",
      });
    }
    setIsLoading(false);
  }, [code, handleCreateSession, initializeId, intl, otpAttempts]);

  function handleEmailChange(value: string) {
    setEmail(value);
    setValidation({
      isValid: true,
      message: "",
    });
  }

  function handleCodeChange(value: string) {
    setCode(value);
    setValidation({
      isValid: true,
      message: "",
    });
  }

  function handleResendCode() {
    submitEmail();
  }

  function handleEnterToSubmit(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      submitEmail();
    }
  }

  useEffect(() => {
    // TODO(@AndresBarreto-code): Handle the case where the user has already logged in or the code is wrong, or the button is loading.
    if (code.length === 6 && !isLoading && !hasTriedToAutoSubmit) {
      setHasTriedToAutoSubmit(true);
      submitCode();
    }
  }, [
    code,
    submitCode,
    isLoading,
    setHasTriedToAutoSubmit,
    hasTriedToAutoSubmit,
  ]);
  return (
    <>
      <Heading>
        {step === "email" && intl.formatMessage(messages.loginTitle)}
        {step === "code" && intl.formatMessage(messages.loginCodeTitle)}
      </Heading>
      <Text>
        {step === "email" && intl.formatMessage(messages.loginText)}
        {step === "code" &&
          intl.formatMessage(messages.loginCodeText, { email })}
      </Text>
      <PageContent>
        {step === "email" && (
          <Row>
            <Input
              id="login-email"
              name="login-email"
              type="email"
              label={intl.formatMessage(messages.email)}
              isValid={validation.isValid}
              validationMessage={validation.message}
              value={email}
              onChange={(event) => handleEmailChange(event.currentTarget.value)}
              onKeyDown={handleEnterToSubmit}
              autoComplete="email"
              inputMode="email"
              pattern={emailPattern.source}
              required
            />
          </Row>
        )}
        {step === "code" && (
          <Row>
            <DigitCode
              id="login-code"
              label={intl.formatMessage(messages.code)}
              digits={6}
              isValid={validation.isValid}
              validationMessage={validation.message}
              onChange={handleCodeChange}
            />
          </Row>
        )}
        <Row>
          <ActionsContainer>
            <LoginButton
              backgroundColor="pink"
              textColor="primaryOnDark"
              size="large"
              onClick={step === "email" ? submitEmail : submitCode}
              disabled={isLoading || (step === "code" && otpAttempts === 3)}
            >
              {step === "email"
                ? intl.formatMessage(messages.sendCode)
                : intl.formatMessage(messages.login)}
            </LoginButton>
            {step === "code" && (
              <Button
                backgroundColor="transparent"
                textColor="pinkOnLight"
                size="large"
                onClick={handleResendCode}
                inline
              >
                {intl.formatMessage(messages.resendCode)}
              </Button>
            )}
          </ActionsContainer>
        </Row>
      </PageContent>
    </>
  );
}

export function validateEmail(email: string) {
  return emailPattern.test(String(email).toLowerCase());
}

const ActionsContainer = styled.div`
  width: 100%;
  padding-top: ${spacing.xxl};
  text-align: center;
`;

const Row = styled.div`
  margin: ${spacing.xl} 0;
  display: "flex";
`;

const LoginButton = styled(Button)`
  margin-bottom: ${spacing.md};
`;
