type TripPlannerScreenKey = "transport" | "hotels" | "destination";

export function getScreenKey(hash: string): TripPlannerScreenKey | undefined {
  if (hash.includes("/transport/")) {
    return "transport";
  } else if (hash.includes("/hotels/")) {
    return "hotels";
  } else if (hash.includes("/destination/")) {
    return "destination";
  }
}
