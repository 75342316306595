import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { Geocoded } from "../../PrefetchData";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { localeToLanguageCode } from "../conversions/languageCode";

import { ONE_DAY_IN_MILLISECONDS } from "../conversions/time";
import { getCacheKeyForSearch, search } from "../search";
import { useSearchParams } from "./useSearchParams";
import useUser from "./useUser";

// useGetRoutes is responsible for querying the search API and returning the
// routes for a pair of places.
//
// It is safe to call useGetRoutes multiple times with the same canonicals, the
// result is cached and will not result in additional network calls.
export function useGetRoutes(
  originPlace?: Geocoded,
  destinationPlace?: Geocoded
) {
  const { currencyCode } = useUser();
  const intl = useIntl();
  const config = useApiConfig();

  const searchParams = useSearchParams();
  const searchDate = searchParams.get("oDateTime") ?? "anytime";
  const languageCode = localeToLanguageCode(intl.locale);

  const searchKey = getCacheKeyForSearch(
    originPlace?.canonicalName,
    destinationPlace?.canonicalName,
    currencyCode,
    languageCode,
    searchDate
  );

  const searchResultsInfo = useQuery(
    searchKey,
    async () => {
      const result = await search(
        originPlace!,
        destinationPlace!,
        currencyCode,
        languageCode,
        config
      );
      return result;
    },
    {
      cacheTime: ONE_DAY_IN_MILLISECONDS,
      staleTime: ONE_DAY_IN_MILLISECONDS,
      enabled: !!(
        originPlace?.canonicalName && destinationPlace?.canonicalName
      ),
      retryOnMount: false,
      retry: 3,
    }
  );

  return searchResultsInfo;
}
