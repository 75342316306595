import { animated } from "@react-spring/web";
import { ReactNode } from "react";
import styled from "styled-components";
import { borderRadius, color, spacing } from "../../theme";
import { findElementOfType } from "../../utils/findElementOfType";
import { Modal } from "../Modal/Modal";
import { Slide } from "../Transition/Slide";
import { Drawer2Content } from "./Drawer2Content/Drawer2Content";
import { Drawer2Title } from "./Drawer2Title/Drawer2Title";

type Props = {
  isOpen: boolean;
  onBackdropClicked: () => void;
  children: ReactNode;
  isFullScreen?: boolean;
  disableBackgroundScroll?: boolean;
};

export function Drawer2({
  isOpen,
  onBackdropClicked,
  children,
  disableBackgroundScroll,
  isFullScreen = false,
}: Props) {
  const [title] = findElementOfType(children, Drawer2Title);
  const [content] = findElementOfType(children, Drawer2Content);

  return (
    <Modal
      open={isOpen}
      onBackdropClicked={onBackdropClicked}
      disableBackgroundScroll={disableBackgroundScroll}
    >
      <Slide open={isOpen} anchor="bottom">
        <DrawerBackground
          data-testid="drawer"
          role="dialog"
          aria-labelledby={title.props.id}
          aria-describedby={content.props.id}
          fullScreen={isFullScreen}
        >
          {children}
        </DrawerBackground>
      </Slide>
    </Modal>
  );
}

const DrawerBackground = animated(styled.div<{ fullScreen?: boolean }>`
  background-color: ${color.white};
  position: fixed;
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.fullScreen ? null : "85%")};
  height: ${(props) => (props.fullScreen ? "100%" : null)};
  inset: auto 0px 0px;
  // iOS 13 does not support inset, by using bottom is fixed
  bottom: 0;
  width: 100%;
  border-radius: ${(props) =>
    props.fullScreen ? null : `${borderRadius.xxl} ${borderRadius.xxl} 0 0`};
  overflow: hidden;
  // Vertical spacing is actually 16px, but we use 8px here so we
  // can add it up across the compound components that are used.
  padding: ${spacing.md} 0 0 0;
`);
