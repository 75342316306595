import {
  ForwardedRef,
  SetStateAction,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Button } from "src/components/Button/Button";
import { Dialog } from "src/components/Dialog/Dialog";
import { DragAndDropList } from "src/components/DragAndDropList/DragAndDropList";
import { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { AccessibilityToggle } from "src/components/Toggle/AccessibilityToggle";
import { color, fontSize, fontWeight, lineHeight, spacing } from "src/theme";
import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { navigateToNewState } from "src/utils/location/navigateToNewState";
import styled from "styled-components";
import { searchPaneWidth } from "src/PaneContentWidths";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { hasTripChanged } from "../util/hasTripChanged";
import messages from "./ReorderTrip.messages";
import { UpdateButton } from "./UpdateButton";

type ReorderTripModalProps = {
  places: GeocodedPlace[];
};

export function ReorderTripModal({ places }: ReorderTripModalProps) {
  const focusRef = useRef<HTMLDivElement>(null);
  const location = useTypedLocation();
  const navigate = useNavigate();

  function handleClose() {
    navigateToNewState(navigate, { reorderingTripPlan: false }, location);
  }

  return (
    <Dialog
      isOpen={Boolean(location.state?.reorderingTripPlan)}
      onBackdropClicked={handleClose}
      variant="fullScreen"
      duration={75}
      backgroundColor="white"
      overflow="hidden"
      focusRef={focusRef}
    >
      <ReorderTrip places={places} handleClose={handleClose} ref={focusRef} />
    </Dialog>
  );
}

type ReorderTripProps = {
  places: GeocodedPlace[];
  handleClose: () => void;
};

export const ReorderTrip = forwardRef(
  (
    { places, handleClose }: ReorderTripProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const intl = useIntl();
    const isMobile = useLayout() === "mobile";
    const [accessibleMode, setAccessibleMode] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [newOrder, setNewOrder] = useState<SortableObject<GeocodedPlace>[]>(
      () =>
        places.map((place, index) => ({
          ...place,
          id: `${index}-${place.canonicalName}`,
          content: place.shortName,
        }))
    );
    const {
      dispatch,
      tripPlanningState,
      setHoveredPlaceIndex,
      setReorderingPlaces,
    } = useTripPlannerContext();

    function handleCancelClicked() {
      sendAnalyticsInteractionEvent("ReorderTrip", "Click:Cancel");
      handleClose();
    }

    function handleUpdateClicked() {
      sendAnalyticsInteractionEvent(
        "ReorderTrip",
        "Click:Update",
        hasChanges ? "modified" : "unchanged"
      );
      if (hasChanges) {
        dispatch({ type: "REORDER_TRIP", newOrder });
        tripPlanningState.updatedNotification.set(
          intl.formatMessage(messages.notification)
        );
      }
      handleClose();
    }

    function handleChange(items: SortableObject<GeocodedPlace>[]) {
      setNewOrder(items);
    }

    function handleOnChangeToggle(value: SetStateAction<boolean>) {
      sendAnalyticsInteractionEvent(
        "ReorderTrip",
        `${value ? "Select" : "Deselect"}:AccessibilityMode`
      );
      setAccessibleMode(value);
    }

    useEffect(() => {
      if (!hasChanges) {
        setHasChanges(hasTripChanged(places, newOrder));
      }
      // we do not want to check for hasChanges on every render
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [places, newOrder]);

    useEffect(() => {
      setReorderingPlaces(newOrder);
    }, [newOrder, setReorderingPlaces]);

    return (
      <Container ref={ref} tabIndex={-1}>
        {!isMobile && (
          <AccessibilityToggleContainer>
            <AccessibilityToggle
              selected={accessibleMode}
              setSelected={handleOnChangeToggle}
            />
          </AccessibilityToggleContainer>
        )}
        {isMobile && (
          <TopNav>
            <AccessibilityToggleContainer>
              <AccessibilityToggle
                selected={accessibleMode}
                setSelected={handleOnChangeToggle}
              />
            </AccessibilityToggleContainer>
            <Title>{intl.formatMessage(messages.reorderTitle)}</Title>
            <Description>
              {intl.formatMessage(messages.reorderDescription)}
            </Description>
          </TopNav>
        )}
        <DragAndDropList
          accessibleMode={accessibleMode}
          onChange={handleChange}
          initialItems={newOrder}
          ListComponent={List}
          onItemHover={setHoveredPlaceIndex}
        />
        <ButtonWrapper>
          <CancelButton
            onClick={handleCancelClicked}
            textColor="primaryOnLight"
            backgroundColor="cod"
            outline
            size={isMobile ? undefined : "large"}
          >
            {intl.formatMessage(messages.cancel)}
          </CancelButton>
          <UpdateButton
            handleUpdate={handleUpdateClicked}
            hasChanges={hasChanges}
          />
        </ButtonWrapper>
      </Container>
    );
  }
);

const Container = styled.div`
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: calc(56px + ${spacing.sm});

  ${desktopLayout} {
    padding-bottom: 0;
    margin-left: -${spacing.xl};
    margin-right: -${spacing.xl};
    // This is the height minus nav, fixed button and safari menu
    max-height: calc(100vh - 124px - 64px - 96px);

    // Hide the scrollbar for huge line names
    scrollbar-width: 4px; /* Firefox */
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
`;

const TopNav = styled.div`
  padding: 0 ${spacing.xl};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${desktopLayout} {
    padding: 0;
    align-items: flex-start;
  }
`;

const ButtonWrapper = styled.div`
  background-color: ${color.white};
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  padding: ${spacing.md} ${spacing.xl};
  box-shadow: 0px -1px 4px rgba(31, 30, 30, 0.2);
  z-index: 2;
  display: flex;
  justify-content: space-between;
  gap: ${spacing.xl};

  ${desktopLayout} {
    max-width: ${searchPaneWidth}px;
    position: absolute;
  }
`;

const CancelButton = styled(Button)`
  &:hover {
    background-color: ${color.n20};
  }
  &:focus-visible {
    background-color: ${color.n40};
  }
  &:active {
    background-color: ${color.n60};
  }
`;

const List = styled.ul<{ $isDraggingOver?: boolean }>`
  list-style: none;
  padding: ${spacing.xxl} ${spacing.xl};
  margin: 0;
  min-height: calc(100% - 2 * 56px);

  ${desktopLayout} {
    padding: ${spacing.xl} ${spacing.xl};
  }
`;

const AccessibilityToggleContainer = styled.div`
  margin-left: auto;
  margin-top: 19px;

  ${desktopLayout} {
    position: absolute;
    top: 0;
    right: ${spacing.xl};
  }
`;

const Title = styled.h2`
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h2};
  line-height: ${lineHeight.snug};
  color: ${color.n300};
`;

const Description = styled.p`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.normal};
  color: ${color.n300};
`;
