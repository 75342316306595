import { Fragment, useRef, useState } from "react";
import { SearchResponse } from "src/api/SearchResponse";
import { Accordion } from "src/components/Accordion/Accordion";
import { createSearchResultViewModel } from "src/domain/SearchResultsScreen/createSearchResultViewModel";
import { useFeature } from "src/feature/useFeature";

import { useIntl } from "react-intl";
import { searchResultScreenComponentList } from "src/analytics/generateScreenComponentsRendered/searchResultScreenComponentList";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { Icon } from "src/components/Icon/Icon";
import { TripSearchResult } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResult";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { updateTripPlanningState } from "src/domain/TripPlanner/hooks/useTripPlanningState";
import { ChevronDown } from "src/svg/ChevronDown";
import { ChevronUp } from "src/svg/ChevronUp";
import { borderRadius, color, fontSize, fontWeight, spacing } from "src/theme";
import { SearchOverrideProvider } from "src/utils/hooks/SearchOverrideProvider";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import styled, { css } from "styled-components";
import { Mode } from "src/utils/types/mode";
import { TripInlineSerpAd } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripInlineSerpAd";
import {
  SearchTripCardContainer,
  SearchTripCardDetails,
} from "../SearchTripCard/SearchTripCard";
import { TripCard, TripCardTypeProps } from "../TripCard";
import { messages } from "./SearchResultListCard.messages";
import { ResultsWrapper } from "./ResultsWrapper";

export function SearchResultListCard(
  props: TripCardTypeProps & {
    searchResponse: SearchResponse;
    isExpanded?: boolean;
    toggle?: () => void;
    index?: number;
    isDragging?: boolean;
    isPendingDragChanges?: boolean;
  }
) {
  const intl = useIntl();
  const location = useTypedLocation();
  const {
    tripPlannerDetails,
    dispatch,
    tripPlanningState,
    tripInteraction,
    isMultiTrip,
  } = useTripPlannerContext();
  const searchResults = createSearchResultViewModel(
    props.searchResponse,
    useFeature("SkipNonBookableRoutes"),
    useFeature("ApplyRouteListReordering")
  );
  const showInlineSerpAd = useFeature("DesktopInlineSerpAd");
  const isSimpleSearchV2 = useFeature("SimpleSearchV2");
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const isRadioHidden = isTripsAsCoreHoldback;
  let pageViewTrackingSent = useRef(false);
  const sendExpandedPageView =
    props.isExpanded && !pageViewTrackingSent.current;
  const initialNumResults = 4;
  const [numResults, setNumResults] = useState(initialNumResults);
  const sortedSearchResults = [
    ...searchResults.aboveFold,
    ...searchResults.belowFold,
  ];
  const paginatedSearchResults = sortedSearchResults.slice(
    0,
    !props.isDragging ? numResults : initialNumResults
  );

  const tripLegIndex = props.index;

  function handleClick() {
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      `Click:${props.isExpanded ? "Retract" : "Expand"}SearchCard`,
      props.gaLabel
    );
    if (props.toggle) {
      props.toggle();
    }
  }

  function handleTripSave(routeIndex: string) {
    if (props.searchResponse) {
      dispatch({
        type: "SAVE_SEARCH",
        searchResponse: props.searchResponse,
        url: {
          pathname: location.pathname,
          hash: `#trips/r/${
            props.searchResponse.routes[Number(routeIndex)].canonicalName
          }`,
        },
        routeIndex: routeIndex ? Number(routeIndex) : undefined,
      });

      updateTripPlanningState(
        tripPlanningState,
        tripPlannerDetails,
        props.searchResponse,
        routeIndex ? Number(routeIndex) : undefined,
        undefined,
        "Route"
      );
    }
  }

  function handleShowMoreClick() {
    setNumResults(sortedSearchResults.length);
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:ShowMoreResults",
      props.gaLabel
    );
    tripInteraction.setIsInteractionMade(true);
  }

  function handleShowLessClick() {
    setNumResults(4);
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:ShowLessResults",
      props.gaLabel
    );
    tripInteraction.setIsInteractionMade(true);
  }

  function ExpandedAnalyticsEvent() {
    const jayridePromosEnabled = useFeature("JayrideTransfersPromos");
    const components = searchResultScreenComponentList(
      props.searchResponse,
      jayridePromosEnabled
    );

    useAnalyticsPageView(
      {
        pagePath: location.pathname + location.search + location.hash,
        pageLocation: window.location.href,
      },
      props.searchResponse,
      "SearchResults",
      undefined,
      components
    );
    pageViewTrackingSent.current = true;

    return <></>;
  }

  function getModeSummary(): Mode[] {
    // Return flattened array of major modes from all results
    const majorModeSummaryOfResults = sortedSearchResults.flatMap((result) =>
      result.segments.filter((it) => it.isMajor).map((it) => it.transitMode)
    );

    // Remove any duplicates from the flattened array and returns max 3
    const modeSummaryNoDuplicates = majorModeSummaryOfResults
      .filter((item, index, self) => self.indexOf(item) === index)
      .slice(0, 3);

    return modeSummaryNoDuplicates;
  }

  return (
    <SearchOverrideProvider
      value={{
        searchResponse: props.searchResponse,
      }}
    >
      <Accordion
        expanded={!!props.isExpanded}
        skipInitialAnimation
        onChange={() => {}}
      >
        <TripCard
          {...props}
          clickCallback={handleClick}
          ContainerOverride={({ children, onClick }) => {
            if (isMultiTrip) {
              return (
                <TripCardContainer
                  data-testid={`search-card-accordion-${tripLegIndex}`}
                  $isExpanded={props.isExpanded}
                  onClick={onClick}
                >
                  {children}
                </TripCardContainer>
              );
            }
          }}
          isExpanded={props.isExpanded}
          TimelineDetailsContent={
            <SearchTripCardDetails
              {...props}
              isExpanded={props.isExpanded}
              modeSummary={getModeSummary()}
            />
          }
        />
        <TripSearchResultsContainer $hasBackground={isMultiTrip}>
          <>
            {sendExpandedPageView && <ExpandedAnalyticsEvent />}
            <ResultsWrapper
              handleTripSave={handleTripSave}
              isRadioHidden={isRadioHidden}
            >
              {paginatedSearchResults.map((searchResult, index) => (
                <Fragment key={searchResult.title + index}>
                  <TripSearchResult
                    {...searchResult}
                    $size="sm"
                    $shadow={!isSimpleSearchV2}
                    isRadioHidden={isRadioHidden}
                  />
                  {showInlineSerpAd && tripLegIndex === 0 && index === 0 && (
                    <TripInlineSerpAd
                      flightOriginCode={searchResult.flightOriginCode}
                      flightDestCode={searchResult.flightDestCode}
                    />
                  )}
                </Fragment>
              ))}
            </ResultsWrapper>
            {numResults < sortedSearchResults.length && (
              <ButtonWrapper>
                <ShowMoreButton
                  onClick={handleShowMoreClick}
                  data-testid={`show-more-${props.index}`}
                >
                  {intl.formatMessage(messages.showMoreResults, {
                    count:
                      sortedSearchResults.length -
                      paginatedSearchResults.length,
                  })}
                  <Icon size="sm">
                    <ChevronDown tint="n300" />
                  </Icon>
                </ShowMoreButton>
              </ButtonWrapper>
            )}
            {numResults > 4 && (
              <ButtonWrapper>
                <ShowMoreButton onClick={handleShowLessClick}>
                  {intl.formatMessage(messages.showLessResults)}
                  <Icon size="sm">
                    <ChevronUp tint="n300" />
                  </Icon>
                </ShowMoreButton>
              </ButtonWrapper>
            )}
          </>
        </TripSearchResultsContainer>
      </Accordion>
    </SearchOverrideProvider>
  );
}

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${spacing.xl} ${spacing.md} ${spacing.md};
`;

const ShowMoreButton = styled(ButtonBase)`
  gap: ${spacing.md};
  text-align: center;
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.medium};
  padding: ${spacing.md};
  text-decoration: underline;
  border-radius: ${borderRadius.md};

  &:hover {
    background-color: ${color.n30};
    text-decoration: none;
  }
`;

const TripCardContainer = styled.a<{
  $isExpanded?: boolean;
}>`
  display: block;
  text-decoration: none;
  color: ${color.cod};
  font-size: ${fontSize.h6};
  background-color: white;
  border-radius: ${borderRadius.md};
  border: 1px ${color.n40} solid;
  padding: ${spacing.md};

  &:hover {
    cursor: pointer;

    ${SearchTripCardContainer} {
      background-color: ${color.n30};

      span {
        text-decoration: none;
      }
    }
  }

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      background-color: ${color.n10};
      border-color: transparent;
      border-top-left-radius: ${borderRadius.md};
      border-top-right-radius: ${borderRadius.md};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: ${spacing.md};
    `}
`;

export const TripSearchResultsContainer = styled.div<{
  $isDragging?: boolean;
  $hasBackground?: boolean;
}>`
  ${({ $hasBackground }) =>
    $hasBackground &&
    css`
      background-color: ${color.n10};
      padding: ${spacing.md};
      padding-top: 0;
      border-bottom-right-radius: ${borderRadius.md};
      border-bottom-left-radius: ${borderRadius.md};
      border-color: transparent;
    `}
`;
