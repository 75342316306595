import { defineMessages } from "react-intl";

export const messages = defineMessages({
  viewMore: {
    id: "tripPlanner.hotelsCarousel.viewMore",
    description: "View more hotels ",
    defaultMessage: "View more",
  },
  viewAll: {
    id: "tripPlanner.hotelsCarousel.viewAll",
    description: "Opens up all hotel options",
    defaultMessage: "View more",
  },
});
