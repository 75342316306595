import { Skeleton } from "src/components/Skeleton/Skeleton";
import { spacing } from "src/design-system/tokens/spacing";
import { useLayout } from "src/utils/hooks/useLayout";

export default function SimpleRouteCardLoading() {
  const layout = useLayout();
  return (
    <Skeleton
      margin={`${spacing.md} 0 0`}
      borderRadius="xl"
      height={layout === "desktop" ? "50px" : "70px"}
      border
    />
  );
}
