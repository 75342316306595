import { KeyboardEvent, useEffect, useReducer, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { FocusedElement } from "src/FocusContext";
import scrollIntoView from "scroll-into-view-if-needed";
import { useApiConfig } from "../../api/ApiConfigProvider";
import {
  AutocompletePlace,
  AutocompleteResponse,
} from "../../api/AutocompleteResponse";
import { autocompleteEndpoint } from "../../api/endpoints";
import { localeToLanguageCode } from "../conversions/languageCode";
import { specialCanonicalNames } from "./useTimelineTripPointAlternativeNames";

type AutocompleteAction =
  | {
      type: "SET_KNOWN_KIND_RESULTS";
      payload: AutocompletePlace[];
    }
  | {
      type: "SET_UNKNOWN_KIND_RESULTS";
      payload: AutocompletePlace[];
    }
  | {
      type: "CLEAR_RESULTS";
    };

function autocompleteReducer(
  _: AutocompletePlace[],
  action: AutocompleteAction
): AutocompletePlace[] {
  switch (action.type) {
    case "SET_UNKNOWN_KIND_RESULTS":
      return action.payload;
    case "SET_KNOWN_KIND_RESULTS":
      return action.payload.filter(
        (place) => !specialCanonicalNames.includes(place.canonicalName)
      );
    default:
      return [];
  }
}

function useAutocomplete(
  initialQuery?: string,
  initialRequest?: boolean,
  includeUnknownKinds: boolean = true
): {
  results: AutocompletePlace[];
  query: string;
  changeQuery: (query: string, getResults?: boolean) => void;
} {
  const intl = useIntl();
  const config = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const queryClient = useQueryClient();
  const actionType = includeUnknownKinds
    ? "SET_UNKNOWN_KIND_RESULTS"
    : "SET_KNOWN_KIND_RESULTS";
  const [query, setQuery] = useState(initialQuery ?? "");
  const [results, setResults] = useReducer(autocompleteReducer, []);
  // We initialize sendRequest as false so that no initial autocomplete request is sent
  // if an initialQuery has been set.
  const [sendRequest, setSendRequest] = useState(initialRequest);

  // Using mutable state because need access to value without causing infinite loop in useEffect
  const nextRequestNum = useRef(1);
  // Using a ref because we need access to latest updated response num after results have fetched
  const latestResponseNum = useRef(0);

  // This is a workaround that lets us stop updating the state when the component
  // is unmounted. We can't do this kind of cleanup in the autocomplete hook
  // because we rely on the autocomplete fetching promises not being cleaned up
  // so that we can have multiple in-flight requests at once.
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useDeepCompareEffectNoCheck(() => {
    // Ignore whitespace on start and end on query
    const trimmedQuery = query.trim();
    async function fetchAutocompleteResults() {
      const url = autocompleteEndpoint(config, trimmedQuery, languageCode);

      const requestNum = nextRequestNum.current;
      nextRequestNum.current = requestNum + 1;

      try {
        const response = await fetch(url, {
          referrerPolicy: "no-referrer-when-downgrade",
        });
        const json: AutocompleteResponse = await response.json();

        // Only update the results if the incoming request is more recent
        // than the latest received response and the hook is still mounted.
        if (requestNum > latestResponseNum.current && isMounted.current) {
          if (json && json.results) {
            latestResponseNum.current = requestNum;
            setResults({
              type: actionType,
              payload: json.results,
            });
          }
        }
      } catch (e) {
        // TODO: Do we want to send errors to GA when the API gives us an error?
      }
    }

    // If we've been told not to send the request or there's no query then return no results.
    if (!sendRequest || !trimmedQuery) {
      setResults({ type: "CLEAR_RESULTS" });
    } else {
      fetchAutocompleteResults();
    }
  }, [query, languageCode, sendRequest, config]);

  useEffect(() => {
    // We want to cache the places that are returned from autocomplete
    // because this means we don't have to do a geocode for the place if the
    // user clicks on it. So, it saves us an extra network call and it means we
    // can display the place short name immediately.
    if (!!results && results.length > 0) {
      for (const place of results) {
        queryClient.setQueryData(
          ["place", place.canonicalName, languageCode],
          place
        );
      }
    }
  }, [results, languageCode, queryClient]);

  function changeQuery(query: string, getResults: boolean = true) {
    if (!getResults) {
      setSendRequest(false);
      setQuery(query);
    } else {
      setSendRequest(true);
      setQuery(query);
    }
  }

  return {
    results,
    query,
    changeQuery,
  };
}

type UseKeyboardAutocompleteNavigationProps = {
  id: string;
  results: AutocompletePlace[];
  onPressEnter: (place: AutocompletePlace) => void;
  onPressEscape: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressTab?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  scrollContainer?: HTMLElement | null;
};
export function useKeyboardAutocompleteNavigation({
  id,
  results,
  onPressEnter,
  onPressEscape,
  onPressTab,
  scrollContainer,
}: UseKeyboardAutocompleteNavigationProps) {
  const [focusedIndex, setFocusIndex] = useState(0);
  const focusRef = useRef<HTMLLIElement>(null);

  function onFocusChanged(newFocusElement: FocusedElement) {
    setFocusIndex(newFocusElement.index);
    if (focusRef.current) {
      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: focusRef.current.offsetTop - focusRef.current.offsetHeight,
          behavior: "smooth",
        });
      } else {
        scrollIntoView(focusRef.current, {
          block: "center",
          inline: "end",
          behavior: "smooth",
        });
      }
    }
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    switch (event.key) {
      case "ArrowDown":
        if (focusedIndex === results.length) break;
        onFocusChanged({
          id,
          index: focusedIndex + 1,
        });
        break;
      case "ArrowUp":
        // Prevent the cursor position from shifting to the start of the input.
        event.preventDefault();
        if (focusedIndex === 0) break;
        onFocusChanged({
          id,
          index: focusedIndex - 1,
        });
        break;
      case "Enter":
        if (focusedIndex === 0) break;
        onPressEnter(results[focusedIndex - 1]);
        break;
      case "Tab":
        if (focusedIndex === 0) {
          // Reset focus and any typed input
          onPressTab?.();
          break;
        }
        onPressEnter(results[focusedIndex - 1]);
        break;
      case "Escape":
        onPressEscape(event);
        break;
    }
  }

  function resetFocus() {
    setFocusIndex(0);
  }

  return {
    onKeyDown,
    onFocusChanged,
    focusedIndex,
    focusRef,
    resetFocus,
    setFocusIndex,
  };
}

export default useAutocomplete;
