import { destinationPlaceFromRouteOrSegment } from "src/utils/adapters/place";
import { GeocodedPlace } from "src/PrefetchData";
import { useGetRoutes } from "src/utils/hooks/useGetRoutes";

// useMeaningfulPlace can be used in cases where the user enters a country or state where the longitude and latitude are not connected to a city/town
// For example, if the user enters "Australia" as the origin, the lng & lat co-ordinates are in the middle of the country
// This is not a meaningful place as generally there are no roads, towns, cities or hotels nearby
// useMeaningfulPlace converts the GeocodedPlace into location that can be used for hotel search based on the routes
// For example, instead of a location in the middle of Australia, the function returns Sydney as this is the most relevant location based on routes

export function useMeaningfulPlace(origin: GeocodedPlace) {
  const searchResponse = useGetRoutes(origin, origin);
  if (!searchResponse || !searchResponse.data) return;
  return destinationPlaceFromRouteOrSegment(searchResponse.data, 0);
}
