import { useState } from "react";
import styled from "styled-components";
import { DisplayAd } from "src/components/DisplayAd/DisplayAd";
import { borderRadius, color, spacing } from "src/theme";

type Props = {
  flightOriginCode?: string;
  flightDestCode?: string;
};

export function TripInlineSerpAd({ flightOriginCode, flightDestCode }: Props) {
  const [hasAd, setHasAd] = useState(false);

  return (
    <InlineAdWrapper test-id="desktop-inline-serp-ad" hasAd={hasAd}>
      <StyledDisplayAd
        onFilled={() => setHasAd(true)}
        slotConfigId="desktopInlineSerpAd"
        flightOriginCode={flightOriginCode}
        flightDestCode={flightDestCode}
      />
    </InlineAdWrapper>
  );
}

const InlineAdWrapper = styled.div<{ hasAd: boolean }>`
  display: ${({ hasAd }) => (hasAd ? "flex" : "none")};
  background-color: ${color.white};
  padding: 0 6px 6px 15px;
  margin-top: ${spacing.md};
  border: 1px solid ${color.n30};
  border-left: 1px solid ${color.n30};
  border-radius: ${borderRadius.md};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

  cursor: pointer;
  &:hover {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid ${color.n40};
  }
`;

const StyledDisplayAd = styled(DisplayAd)`
  width: 100%;
`;
