import { PropsWithChildren, useState } from "react";
import { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { useActiveTripPlannerTab } from "src/components/Map/TripPlannerMap/util/useActiveTripPlannerTab";
import { useReorderingPlaces } from "src/components/Map/TripPlannerMap/util/useReorderingPlaces";
import { useTripHoveredPlace } from "src/components/Map/TripPlannerMap/util/useTripHoveredPlace";
import { useGetTripRoutes } from "src/utils/hooks/useGetTripRoutes";
import { useLayout } from "src/utils/hooks/useLayout";
import { getRouteIndexFromHash } from "src/utils/location/routeIndexFromHash";
import { MapSegment } from "src/components/Map/mapSegments";
import { GeocodedPlace } from "../../PrefetchData";
import { SchedulesResponse } from "../../api/SchedulesResponse";
import { SearchResponse } from "../../api/SearchResponse";
import { destinationPlaceFromSearch } from "../../utils/adapters/place";
import useExpandedPlaces from "./hooks/useExpandedPlaces";
import { useInteractionMade } from "./hooks/useInteractionMade";
import { useTripPlanSync } from "./hooks/useTripPlanSync";
import { TripPlannerContext } from "./hooks/useTripPlannerContext";
import { useTripPlanningState } from "./hooks/useTripPlanningState";
import { adjustTripPlannerDetailsForNearby } from "./util/adjustTripPlannerDetailsForNearby";
import { createTransportKey } from "./util/createTransportKey";
import {
  PartialSearchResponse,
  getPartialSearchResponse,
} from "./util/getPartialSearchResponse";
import { getPlacePairs } from "./util/getPlacePairs";
import { getPlaceTransportKey } from "./util/getPlaceTransportKey";
import { removeDuplicateConsequential } from "./util/removeDuplicateConsequential";
import { removeTransportWithoutPlaces } from "./util/removeTransportWithoutPlaces";
import { transportKeyHasPlacePair } from "./util/transportKeyHasPlacePair";
import { useTripDestination } from "./hooks/useTripDestination";

export const OVERWRITE_MESSAGE_TIMEOUT = 4000;

export type Action =
  | PassiveUpdateAction
  | SaveAction
  | AddDestinationAction
  | RemoveTransportAction
  | RemovePlaceAction
  | RemoveIDAction
  | ReorderTripAction
  | EditDestinationAction
  | CreateTripFromSearchAction
  | ReplaceTripFromSearchAction
  | EditStartDateAction
  | ClearAction
  | SetTripAction;

type SaveAction = SaveSearchAction | SaveRouteAction | SaveSegmentAction;

type PassiveUpdateAction = {
  type: "PASSIVE_UPDATE";
  trip: TripPlannerDetails;
};

type SaveSearchAction = {
  type: "SAVE_SEARCH";
  searchResponse: SearchResponse;
  url: TripPlannerURL;
  routeIndex?: number;
};

type SaveRouteAction = {
  type: "SAVE_ROUTE";
  searchResponse: SearchResponse;
  url: TripPlannerURL;
};

type SaveSegmentAction = {
  type: "SAVE_SEGMENT";
  searchResponse: SearchResponse;
  url: TripPlannerURL;
  routeIndex: number;
  isRouteSkipped: boolean;
  isSkippedFlight: boolean;
  scheduleResponse?: SchedulesResponse;
};

type AddDestinationAction = {
  type: "ADD_DESTINATION";
  destination: GeocodedPlace;
};

type RemoveTransportAction = {
  type: "REMOVE_TRANSPORT";
  originDestinationKey: TripPlannerTransportKey;
};

type RemovePlaceAction = {
  type: "REMOVE_PLACE";
  index: number;
};

type RemoveIDAction = {
  type: "REMOVE_ID";
};

type ClearAction = {
  type: "CLEAR";
};

type ReorderTripAction = {
  type: "REORDER_TRIP";
  newOrder: SortableObject<GeocodedPlace>[];
};

type EditDestinationAction = {
  type: "EDIT_DESTINATION";
  index: number;
  newPlace: GeocodedPlace;
};

type CreateTripFromSearchAction = {
  type: "CREATE_TRIP_FROM_SEARCH_TRIP";
  places: GeocodedPlace[];
};

type ReplaceTripFromSearchAction = {
  type: "REPLACE_TRIP_FROM_SEARCH_TRIP";
  places: GeocodedPlace[];
};

type EditStartDateAction = {
  type: "EDIT_START_DATE";
  date: Date | undefined;
};

type SetTripAction = {
  type: "SET_TRIP";
  trip: TripPlannerDetails;
};

export type TripPlannerURL = {
  pathname: string;
  hash: string | undefined;
};

export type TripPlannerCardType =
  | "search"
  | "route"
  | "schedule"
  | "segment"
  | "searchPrompt";

export type TripPlannerEntryType = {
  type: TripPlannerCardType;
  url: TripPlannerURL;
  searchResponse: SearchResponse | PartialSearchResponse;
  scheduleResponse?: SchedulesResponse;
  selectedRouteIndex?: number;
};

export type PlaceIdentifier = GeocodedPlace["canonicalName"];
export type TripPlannerTransportKey = `${PlaceIdentifier}_${PlaceIdentifier}`;

export type TripPlannerDetails = {
  id?: string;
  slug?: string;
  name?: string;
  places: GeocodedPlace[];
  startDate?: string;
  transport: {
    [key: TripPlannerTransportKey]: TripPlannerEntryType | undefined;
  };
  accomodation?: {
    [key: PlaceIdentifier]:
      | {
          type: "suggestion" | "booked" | "saved";
          data: any; // AccomodationCardViewModel
        }[];
  };
  attraction?: {
    [key: PlaceIdentifier]:
      | {
          type: "suggestion" | "saved";
          data: any; // AttractionCardViewModel
        }[];
  };
};

export type HoveredRecommendation = {
  hoverId: number | undefined;
  isPaneHover: boolean;
  mapSegment?: MapSegment[];
};

export function TripPlannerProvider(props: PropsWithChildren<{}>) {
  const { setHoveredPlaceIndex, hoveredPlaceIndex } = useTripHoveredPlace();
  const { activeTripPlannerTab, setActiveTripPlannerTab } =
    useActiveTripPlannerTab();
  const { reorderingPlaces, setReorderingPlaces } = useReorderingPlaces();
  const tripPlanningState = useTripPlanningState();
  const tripInteraction = useInteractionMade();
  const isDesktop = useLayout() === "desktop";
  const [hoveredRecommendation, setHoveredRecommendation] =
    useState<HoveredRecommendation>({
      hoverId: undefined,
      isPaneHover: false,
    });

  const { fetchState, tripPlannerDetails, dispatch, history } =
    useTripPlanSync();

  const tripRoutes = useGetTripRoutes(getPlacePairs(tripPlannerDetails[0]));
  const { expandedPlaces, dispatchExpandedPlaces } = useExpandedPlaces(
    tripPlannerDetails[0]
  );
  const tripDestination = useTripDestination({
    tripPlannerDetails: tripPlannerDetails[0],
    dispatch,
    dispatchExpandedPlaces,
    tripPlanningState,
  });

  const updatedTripPlannerDetails = adjustTripPlannerDetailsForNearby(
    tripRoutes.queries,
    tripPlannerDetails[0]
  );

  const isMultiTrip = tripPlannerDetails[0].places.length > 2;

  return (
    <TripPlannerContext.Provider
      value={{
        tripRoutes,
        tripPlannerDetails: updatedTripPlannerDetails,
        dispatch,
        hoveredPlaceIndex,
        setHoveredPlaceIndex,
        tripPlanningState,
        apiState: {
          fetchState,
        },
        reorderingPlaces,
        setReorderingPlaces,
        history: isDesktop ? history : undefined,
        activeTripPlannerTab,
        setActiveTripPlannerTab,
        tripInteraction,
        expandedPlaces,
        dispatchExpandedPlaces,
        hoveredRecommendation,
        setHoveredRecommendation,
        tripDestination,
        isMultiTrip,
      }}
      {...props}
    />
  );
}

export function tripPlannerActionReducer(
  state: TripPlannerDetails[],
  action: Action
): TripPlannerDetails[] {
  let result: TripPlannerDetails;

  switch (action.type) {
    case "PASSIVE_UPDATE":
    case "SET_TRIP":
      result = action.trip;
      break;
    case "SAVE_SEARCH":
      result = reduceSaveSearchAction(state[0], action);
      break;
    case "SAVE_ROUTE":
      result = reduceSaveRouteAction(state[0], action);
      break;
    case "SAVE_SEGMENT":
      result = reduceSaveSegmentAction(state[0], action);
      break;
    case "ADD_DESTINATION":
      result = reduceAddDestinationAction(state[0], action);
      break;
    case "REMOVE_TRANSPORT":
      result = reduceRemoveTransportAction(state[0], action);
      break;
    case "REMOVE_PLACE":
      result = reduceRemovePlaceAction(state[0], action);
      break;
    case "REORDER_TRIP":
      result = reorderTripAction(state[0], action);
      break;
    case "EDIT_DESTINATION":
      result = editDestinationAction(state[0], action);
      break;
    case "REMOVE_ID":
      result = reduceRemoveIDAction(state[0], action);
      break;
    case "CREATE_TRIP_FROM_SEARCH_TRIP":
      result = reduceCreateTripFromSearchAction(state[0], action);
      break;
    case "REPLACE_TRIP_FROM_SEARCH_TRIP":
      result = reduceReplaceTripFromSearchAction(state[0], action);
      break;
    case "EDIT_START_DATE":
      result = reduceEditStartDateAction(state[0], action);
      break;
    case "CLEAR":
      result = {
        places: [],
        transport: {},
      };
      break;
  }

  return [result];
}

function reduceSaveSearchAction(
  state: TripPlannerDetails,
  action: SaveSearchAction
): TripPlannerDetails {
  // First two places in search response **always** have a canonical name
  const origin = action.searchResponse.places[0].canonicalName as string;
  const destination = action.searchResponse.places[1].canonicalName as string;

  const transportKey = createTransportKey(origin, destination);

  const newPlaces = addPlaces(action, state);

  const selectedRouteIndex = action.routeIndex;

  return {
    ...state,
    places: newPlaces,
    transport: {
      ...state.transport,
      [transportKey]: {
        type: "search",
        url: action.url,
        searchResponse: getPartialSearchResponse(
          action.searchResponse,
          selectedRouteIndex ?? 0
        ),
        selectedRouteIndex: selectedRouteIndex,
      },
    },
  };
}

function reduceSaveRouteAction(
  state: TripPlannerDetails,
  action: SaveRouteAction
): TripPlannerDetails {
  // First two places in search response **always** have a canonical name
  const origin = action.searchResponse.places[0].canonicalName as string;
  const destination = action.searchResponse.places[1].canonicalName as string;

  const transportKey = createTransportKey(origin, destination);
  const routeIndex = getRouteIndexFromHash(
    action.url.hash,
    action.searchResponse
  );

  const newPlaces = addPlaces(action, state);

  return {
    ...state,
    places: newPlaces,
    transport: {
      ...state.transport,
      [transportKey]: {
        type: "route",
        url: action.url,
        searchResponse: getPartialSearchResponse(
          action.searchResponse,
          routeIndex ?? 0
        ),
        selectedRouteIndex: routeIndex,
      },
    },
  };
}

function reduceSaveSegmentAction(
  state: TripPlannerDetails,
  action: SaveSegmentAction
): TripPlannerDetails {
  // First two places in search response **always** have a canonical name
  const origin = action.searchResponse.places[0].canonicalName as string;
  const destination = action.searchResponse.places[1].canonicalName as string;

  const transportKey = createTransportKey(origin, destination);
  const newTransport = { ...state.transport };

  /**
   * If a user saves a multi-part segment, we disregard that segment/schedule and
   * save the route information to display. We also overwrite the URL so when they
   * click that card on the trip planner, we navigate them back to the route screen.
   */
  let navigateToRouteScreen: boolean;
  const parts = action.url.hash?.split("/s/");
  const isSingleSegment =
    action.searchResponse.routes[action.routeIndex].segments.length === 1;

  if (
    !action.isRouteSkipped &&
    parts &&
    parts.length >= 1 &&
    !isSingleSegment &&
    !action.isSkippedFlight
  ) {
    navigateToRouteScreen = true;
  } else {
    navigateToRouteScreen = false;
  }

  const partialSearchResponse = getPartialSearchResponse(
    action.searchResponse,
    action.routeIndex
  );

  if (parts && navigateToRouteScreen && partialSearchResponse) {
    newTransport[transportKey] = {
      type: "route",
      url: {
        pathname: action.url.pathname,
        hash: parts[0],
      },
      searchResponse: partialSearchResponse,
      selectedRouteIndex: action.routeIndex,
    };
  } else if (action.scheduleResponse && partialSearchResponse) {
    newTransport[transportKey] = {
      type: "schedule",
      url: {
        pathname: action.url.pathname,
        hash: action.url.hash,
      },
      searchResponse: partialSearchResponse,
      scheduleResponse: action.scheduleResponse,
      selectedRouteIndex: action.routeIndex,
    };
  } else if (partialSearchResponse) {
    newTransport[transportKey] = {
      type: "segment",
      url: {
        pathname: action.url.pathname,
        hash: action.url.hash,
      },
      searchResponse: partialSearchResponse,
      selectedRouteIndex: action.routeIndex,
    };
  }

  const newPlaces = addPlaces(action, state);

  return {
    ...state,
    places: newPlaces,
    transport: newTransport,
  };
}

function reduceAddDestinationAction(
  state: TripPlannerDetails,
  action: AddDestinationAction
): TripPlannerDetails {
  let newPlaces: GeocodedPlace[] = [...state.places];

  // Append our new destination
  newPlaces.push(action.destination);

  return {
    ...state,
    places: newPlaces,
  };
}

export function reduceRemoveTransportAction(
  state: TripPlannerDetails,
  action: RemoveTransportAction
) {
  let newTransport: TripPlannerDetails["transport"] = { ...state.transport };

  if (newTransport.hasOwnProperty(action.originDestinationKey)) {
    delete newTransport[action.originDestinationKey];
  }

  return {
    ...state,
    transport: newTransport,
  };
}

function reduceRemovePlaceAction(
  state: TripPlannerDetails,
  action: RemovePlaceAction
): TripPlannerDetails {
  let newPlaces: TripPlannerDetails["places"] = [...state.places];

  // If there's only one place, remove it
  if (newPlaces.length < 2) {
    newPlaces = [];
  }

  newPlaces = deletePlacePair(newPlaces, action.index);
  const safePlaces = removeDuplicateConsequential(newPlaces, "canonicalName");

  const newTransport = removeTransportWithoutPlaces(
    state.transport,
    safePlaces
  );

  return {
    ...state,
    places: safePlaces,
    transport: newTransport,
  };
}

export function reorderTripAction(
  state: TripPlannerDetails,
  action: ReorderTripAction
): TripPlannerDetails {
  // If there are no places
  // we shouldn't keep the transport
  if (action.newOrder.length < 1) {
    return {
      ...state,
      places: [],
      transport: {},
    };
  }

  // remove duplicate following places
  const newOrder = removeDuplicateConsequential(
    action.newOrder,
    "canonicalName"
  );

  // remove id and content properties from places
  const newPlaces: GeocodedPlace[] = newOrder.map((place) => {
    const { id, content, ...rest } = place;
    return {
      ...rest,
    };
  });

  const newTransport = removeTransportWithoutPlaces(state.transport, newPlaces);

  return {
    ...state,
    places: newPlaces,
    transport: newTransport,
  };
}

export function reduceCreateTripFromSearchAction(
  state: TripPlannerDetails,
  action: CreateTripFromSearchAction
): TripPlannerDetails {
  // If there are no places
  // we shouldn't keep the transport
  if (action.places.length < 1) {
    return {
      ...state,
      places: [],
      transport: {},
    };
  }

  // remove duplicate following places
  const newPlaces = removeDuplicateConsequential(
    [...action.places],
    "canonicalName"
  );

  return {
    ...state,
    // Removing the ID ensures a new trip plan is initialized on the server.
    id: undefined,
    places: newPlaces,
  };
}

export function reduceReplaceTripFromSearchAction(
  state: TripPlannerDetails,
  action: ReplaceTripFromSearchAction
): TripPlannerDetails {
  // If there are no places
  // we shouldn't keep the transport
  if (action.places.length < 1) {
    return {
      ...state,
      places: [],
      transport: {},
    };
  }

  // remove duplicate following places
  const newPlaces = removeDuplicateConsequential(
    [...action.places],
    "canonicalName"
  );

  return {
    ...state,
    places: newPlaces,
  };
}

function reduceRemoveIDAction(
  state: TripPlannerDetails,
  action: RemoveIDAction
): TripPlannerDetails {
  return {
    ...state,
    id: undefined,
  };
}

function reduceEditStartDateAction(
  state: TripPlannerDetails,
  action: EditStartDateAction
) {
  return {
    ...state,
    startDate: action.date ? action.date.toISOString() : undefined,
  };
}

function editDestinationAction(
  state: TripPlannerDetails,
  action: EditDestinationAction
): TripPlannerDetails {
  const { index, newPlace } = action;

  const newPlaces = [...state.places];
  newPlaces[index] = newPlace as GeocodedPlace;

  // Remove duplicate following places
  const safePlaces = removeDuplicateConsequential(newPlaces, "canonicalName");
  const newTransport = removeTransportWithoutPlaces(
    state.transport,
    safePlaces
  );

  return {
    ...state,
    places: safePlaces,
    transport: newTransport,
  };
}

function addPlaces(action: SaveAction, state: TripPlannerDetails) {
  let newPlaces: GeocodedPlace[] = [...state.places];

  if (!getActionNeedsPlaces(action, state)) return newPlaces;

  if (isOriginSaved(state.places, action.searchResponse)) {
    // Add just the destination
    const destination = destinationPlaceFromSearch(action.searchResponse);
    newPlaces.push(destination as GeocodedPlace);
  } else {
    // Add both the origin and destination
    newPlaces.push(action.searchResponse.places[0] as GeocodedPlace);
    newPlaces.push(action.searchResponse.places[1] as GeocodedPlace);
  }

  return newPlaces;
}

function getActionNeedsPlaces(action: SaveAction, state: TripPlannerDetails) {
  const transportKey = getPlaceTransportKey(
    0,
    action.searchResponse.places as GeocodedPlace[]
  );

  if (transportKey) {
    return (
      !transportKeyHasPlacePair(state.places, transportKey) &&
      !state.transport.hasOwnProperty(transportKey)
    );
  }

  return true;
}

function isOriginSaved(
  places: GeocodedPlace[],
  searchResponse: SearchResponse
) {
  return (
    places.length > 0 &&
    places[places.length - 1]?.canonicalName ===
      searchResponse.places[0].canonicalName
  );
}

export function userOverwritten(
  tripPlannerDetails: TripPlannerDetails,
  canonicalPair: TripPlannerTransportKey
) {
  let hasUserOverwritten = false;

  if (tripPlannerDetails.transport?.hasOwnProperty(canonicalPair)) {
    hasUserOverwritten = true;
  }

  return hasUserOverwritten;
}

function deletePlacePair(
  places: TripPlannerDetails["places"],
  index: number
): GeocodedPlace[] {
  if (places.length === 0) return [];
  const newPlaces = [...places];
  newPlaces.splice(index, 1);
  return newPlaces;
}
