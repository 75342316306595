import { SearchResponse } from "../../../../api/SearchResponse";
import { distanceFromSegment } from "../../../../utils/adapters/distance";
import {
  destinationPlaceFromSegment,
  originPlaceFromSegment,
} from "../../../../utils/adapters/place";
import { vehicleFromSegment } from "../../../../utils/adapters/vehicle";
import { distanceBetween } from "../../../../utils/distanceBetween";

export function transferCellFromSegment(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  isInterchange?: boolean
): TransferCell {
  return transferCellFromOption(
    response,
    routeIndex,
    segmentIndex,
    isInterchange
  );
}

function transferCellFromOption(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  isInterchange?: boolean
): TransferCell {
  const route = response.routes[routeIndex];

  // Precondition
  if (route.segments.length <= 0) {
    throw Error(`route.segments.length <= 0`);
  }

  // Precondition: must have a segment at segmentIndex + 1
  if (segmentIndex + 1 > route.segments.length) {
    throw Error(`segmentIndex + 1 <= route.segments.length`);
  }

  const segmentLookupIndex = route.segments[segmentIndex];
  const segment = response.segments[segmentLookupIndex];
  const hasSegmentData =
    vehicleFromSegment(response, segment).kind === "transfer";

  // We can pull distance information directly from a transfer segment and it's hops.
  // However there does not appear to be duration info for a transfer segment, so we
  // cannot use the helper function durationInMinutesFromSegment.
  let distanceInMeters = 0;

  if (hasSegmentData) {
    distanceInMeters =
      distanceFromSegment(response, routeIndex, segmentIndex) ?? 0;
  } else {
    // To display an interchange as a transfer segment, we need to get the current
    // segment's destination, and the next segment's origin.
    const prevPlace = destinationPlaceFromSegment(
      response,
      route.segments[segmentIndex]
    );
    const nextPlace = originPlaceFromSegment(
      response,
      route.segments[segmentIndex + 1]
    );
    distanceInMeters = distanceBetween(prevPlace, nextPlace);
  }

  // We need to consider crowded stations and slower walkers when defining a walking speed.
  // 1.35 metres/sec seems to be the magic number we use in our backend data.
  const walkingSpeed = 80;
  const durationInMinutes = distanceInMeters / walkingSpeed;

  return {
    type: "transfer",
    id: `transfer-${routeIndex}-${segmentIndex}`,
    distanceInMeters: Math.round(distanceInMeters),
    durationInMinutes: Math.round(durationInMinutes),
    label: isInterchange ? "change" : "transfer",
  };
}

export type TransferCell = {
  type: "transfer";
  id: string;
  distanceInMeters: number;
  durationInMinutes: number;
  label: "change" | "transfer";
};
