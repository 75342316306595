import {
  DragUpdate,
  DropResult,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { SortableObject } from "./DraggableItem";

export function useDragAndDropList<T>(
  initialItems: SortableObject<T>[],
  onChange: (items: SortableObject<T>[]) => void,
  isCustomItem?: boolean,
  onDragEnd?: () => void
) {
  const [accessibilityFeature, setAccessibilityFeature] = useState(false);
  const [items, setItems] = useState<SortableObject<T>[]>(initialItems);
  const [movingUp, setMovingUp] = useState<number>();
  const [movingDown, setMovingDown] = useState<number>();

  function handleDragEnd(result: DropResult) {
    if (onDragEnd) {
      onDragEnd();
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  }

  function handleMoveDown(index: number) {
    const newItems = reorder(items, index, index + 1);
    setItems(newItems);
  }

  function handleMoveUp(index: number) {
    const newItems = reorder(items, index, index - 1);
    setItems(newItems);
  }

  function handleRemove(index: number) {
    const newItems = Array.from(items);
    newItems.splice(index, 1);
    setItems(newItems);
  }

  function handleAccessibilityFeatureChange() {
    setAccessibilityFeature(!accessibilityFeature);
  }

  useEffect(() => {
    onChange(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useDeepCompareEffect(() => {
    if (isCustomItem) {
      setItems(initialItems);
    }
  }, [initialItems]);

  return {
    handleDragEnd,
    handleMoveDown,
    handleMoveUp,
    handleRemove,
    handleAccessibilityFeatureChange,
    setMovingUp,
    setMovingDown,
    accessibilityFeature,
    items,
    movingUp,
    movingDown,
  };
}

// a little function to help us with reordering the result
export function reorder<T>(
  list: SortableObject<T>[],
  startIndex: number,
  endIndex: number
): SortableObject<T>[] {
  if (startIndex < 0 || endIndex < 0 || startIndex >= list.length) {
    return list;
  }
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function getMoving(
  index: number,
  movingUp: number | undefined,
  movingDown: number | undefined
) {
  if (index === movingUp) {
    return "up";
  }
  if (index === movingDown) {
    return "down";
  }
}

export function isWithinRangeOfReorder(
  index: number,
  sourceIndex?: number,
  dropIndex?: number
) {
  if (dropIndex === undefined) {
    return false;
  }
  if (sourceIndex === index + 1 || sourceIndex === index - 1) {
    return true;
  }
  if (index === dropIndex || index === dropIndex - 1) {
    return true;
  }
  return false;
}

export function getDragChanges(
  index: number,
  dragStatus?: DragUpdate,
  dropSnapshot?: DroppableStateSnapshot
) {
  const isDraggingThis =
    dragStatus?.source?.index === index && dropSnapshot?.isDraggingOver;
  const isDraggedItemInNewPosition =
    dragStatus?.source?.index !== dragStatus?.destination?.index;
  const isEffectedByDrag =
    isDraggedItemInNewPosition &&
    isWithinRangeOfReorder(
      index,
      dragStatus?.source?.index,
      dragStatus?.destination?.index
    );

  return {
    isDraggingThis,
    isEffectedByDrag,
    isDragEnding:
      isEffectedByDrag && dropSnapshot && !dropSnapshot.isDraggingOver,
  };
}
