import styled from "styled-components";
import { Icon } from "../../components/Icon/Icon";
import { Star } from "../../svg/Star";

type Props = {
  starRating?: number;
  iconSize?: "xs" | "sm";
};
export default function StarRating({ starRating, iconSize = "sm" }: Props) {
  const ratingToArray =
    starRating && starRating > 0
      ? Array(starRating).fill(undefined)
      : undefined;

  return (
    <>
      {ratingToArray && (
        <StarsDiv>
          {ratingToArray?.map((_, i) => {
            return (
              <Icon size={iconSize} key={i}>
                <Star title="star" tint="yellow" />
              </Icon>
            );
          })}
        </StarsDiv>
      )}
    </>
  );
}

const StarsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
