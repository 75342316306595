import { useIntl } from "react-intl";
import styled from "styled-components";
import { spacing } from "src/theme";
import { primitive } from "src/design-system/tokens/color";
import { border_radius } from "src/design-system/tokens/border";
import { useFeature } from "src/feature/useFeature";
import { SupportedLanguageCode } from "src/utils/language";
import { SearchResponse } from "src/api/SearchResponse";
import { Geocoded } from "src/PrefetchData";
import { useHoveredIndex } from "../../../../HoveredIndexProvider";
import { localeToLanguageCode } from "../../../../utils/conversions/languageCode";
import useSearch, { SearchPlace } from "../../../../utils/hooks/useSearch";
import useTimelineTripPointAlternativeNames, {
  TripPointAlternativeNames,
} from "../../../../utils/hooks/useTimelineTripPointAlternativeNames";
import { TroniconTimelineAccommodationPromo } from "../../Shared/TroniconTimelineAccommodationPromo";
import { TroniconTimelineDestination } from "../Shared/TroniconTimelineDestination";
import { TroniconTimelineOrigin } from "../Shared/TroniconTimelineOrigin";
import {
  createGroupedTimelineCellsFromRoute,
  createTimelineCellsFromRoute,
} from "../cell/createTimelineCells";
import { CompactDottedTimeline } from "../../Timeline";
import { TroniconTimelineInterchange } from "../Shared/TroniconTimelineInterchange";
import { TroniconTimelineDetail } from "./TroniconTimelineDetail";
import { TroniconTimelineTransfer } from "./TroniconTimelineTransfer";
import { TroniconTimelineJoint } from "./TroniconTimelineJoint";

type Props = {
  context: "transport" | "tripPlanner";
  routeIndex: number;
  onDetailClick?: (segmentIndex: number) => void;
};

export function TroniconRouteTimeline({
  context,
  routeIndex,
  onDetailClick,
}: Props) {
  const { setHoveredSegmentIndex } = useHoveredIndex();
  const { searchResponse, destination } = useSearch();
  const tripPointAlternatives = useTimelineTripPointAlternativeNames(
    routeIndex,
    undefined,
    true
  );

  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const isNewRoutePane = useFeature("NewRoutePane");

  if (!searchResponse) {
    return null;
  }

  if (isNewRoutePane) {
    return (
      <VariantTroniconRouteTimeline
        {...{
          context,
          searchResponse,
          destination,
          routeIndex,
          onDetailClick,
          languageCode,
          tripPointAlternatives,
          setHoveredSegmentIndex,
        }}
      />
    );
  }

  const cells = createTimelineCellsFromRoute({
    response: searchResponse,
    routeIndex,
    tripPointAlternatives,
    languageCode,
    destination,
  });

  return (
    <TimelineContainer>
      {cells.map((cell) => {
        switch (cell.type) {
          case "origin":
            return <TroniconTimelineOrigin key={cell.id} cell={cell} />;
          case "destination":
            return <TroniconTimelineDestination key={cell.id} cell={cell} />;
          case "detail":
            return (
              <TroniconTimelineDetail
                context={context}
                key={cell.id}
                cell={cell}
                onClick={onDetailClick}
                onHover={setHoveredSegmentIndex}
              />
            );
          case "interchange":
            return <TroniconTimelineInterchange key={cell.id} cell={cell} />;
          case "transfer":
            return <TroniconTimelineTransfer key={cell.id} cell={cell} />;
          case "accommodation":
            return (
              <TroniconTimelineAccommodationPromo
                key={cell.id}
                cell={cell}
                loggingFrom="Route"
                showTransfer={true}
              />
            );
          default:
            throw Error(`Unrecognised Cell Type: ${JSON.stringify(cell)}`);
        }
      })}
    </TimelineContainer>
  );
}

type VariantProps = {
  searchResponse: SearchResponse;
  destination: Geocoded | SearchPlace | undefined;
  languageCode: SupportedLanguageCode;
  tripPointAlternatives: TripPointAlternativeNames;
  setHoveredSegmentIndex: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
} & Props;

export function VariantTroniconRouteTimeline({
  context,
  routeIndex,
  onDetailClick,
  searchResponse,
  destination,
  languageCode,
  tripPointAlternatives,
  setHoveredSegmentIndex,
}: VariantProps) {
  const groupedTimeline = createGroupedTimelineCellsFromRoute({
    response: searchResponse,
    routeIndex,
    tripPointAlternatives,
    languageCode,
    destination,
  });

  return (
    <VariantTimelineContainer>
      {groupedTimeline.map(
        ({ cells, origin, destination, isJoint, isInteractive }, i) => {
          return (
            <GroupedContainer $isJoint={isJoint || !isInteractive} key={i}>
              <GroupedContent
                $isInteractable={isInteractive}
                $isJoint={isJoint}
              >
                {cells.map((cell) => {
                  switch (cell.type) {
                    case "origin":
                      return (
                        <GroupedTroniconTimelineOrigin
                          key={cell.id}
                          cell={cell}
                          isOriginTimelineHidden={cell.transitMode === "walk"}
                          $isStandalone={cells.length === 1}
                        />
                      );
                    case "destination":
                      return (
                        <GroupedTroniconTimelineDestination
                          key={cell.id}
                          cell={cell}
                          $isStandalone={cells.length === 1}
                        />
                      );
                    case "detail":
                      return (
                        <GroupedTroniconTimelineDetail
                          context={context}
                          key={cell.id}
                          cell={cell}
                          onClick={onDetailClick}
                          onHover={setHoveredSegmentIndex}
                          $isStandalone={cells.length === 1}
                          timeline={
                            cell.transitMode === "walk" ? (
                              <CompactDottedTimeline />
                            ) : null
                          }
                          isCompact={cell.transitMode === "walk"}
                          origin={origin}
                          destination={destination}
                        />
                      );
                    case "interchange":
                      return null;
                    case "transfer":
                      return (
                        <GroupedTroniconTimelineTransfer
                          key={cell.id}
                          cell={cell}
                        />
                      );
                    case "joint":
                      return <TroniconTimelineJoint key={cell.id} />;
                    case "accommodation":
                      return (
                        <GroupedTroniconTimelineAccommodationPromo
                          key={cell.id}
                          cell={cell}
                          loggingFrom="Route"
                        />
                      );
                    default:
                      throw Error(
                        `Unrecognised Cell Type: ${JSON.stringify(cell)}`
                      );
                  }
                })}
              </GroupedContent>
            </GroupedContainer>
          );
        }
      )}
    </VariantTimelineContainer>
  );
}

const TimelineContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const VariantTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.xl} 20px ${spacing.xxxl};
  background-color: ${primitive.neutral_400};
  gap: ${spacing.md};
`;

const GroupedContainer = styled.div<{ $isJoint?: boolean }>`
  background-color: ${(props) =>
    props.$isJoint ? "unset" : primitive.neutral_100};
  border-radius: ${border_radius.rounded_lg};
  padding: ${(props) => (props.$isJoint ? `0 ${spacing.md}` : spacing.md)};
`;

const GroupedContent = styled.div<{
  $isInteractable?: boolean;
  $isJoint?: boolean;
}>`
  position: relative;
  border-radius: ${border_radius.rounded_md};
  transition: background-color 0.05s ease-in-out;
  :hover {
    background-color: ${(props) =>
      props.$isInteractable
        ? props.$isJoint
          ? primitive.neutral_700
          : primitive.neutral_300
        : undefined};
  }
`;

const GroupedTroniconTimelineOrigin = styled(TroniconTimelineOrigin)<{
  $isStandalone?: boolean;
}>`
  margin: 0;
  padding: ${spacing.md};
  padding-bottom: ${(props) => (props.$isStandalone ? spacing.md : 0)};
`;

const GroupedTroniconTimelineDestination = styled(TroniconTimelineDestination)<{
  $isStandalone?: boolean;
}>`
  margin: 0;
  padding: ${spacing.md};
  padding-top: ${(props) => (props.$isStandalone ? spacing.md : 0)};
`;
const GroupedTroniconTimelineDetail = styled(TroniconTimelineDetail)<{
  $isStandalone?: boolean;
}>`
  margin: 0;
  padding: 0 ${spacing.md};
`;

const GroupedTroniconTimelineTransfer = styled(TroniconTimelineTransfer)`
  margin: 0;
  padding: 0 ${spacing.md};
`;

const GroupedTroniconTimelineAccommodationPromo = styled(
  TroniconTimelineAccommodationPromo
)`
  margin: 0;
  padding: ${spacing.md};
`;
