import markerSDK, { MarkerSdk } from "@marker.io/browser";
import { useEffect, useRef, useState } from "react";
import { useFeatureConfig } from "src/feature/useFeatureConfig";
import { FeatureName } from "src/feature/FeatureConfig";
import { useGetPermaLink } from "./useGetPermaLink";

type MarkerFeature = {
  featureName: FeatureName;
  markerId: string;
};

export function useMarker() {
  const marker = useRef<MarkerSdk | undefined>();
  const permalink = useGetPermaLink().href;
  const frontendFeatures = useFeatureConfig();
  const [projectId, setProjectId] = useState<string>("");

  useEffect(() => {
    // Add a frontend feature to markerProjects along with
    // an active Marker.io project ID. When that feature is
    // active in the browser, it will trigger the Marker.io widget
    // for the Marker.io project associated with that feature.
    // Be selective with which feature name you associate
    // with a Marker.io project, as only the first match will
    // be used to trigger the form widget in the browser.
    const markerProjects: MarkerFeature[] = [];

    let currentProjectId = "";

    for (let markerProject of markerProjects) {
      if (frontendFeatures[markerProject.featureName]) {
        currentProjectId = markerProject.markerId;
        break;
      }
    }

    setProjectId(currentProjectId);

    marker.current?.setCustomData({
      permalink,
    });

    // The permalink will always update if a FE feature changes
    // so we just listen to the permalink as the dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permalink]);

  useEffect(() => {
    async function loadMarker() {
      // Initialise Marker script
      return await markerSDK.loadWidget({
        project: projectId,
        customData: {
          permalink,
        },
      });
    }

    if (projectId && marker.current === undefined) {
      loadMarker()
        .then((widget) => {
          marker.current = widget;
        })
        .catch((error) => console.warn(error));
    }

    if (projectId) {
      marker.current?.show();
    } else {
      marker.current?.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
}
