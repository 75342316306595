import { useEffect, useState } from "react";
import { useIsMutating } from "react-query";
import { useHotjar } from "src/utils/hooks/useHotjar";

/**
 * This hook is responsible for recognizing when the user has made a meaningful interaction with the trip planner.
 */
export function useInteractionMade() {
  const fireHotjarEvent = useHotjar({
    id: "tripPlannerInteraction",
    layout: "desktop",
  });
  const [isInteractionMade, setIsInteractionMade] = useState(false);
  const isTripPlanMutated = useIsMutating("tripPlan");

  useEffect(() => {
    if (isTripPlanMutated) {
      setIsInteractionMade(true);
      fireHotjarEvent();
    }
  }, [isTripPlanMutated, setIsInteractionMade, fireHotjarEvent]);

  return {
    isInteractionMade,
    setIsInteractionMade,
  };
}
