import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { SwitchLight } from "src/svg/SwitchLight";
import styled, { css } from "styled-components";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { Icon } from "../../../components/Icon/Icon";
import { Pin } from "../../../svg/Pin";
import { color, fontSize, spacing } from "../../../theme";
import { useTheme } from "../../../utils/hooks/useTheme";
import messages from "./SearchBox.messages";

type Props = {
  origin?: string;
  destination?: string;
  onSwitchClick: () => void;
  onOriginClick: () => void;
  onDestinationClick: () => void;
};

export function SearchBox(props: Props) {
  const intl = useIntl();
  const theme = useTheme();
  const iconColor: keyof typeof color = theme.searchBar.input.iconTint;

  return (
    <Search>
      <Wrapper>
        <InputWrapper
          onClick={(event) => {
            props.onOriginClick();
            sendAnalyticsInteractionEvent("SearchBox", "Click:Origin");
            event.preventDefault();
          }}
          addDestinationInSearch
          isOrigin
        >
          <StyledOrigin iconColor={color[iconColor]} />
          <StyledInput
            aria-label={intl.formatMessage(messages.origin)}
            id="origin"
            value={props.origin ?? ""}
            readOnly
            addDestinationInSearch
          />
        </InputWrapper>
        <SwitchWrapper
          onClick={(event) => {
            props.onSwitchClick();
            sendAnalyticsInteractionEvent("SearchBox", "Click:Switch");
            event.preventDefault();
          }}
          aria-label={intl.formatMessage(messages.switch)}
          addDestinationInSearch
        >
          <Icon size="xxxxl">
            <SwitchLight title="switch" tint="n90" />
          </Icon>
        </SwitchWrapper>
        <InputWrapper
          onClick={(event) => {
            props.onDestinationClick();
            sendAnalyticsInteractionEvent("SearchBox", "Click:Destination");
            event.preventDefault();
          }}
          addDestinationInSearch
        >
          <StyledPinIcon size="md">
            <Pin title="pin" tint={theme.searchBar.input.iconTint} />
          </StyledPinIcon>
          <StyledInput
            aria-label={intl.formatMessage(messages.destination)}
            id="destination"
            value={props.destination ?? ""}
            readOnly
            addDestinationInSearch
          />
        </InputWrapper>
      </Wrapper>
    </Search>
  );
}

export const Search = styled.div`
  background-color: ${(props) => props.theme.searchBar.background};
  padding: ${spacing.md};
`;

export const Wrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div<{
  addDestinationInSearch: boolean;
  isOrigin?: boolean;
}>`
  position: relative;
  margin: 2px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  ${({ addDestinationInSearch, isOrigin }) =>
    addDestinationInSearch &&
    css`
      box-shadow: unset;
      border: 1px solid ${color.n30};
      margin: 0;
      overflow: hidden;
      border-radius: ${isOrigin
        ? `${spacing.sm} ${spacing.sm} 0 0`
        : `0 0 ${spacing.sm} ${spacing.sm}`};
      border-bottom: ${isOrigin && "none"};
    `}
`;

const StyledInput = styled.input<{ addDestinationInSearch: boolean }>`
  background: ${(props) => props.theme.searchBar.input.background};
  display: block;
  width: 100%;
  border: none;
  color: ${(props) => props.theme.searchBar.input.text};
  font-size: ${fontSize.body};
  padding: 13px ${spacing.md} 13px 40px;
  text-overflow: ellipsis;
  border-radius: ${spacing.sm};

  ${({ addDestinationInSearch }) =>
    addDestinationInSearch &&
    css`
      padding: 13px ${spacing.md} 13px 64px;
      height: 48px;
    `}

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
    -webkit-tap-highlight-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
  }
`;

const SwitchWrapper = styled(ButtonBase)<{ addDestinationInSearch: boolean }>`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.searchBar.switchButton.background};
  border: 2px solid ${(props) => props.theme.searchBar.switchButton.border};
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  right: ${spacing.xl};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ addDestinationInSearch }) =>
    addDestinationInSearch &&
    css`
      border: none;
      transform: translateY(-50%);
    `}

  &:active {
    background-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
    -webkit-tap-highlight-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
  }
  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
  }
`;

const StyledPinIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${spacing.lg};
  margin-top: -1px;
`;

const StyledOrigin = styled.span<{ iconColor: string }>`
  &:before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: transparent;
    position: absolute;
    border-radius: 100%;
    border: 1.5px solid ${(props) => props.iconColor};
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
`;
