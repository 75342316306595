import { useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "src/components/Button/Button";
import { ConfirmationModal } from "src/components/ConfirmationModal/ConfirmationModal";
import { useLayout } from "src/utils/hooks/useLayout";
import { TripPlannerTransportKey } from "../TripPlannerProvider";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import messages from "./ReorderTrip.messages";

type UpdateButtonProps = {
  handleUpdate: () => void;
  hasChanges: boolean;
};

export function UpdateButton({ handleUpdate, hasChanges }: UpdateButtonProps) {
  const intl = useIntl();
  const isMobile = useLayout() === "mobile";
  const [showModal, setShowModal] = useState<boolean>(false);

  const { tripPlannerDetails } = useTripPlannerContext();

  let hasSavedTransport = false;
  for (const key in tripPlannerDetails.transport) {
    if (
      tripPlannerDetails["transport"][key as TripPlannerTransportKey]?.type !==
      "searchPrompt"
    ) {
      hasSavedTransport = true;
    }
  }

  function handleUpdateClicked() {
    if (hasSavedTransport && hasChanges) {
      setShowModal(true);
      return;
    }
    handleUpdate();
  }

  function handleConfirm() {
    setShowModal(false);
    handleUpdate();
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <>
      <Button
        data-testid="update-trip"
        onClick={handleUpdateClicked}
        textColor="primaryOnDark"
        backgroundColor="pink"
        size={isMobile ? undefined : "large"}
      >
        {intl.formatMessage(messages.updateTrip)}
      </Button>
      <ConfirmationModal
        showModal={showModal}
        onCloseModal={handleCloseModal}
        onConfirm={handleConfirm}
        title={intl.formatMessage(messages.titleConfirmation)}
        description={intl.formatMessage(messages.descriptionConfirmation)}
        confirmButtonText={intl.formatMessage(messages.updateTrip)}
        cancelButtonText={intl.formatMessage(messages.cancel)}
      />
    </>
  );
}
