import { useIntl } from "react-intl";
import { GeocodedPlace } from "src/PrefetchData";
import { Dialog } from "src/components/Dialog/Dialog";
import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import { PropsWithChildren } from "react";
import { CloseButton } from "src/auth/components/CloseButton/CloseButton";
import { Icon } from "src/components/Icon/Icon";
import { Search } from "src/svg/Search";
import { AutocompletePlace } from "../../../../api/AutocompleteResponse";
import { fontSize, spacing } from "../../../../theme";
import { Drawer } from "../../../Drawer/Drawer";
import messages from "../AddDestinationButton.messages";
import { AddDestinationInput } from "./AddDestinationInput";

type DestinationDrawerProps = {
  isOpen: boolean;
  onCloseClicked: () => void;
  onBackdropClicked: () => void;
  onSelectOption: (place: AutocompletePlace) => void;
  place?: GeocodedPlace;
  initialValue?: string;
};
export function AddDestinationDialog(props: DestinationDrawerProps) {
  const intl = useIntl();
  const isMobile = useLayout() === "mobile";
  const title = props.place
    ? intl.formatMessage(messages.editPlacePrompt)
    : intl.formatMessage(messages.destinationPrompt);

  const ModalOrDrawer = isMobile ? AddDestinationDrawer : AddDestinationModal;
  return (
    <ModalOrDrawer
      onCloseClicked={props.onCloseClicked}
      onBackdropClicked={props.onBackdropClicked}
      isOpen={props.isOpen}
    >
      <Container>
        <Heading>{title}</Heading>
        {!isMobile && (
          <CloseWrapper>
            <CloseButton
              tint="cod"
              size="sm-1"
              onClick={props.onCloseClicked}
            />
          </CloseWrapper>
        )}

        <AddDestinationInput
          // We can disable this autoFocus rule because we only ever have one instance
          // in the DOM at any one time.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          type="destination"
          id="add-destination"
          AutocompleteListWrapper={
            isMobile ? undefined : AutocompleteListWrapper
          }
          icon={
            <Icon size="md">
              <Search tint="cod" />
            </Icon>
          }
          solidBackground
          {...props}
        />
      </Container>
    </ModalOrDrawer>
  );
}

type DialogProps = {
  isOpen: boolean;
  onBackdropClicked: () => void;
  onCloseClicked: () => void;
};

function AddDestinationDrawer({
  children,
  onBackdropClicked,
  onCloseClicked,
  isOpen,
}: PropsWithChildren<DialogProps>) {
  return (
    <Drawer
      onBackdropClicked={onBackdropClicked}
      onCloseClicked={onCloseClicked}
      isOpen={isOpen}
      height="282px"
      closeSize="xs"
      order={3}
    >
      {children}
    </Drawer>
  );
}

function AddDestinationModal({
  children,
  isOpen,
  onBackdropClicked,
}: PropsWithChildren<DialogProps>) {
  return (
    <Dialog
      isOpen={isOpen}
      variant="small"
      align="flex-start"
      topOffset="20%"
      onBackdropClicked={onBackdropClicked}
      onClickOutside={onBackdropClicked}
    >
      {children}
    </Dialog>
  );
}

const Heading = styled.h3`
  text-align: center;
  margin-bottom: ${spacing.xl};

  ${desktopLayout} {
    font-size: ${fontSize.h5};
    margin-bottom: ${spacing.lg};
    text-align: left;
  }
`;

const Container = styled.div`
  padding: ${spacing.md} ${spacing.xl};

  ${desktopLayout} {
    padding: ${spacing.lg} ${spacing.xl} ${spacing.xl};
    width: 480px;
  }
`;

const AutocompleteListWrapper = styled.div`
  max-height: calc(50vh - 80px);
  overflow-y: auto;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: ${spacing.lg};
  right: ${spacing.lg};
`;
