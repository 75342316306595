import { SuccessfulDestinationInfoResponse } from "src/api/DestinationInfoResponse";

export default function createAttractionViewModel(
  attractionInfo: SuccessfulDestinationInfoResponse["attractions"][0]
) {
  return {
    title: attractionInfo.name,
    imageUrl: attractionInfo.primaryPhotoUrl,
    url: attractionInfo.attractionUrl,
    description: attractionInfo.description,
  };
}
