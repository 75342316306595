import { SvgProps, useColor } from "./common";

/**
 * @deprecated Use the Star svg instead
 */
export const Star = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <g>
        <path
          d="M12.397 2.5L8.118 8.503H2l5.42 5.903L5.962 21.5l6.435-3.274 6.838 3.274-1.91-7.094L22 8.503h-5.731L12.397 2.5z"
          strokeWidth="1"
          strokeLinecap="round"
          fill={tintColor}
          stroke={tintColor}
        />
      </g>
    </svg>
  );
};

export const StarWithBorder = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  const strokeColor = "#1F1E1E6B";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <g>
        <path
          d="M12.397 2.5L8.118 8.503H2l5.42 5.903L5.962 21.5l6.435-3.274 6.838 3.274-1.91-7.094L22 8.503h-5.731L12.397 2.5z"
          strokeWidth="1"
          strokeLinecap="round"
          fill={tintColor}
          stroke={strokeColor}
        />
      </g>
    </svg>
  );
};
