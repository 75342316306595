import { useCallback, useEffect } from "react";
import { AnyTripCard } from "src/components/TripPlanner/TripCard/AnyTripCard";

import { borderRadius, spacing } from "src/theme";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import styled, { css } from "styled-components";
import SimpleRouteCardLoading from "src/components/TripPlanner/TripCard/SimpleRouteCard/SimpleRouteCardLoading";
import { SearchResultListCardSkeleton } from "src/components/TripPlanner/TripCard/SearchResultListCard/SearchResultListCardSkeleton";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { useNavigate } from "react-router";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { TripPlannerDetails } from "../TripPlannerProvider";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { createTransportKey } from "../util/createTransportKey";
import {
  createTripCardProperties,
  getCardCallback,
} from "./createTripCardProperties";

type TripPlannerTravelBlockProps = {
  origin: TripPlannerDetails["places"][number];
  destination: TripPlannerDetails["places"][number];
  index: number;
  isExpanded?: boolean;
  toggle?: () => void;
  isDragging?: boolean;
  isDraggable?: boolean;
  isPendingChanges?: boolean;
};

export function TripPlannerTravelBlock(props: TripPlannerTravelBlockProps) {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { tripPlannerDetails, tripRoutes, tripPlanningState, isMultiTrip } =
    useTripPlannerContext();

  const transportKey = createTransportKey(
    props.origin.canonicalName,
    props.destination.canonicalName
  );

  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const tripPlannerEntry = tripPlannerDetails.transport[transportKey];
  const staticSearchResponse = tripPlannerEntry?.searchResponse;
  const searchResponse = tripRoutes.queries[props.index];

  useEffect(() => {
    if (
      (!staticSearchResponse || isTripsAsCoreHoldback) &&
      !searchResponse.isLoading &&
      !searchResponse.data
    ) {
      tripRoutes.setActiveQueries({
        index: props.index,
        value: true,
      });
    }
  }, [
    staticSearchResponse,
    tripRoutes,
    props.index,
    searchResponse.isLoading,
    searchResponse.data,
    isTripsAsCoreHoldback,
  ]);

  const getCardProperties = useCallback(
    () =>
      createTripCardProperties(
        isTripsAsCoreHoldback
          ? searchResponse.data
          : staticSearchResponse ?? searchResponse.data,
        tripPlannerEntry,
        isMultiTrip
      ),
    [
      tripPlannerEntry,
      searchResponse,
      staticSearchResponse,
      isTripsAsCoreHoldback,
      isMultiTrip,
    ]
  );

  const cardProperties = getCardProperties();

  if (!isMultiTrip && !cardProperties) {
    return <SearchResultListCardSkeleton />;
  }

  if (!cardProperties || (props.isPendingChanges && !isTripsAsCoreHoldback)) {
    return <SimpleRouteCardLoading />;
  }

  if (cardProperties && tripPlanningState.updatedPlace.value === props.index) {
    return <SimpleRouteCardLoading />;
  }

  function cardCallback() {
    navigateToNewStateHash(
      navigate,
      { highlightedTab: "trips" },
      tripHashFromSearchResponse(searchResponse.data),
      {
        ...location,
      },
      true
    );
  }

  const callback = getCardCallback(cardCallback, cardProperties, props.index);

  return (
    <TravelBlockWrapper
      data-testid={`${cardProperties.type}-card`}
      $padTop={isTripsAsCoreHoldback}
    >
      <AnyTripCard
        {...cardProperties}
        index={props.index}
        clickCallback={callback}
        isExpanded={props.isExpanded}
        toggle={props.toggle}
        isDragging={props.isDragging}
        isPendingDragChanges={props.isPendingChanges}
      />
    </TravelBlockWrapper>
  );
}

export const TravelBlockWrapper = styled.div<{ $padTop: boolean }>`
  border-radius: ${borderRadius.md};
  ${({ $padTop }) =>
    $padTop &&
    css`
      padding-top: ${spacing.md};
    `}
`;
