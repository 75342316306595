import { ScreenComponent } from "src/analytics/generateScreenComponentsRendered/ScreenComponent";
import { LayoutType } from "src/utils/hooks/useLayout";
import { TypedLocation } from "src/utils/hooks/useTypedLocation";
import { TripPlanOnboardStep } from "../hooks/useTripPlanOnboarding";
import { ApiState } from "./api";

export function getTripPlannerComponentsToRender(
  onboardingStep: TripPlanOnboardStep,
  layout: LayoutType,
  apiState: ApiState,
  location?: TypedLocation
): ScreenComponent[] {
  let components: ScreenComponent[] = [];

  if (apiState.fetchState === "fetching") {
    components.push("TripPlannerSkeleton");
    return components;
  }

  if (onboardingStep === "introduction") {
    components.push("TripPlannerIntroduction");
  }

  if (onboardingStep === "complete" && location?.state?.reorderingTripPlan) {
    components.push("ReorderPage");
  }

  if (onboardingStep === "complete" && !location?.state?.reorderingTripPlan) {
    components.push("TripPlannerCards");
  }

  if (
    layout === "mobile" &&
    onboardingStep === "complete" &&
    !location?.state?.reorderingTripPlan
  ) {
    components.push("TripPlannerMobileMap");
  }

  if (onboardingStep === "training") {
    components.push("TripPlannerTraining");
  }

  if (onboardingStep === "complete") {
    components.push("TripPlannerTitle");
    components.push("TripPlannerDatePicker");
  }

  return components;
}
