import { Typography } from "src/design-system/components/Typography/Typography";
import { useIntl } from "react-intl";
import styled, { css } from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { border_radius } from "src/design-system/tokens/border";
import { useHorizontalMouseScroll } from "src/utils/hooks/useHorizontalMouseScroll";
import { useRef } from "react";
import { scrollbarHiddenStyling } from "src/utils/styles/common";
import { Button } from "src/design-system/components/Button/Button";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import createAttractionViewModel from "./createAttractionViewModel";
import messages from "./AttractionList.messages";

export default function AttractionList({
  attractions,
}: {
  attractions: ReturnType<typeof createAttractionViewModel>[];
}) {
  const intl = useIntl();

  const carouselRef = useRef<HTMLUListElement>(null);
  const { handleMouseDown, isDragging } = useHorizontalMouseScroll(carouselRef);

  if (attractions.length === 0) {
    return null;
  }

  return (
    <Container>
      <HeadingContainer>
        <Typography variant="heading-md" as="h2" id="attractions-heading">
          {intl.formatMessage(messages.heading)}
        </Typography>
        <Button
          variant="secondary"
          size="medium"
          onPress={() => alert("not sure where to go yet :-)")}
        >
          {intl.formatMessage(messages.seeAll)}
        </Button>
      </HeadingContainer>
      <List
        aria-labelledby="attractions-heading"
        $isDragging={isDragging}
        ref={carouselRef}
        onMouseDown={handleMouseDown}
      >
        {attractions.map((attraction) => (
          <li key={attraction.title} style={{ display: "contents" }}>
            <Card
              draggable="false"
              target="_blank"
              href={attraction.url}
              rel="noopener nofollow"
              onClick={() =>
                sendAnalyticsInteractionEvent(
                  "Destination",
                  "Attraction:Click",
                  attraction.title
                )
              }
              title={attraction.title}
            >
              <ImageContainer>
                <img
                  draggable="false"
                  src={attraction.imageUrl}
                  alt={attraction.title}
                />
              </ImageContainer>
              <Typography as="h5" weight="bold" variant="label-sm">
                {attraction.title}
              </Typography>
            </Card>
          </li>
        ))}
      </List>
    </Container>
  );
}

export function AttractionListSkeleton() {
  return (
    <Container>
      <HeadingContainer>
        <Skeleton width="120px" height="24px" />
        <Skeleton width="70px" height="32px" />
      </HeadingContainer>
      <List $isDragging={false}>
        {[...Array(8)].map((_, index) => (
          <li key={index} style={{ display: "contents" }}>
            <Card>
              <ImageContainer>
                <Skeleton width="160px" height="160px" />
              </ImageContainer>
              <Skeleton width="100px" height="18px" />
            </Card>
          </li>
        ))}
      </List>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  padding: ${spacing.xxxl} 0;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${spacing.xl};
`;

const List = styled.ul<{ $isDragging: boolean }>`
  // Reset
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  gap: ${spacing.lg};
  overflow-x: scroll;
  padding: ${spacing.xl};

  &:active {
    cursor: grabbing;
  }

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      * {
        pointer-events: none;
      }
    `}

  ${scrollbarHiddenStyling}
`;

const ImageContainer = styled.div`
  overflow: hidden;
  width: min(33vw, 110px);
  height: min(33vw, 110px);
  margin-bottom: ${spacing.sm};
  border-radius: ${border_radius.rounded_md};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Card = styled.a`
  text-decoration: none;
  color: inherit;

  img {
    transition: transform 0.2s;
    transform-origin: center center;
  }
  &:hover,
  &:focus-visible {
    text-decoration: underline;
    img {
      transform: scale(1.05);
    }
  }
  &:focus-visible {
    outline: none;
    ${ImageContainer} {
      outline: 2px solid var(--color-ring); //
    }
  }
`;
