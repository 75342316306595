import { animated, easings } from "@react-spring/web";
import { ComponentProps, ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import { desktopLayout } from "src/utils/hooks/useLayout";
import { useLeftPaneWidth } from "src/utils/hooks/useLeftPaneWidth";
import { StackedNavSlide } from "../Transition/StackedNavSlide";
import { ScrollableContainer } from "../ScrollableContainer/ScrollableContainer";

type PaneStackProps = {
  activePaneIndex: number;
  /**
   * The width of each layer in the stack.
   */
  paneWidth?: number | string;
  /**
   * The LHS gap between each layer as they stack on top of each other.
   */
  paneGap?: number;
  /**
   * The bottom pane is the content that never animates in/out.
   * If all pane layers are inactive, the bottom pane is visible.
   */
  BottomPane: ReactElement;
  children?: ReactElement | ReactElement[];
  onBackdropClick?: () => void;
  isTwoColumnLayout?: boolean;
};

export function PaneStack({
  activePaneIndex,
  children,
  BottomPane,
  paneGap = 51,
  paneWidth = 670,
  onBackdropClick,
  isTwoColumnLayout,
}: PaneStackProps) {
  const panes = children ? [children].flat() : [];

  const initialRender = useRef(true);
  const leftPaneWidth = useLeftPaneWidth();

  useEffect(() => {
    initialRender.current = false;
  }, []);

  return (
    <StackContainer
      data-testid="pane-stack-container"
      fullWidth={activePaneIndex > 0 && isTwoColumnLayout}
    >
      <BottomPaneContainer
        key="pane-stack-layer-0"
        data-testid="pane-stack-layer-0"
        aria-hidden={activePaneIndex > 0}
        style={{ width: leftPaneWidth }}
      >
        {BottomPane}
      </BottomPaneContainer>
      {panes.map((paneContent, i) => {
        const isActive = i < activePaneIndex;
        const gap = paneGap * (i + 1);
        return (
          <StackedNavSlide
            key={`pane-stack-layer-${i + 1}`}
            slideAmount={paneGap / 2}
            config={{
              easing: easings.easeInOutQuad,
              tension: 265,
              clamp: false,
            }}
            open={isActive}
            immediate={initialRender.current}
            left={gap}
          >
            <Pane
              $width={paneWidth}
              $gap={gap}
              data-testid={`pane-stack-layer-${i + 1}`}
              tabIndex={isActive ? 0 : -1}
              aria-hidden={!isActive}
            >
              <ScrollableContainer isDisabled={!isActive}>
                <PaneBackdrop $gap={gap} onClick={onBackdropClick} />
                {paneContent}
              </ScrollableContainer>
            </Pane>
          </StackedNavSlide>
        );
      })}
    </StackContainer>
  );
}

const StackContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  ${desktopLayout} {
    width: ${({ fullWidth }) => fullWidth && "100vw"};
  }
`;

const BottomPaneContainer = styled.div`
  position: relative;
  height: 100%;
  grid-area: 1 / 1 / 2 / 2;
`;

const Pane = animated(styled.div<{
  $gap: number;
  $width: number | string;
}>`
  position: relative;
  z-index: 1;
  width: ${({ $width }) =>
    typeof $width === "number" ? `${$width}px` : $width};
  height: 100%;
  left: ${({ $gap }) => $gap}px;
  // Setting the Pane in the first row and first grid enables us to position the pane on top of the
  // bottom pan container, without removing it from the flow.
  // This ensures the right rail content can scroll naturally with the left pane content without
  // needing any JS and enables the ads to be optimally positioned regardless of content height.
  grid-area: 1 / 1 / 2 / 2;
  pointer-events: auto;

  ${desktopLayout} {
    box-shadow: 1px 0 12px rgba(31, 30, 30, 0.2);
  }
`);

const PaneBackdrop = styled.div<{
  $gap: number;
  onClick: ComponentProps<"div">["onClick"];
}>`
  position: absolute;
  right: 100%;
  // 20px is a buffer to ensure the backdrop reaches the left edge of the screen.
  width: ${({ $gap }) => $gap + 20}px;
  height: 100%;
  background-color: rgba(31, 30, 30, 0.5);
  ${({ onClick }) => (!!onClick ? "cursor: pointer;" : "")}
`;
