import { defineMessages } from "react-intl";

const messages = defineMessages({
  startOver: {
    id: "DefaultErrorBoundary.startOver",
    defaultMessage: "start over",
    description:
      "Link in the error message that redirect the user to the home page",
  },
  resultsNotFound: {
    id: "appError.message",
    // Note: {homeLink} will contain the startOver message, wrapped in a link
    defaultMessage:
      "We can’t get those results right now. Search again, refresh or {homeLink}",
    description:
      "Message that shows to user when an error has occurred on the site",
  },
  resultsNotFoundMap: {
    id: "appError.mapMessage",
    defaultMessage:
      "We can’t get that map right now. Refresh or if it still doesn’t work {homeLink}",
    description:
      "Message that shows to user when an error has occurred on the site",
  },
  couldntLoadSite: {
    id: "appError.couldntLoadSite",
    defaultMessage:
      "We can’t load the site right now. Please refresh or {homeLink}",
    description:
      "Message that shows to user when part of the site (e.g. code bundles) could not load",
  },
});

export default messages;
