import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { DragAndDropList } from "src/components/DragAndDropList/DragAndDropList";
import { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { Icon } from "src/components/Icon/Icon";
import { OutlineMinus } from "src/svg/OutlineMinus";
import styled from "styled-components";
import { PlaceName } from "src/utils/useCreateTripFromSearch";
import { DragHandle } from "src/svg/tripplanner/DragHandle";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import messages from "./SearchBox.messages";

type Props = {
  places: PlaceName[];
  onPlaceClick?: (index: number) => void;
  setNewOrder?: (newOrder: SortableObject<{ index: number }>[]) => void;
};

export function CreateTrip(props: Props) {
  const intl = useIntl();

  function handleChange(
    newOrder: { id: string; content: string; index: number }[]
  ) {
    props.setNewOrder?.(newOrder);
  }

  return (
    <DragAndDropList
      onChange={handleChange}
      initialItems={props.places.map((place, index) => ({
        id: `${index}-place`,
        content: place.shortName,
        index,
      }))}
      ListComponent={List}
      isCustomItem
    >
      {({ dragHandleProps, onClickRemoveButton, item, index }) => (
        <>
          <InputWrapper
            onClick={(event) => {
              event.preventDefault();
              sendAnalyticsInteractionEvent("SearchBox", "Click:Place");
              props.onPlaceClick?.(index);
            }}
            key={`${index}-place`}
          >
            <Indexation>{index + 1}</Indexation>
            <StyledInput
              aria-label={intl.formatMessage(messages.origin)}
              id={`${index}-place`}
              value={item.content}
              readOnly
            />
            <DeleteButton
              onClick={(event) => {
                event.stopPropagation();
                sendAnalyticsInteractionEvent("SearchBox", "Click:RemovePlace");
                onClickRemoveButton(event);
              }}
              aria-label={intl.formatMessage(messages.removePlace, {
                place: item.content,
              })}
            >
              <Icon size="xl">
                <OutlineMinus tint="n60" />
              </Icon>
            </DeleteButton>
          </InputWrapper>
          <DraggableIconContainer {...dragHandleProps}>
            <Icon size="xxl">
              <DragHandle tint="n60" />
            </Icon>
          </DraggableIconContainer>
        </>
      )}
    </DragAndDropList>
  );
}

const InputWrapper = styled.div`
  position: relative;
  margin: ${spacing.sm} 0;
  border: 1px solid ${color.n30};
  border-radius: ${borderRadius.sm};
  flex: auto;
`;

const INDEX_HEIGHT = 22;
const Indexation = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.tight};
  color: ${color.n200};
  border-radius: 50%;
  width: ${INDEX_HEIGHT}px;
  height: ${INDEX_HEIGHT}px;
  background-color: ${color.n20};

  position: absolute;
  top: calc(50% - ${INDEX_HEIGHT / 2}px);
  left: ${spacing.lg};
`;

const StyledInput = styled.input`
  background: ${(props) => props.theme.searchBar.input.background};
  display: block;
  width: 100%;
  border: none;
  color: ${(props) => props.theme.searchBar.input.text};
  font-size: ${fontSize.body};
  padding: 13px ${spacing.xxxxl} 13px 64px;
  text-overflow: ellipsis;
  border-radius: ${borderRadius.sm};
  height: 48px;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
    -webkit-tap-highlight-color: ${(props) =>
      props.theme.searchBar.input.backgroundActive};
  }
`;

const DeleteButton = styled(ButtonBase)`
  position: absolute;
  top: 50%;
  right: ${spacing.lg};
  transform: translateY(-50%);
`;

const DraggableIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spacing.md};
`;

const List = styled.ul`
  list-style: none;
  padding: ${spacing.md};
  margin: 0;
`;
