import { Suspense, useCallback } from "react";
import useDestinationInfo from "src/utils/hooks/useDestinationInfo";
import {
  DestinationInfoResponse,
  SuccessfulDestinationInfoResponse,
} from "src/api/DestinationInfoResponse";
import { QueryErrorResetBoundary } from "react-query";
import { ErrorBoundary } from "@sentry/react";
import { useIntl } from "react-intl";
import { Button } from "src/design-system/components/Button/Button";
import { Typography } from "src/design-system/components/Typography/Typography";
import styled from "styled-components";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { Icon } from "src/design-system/components/Icon/Icon";
import { spacing } from "src/design-system/tokens/spacing";
import color from "src/design-system/tokens/color";
import { A11yOutline, A11yTargetSize } from "src/utils/accessibility";
import { ArrowBackward } from "src/svg/ArrowBackward";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import createDestinationViewModel from "./createDestinationViewModel";
import messages from "./DestinationScreen.messages";
import AttractionList, {
  AttractionListSkeleton,
} from "./AttractionList/AttractionList";

type Props = {
  origin?: string;
  destination?: string;
};

export default function DestinationScreen({ origin, destination }: Props) {
  const intl = useIntl();
  const location = useTypedLocation();

  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    undefined,
    "Destination"
  );

  return (
    <ScreenContainer>
      <CloseButton title={intl.formatMessage(messages.close)}>
        <Icon size="small">
          <ArrowBackward tint="black" />
        </Icon>
      </CloseButton>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallback={({ resetError }) => (
              <FailureContainer>
                <Typography variant="body-lg" as="h4">
                  {intl.formatMessage(messages.requestFailed)}
                </Typography>
                <Button onPress={resetError}>
                  {intl.formatMessage(messages.retry)}
                </Button>
              </FailureContainer>
            )}
          >
            <Suspense fallback={<DestinationScreenContentSkeleton />}>
              {destination ? (
                <DestinationScreenContent
                  requestOrigin={origin}
                  requestDestination={destination}
                />
              ) : null}
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </ScreenContainer>
  );
}

type DestinationScreenContentProps = {
  requestOrigin?: string;
  requestDestination: string;
};

function DestinationScreenContent({
  requestOrigin,
  requestDestination,
}: DestinationScreenContentProps) {
  const intl = useIntl();
  const destinationInfo = useDestinationInfo(requestOrigin, requestDestination);

  const readViewModel = useCallback(() => {
    if (!destinationInfo) {
      return null;
    }
    return tryGetViewModel(requestDestination, destinationInfo);
  }, [requestDestination, destinationInfo]);

  const viewModel = readViewModel();

  if (!viewModel) {
    return (
      <FailureContainer>
        <Typography variant="body-lg" as="p">
          {intl.formatMessage(messages.requestNoResults)}
        </Typography>
      </FailureContainer>
    );
  }

  // TODO: Add top section
  // TODO: Add attractions
  // TODO: Add transport results
  // TODO: Add hotel promo
  return (
    <>
      {/* TOP SECTION */}
      <Divider />
      <AttractionList attractions={viewModel.attractions} />
      <Divider />
      {/* TRANSPORT RESULTS */}
      <Divider />
      {/* HOTEL PROMO */}
    </>
  );
}

function DestinationScreenContentSkeleton() {
  // TODO: Add top section
  // TODO: Add attractions
  // TODO: Add transport results
  // TODO: Add hotel promo

  return (
    <>
      {/* TOP SECTION */}
      <Divider />
      <AttractionListSkeleton />
      <Divider />
      {/* TRANSPORT RESULTS */}
      <Divider />
      {/* HOTEL PROMO */}
    </>
  );
}

function tryGetViewModel(
  destinationCanonical: string,
  destinationInfoResponse: DestinationInfoResponse
) {
  if (destinationInfoResponse.responseType === "Success") {
    return createDestinationViewModel(
      destinationCanonical,
      destinationInfoResponse as SuccessfulDestinationInfoResponse
    );
  }
  return null;
}

const ScreenContainer = styled.div`
  position: relative;
`;

const FailureContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${spacing.lg};
  margin-top: ${spacing.xxxl};
`;

const CloseButton = styled(ButtonBase)`
  ${A11yTargetSize};
  ${A11yOutline};

  position: absolute;
  top: ${spacing.lg};
  left: ${spacing.xl};
  background-color: ${color.bg.fill.fill};

  width: 32px;
  height: 32px;
  border-radius: 50%;

  &:hover,
  &:focus {
    background-color: ${color.bg.fill.hover};
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 8px solid ${color.bg.fill.active};
`;
