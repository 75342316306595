import { SvgProps, useColor } from "../common";

type PlusProps = {
  active?: boolean;
} & SvgProps;

export const Plus = (props: PlusProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        d="M4.71429 10.8703C4.71429 11.3042 5.06607 11.656 5.50001 11.656C5.93395 11.656 6.28572 11.3042 6.28572 10.8703V6.94155H10.2143C10.6482 6.94155 11 6.58977 11 6.15583C11 5.72189 10.6482 5.37012 10.2143 5.37012H6.28572V1.44172C6.28572 1.00778 5.93395 0.656006 5.50001 0.656006C5.06607 0.656006 4.71429 1.00778 4.71429 1.44172V5.37012H0.785715C0.351776 5.37012 0 5.72189 0 6.15583C0 6.58977 0.351776 6.94155 0.785714 6.94155H4.71429V10.8703Z"
        fill={tintColor}
      />
    </svg>
  );
};
