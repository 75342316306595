import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "attractionList.heading",
    defaultMessage: "Top attractions",
    description: "Heading for the list of attractions",
  },
  seeAll: {
    id: "attractionList.seeAll",
    defaultMessage: "See all",
    description: "Button to view all attractions",
  },
});

export default messages;
